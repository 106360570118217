export const SET_COMPANY = 'SET_COMPANY';
export function setListCompany(list_company, crr_company) {
    return {
        type: SET_COMPANY,
        payload:{
            list_company,
            crr_company,
        }
    }
}
