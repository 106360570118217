import React, { Component } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import Header from "../components/layouts/Header";
import MenuBar from "../components/layouts/MenuBar";
import BiTable from '../components/BiTable';
import ScanDocument from '../components/ScanDocument';
import History from '../components/History';
import Customer from '../components/Customer';
import Setting from '../components/Setting';
import Department from '../components/Setting/Deparment';
import HistoryAppointment from '../components/HistoryAppointment';
import {HIDE_SCAN_DOCUMENT} from '../config'
import Store from '../store';


class Routers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataRole: Store.getState().role.role,
        }
        Store.subscribe(() => {
            this.setState({
                dataRole: Store.getState().role.role,
            });
        });
    }

    render() {
        return (
            <div className="m-grid m-grid--hor m-grid--root m-page">
                <Header />
                <MenuBar />
                <Route path="/" exact render={() => <BiTable />} />
                <Route path="/bitable" exact render={() => <BiTable />} />
                {HIDE_SCAN_DOCUMENT !== "1" ?  <Route path="/scandocument" exact render={() => <ScanDocument />} /> : "" }
                <Route path="/history" exact render={() => <History />} />
                <Route path="/Customer" exact render={() => <Customer />} />
                {["1", "2"].includes(this.state.dataRole) && <Route path="/setting" exact render={() => <Setting />} />}
                {["1", "2"].includes(this.state.dataRole) && <Route path="/setting-department" exact render={() => <Department />} />}
                <Route path="/appointment" exact render={() => <HistoryAppointment />} />
            </div>
        );
    }
}

export default Routers;