import { css } from '@emotion/core';
import $ from 'jquery';
import React, { Component } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import { withRouter } from 'react-router';
import { PulseLoader } from 'react-spinners';
import store from '../../store';
import { HOST_EKYC } from '../../config';
import ReactTooltip from 'react-tooltip';
import Pagination from "react-js-pagination";
import ModalDepartment from './ModalDepartment'
import swal from 'sweetalert';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;


const all_camera = {
    id_box_engine: -1,
    title: 'Tất cả camera',
}





class Department extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listBox: store.getState().company.crr_company ? [{
                id_box_engine: -1,
                title: 'Tất cả camera',
            }].concat(store.getState().company.crr_company.dataBox) : [all_camera],
            crrBox: all_camera,
            crrCompany: store.getState().company.crr_company ? store.getState().company.crr_company : null,
            listDepartment: [],
            offset: 0,
            activePage: 1,
            valueSearch: '',
            modalDepartment: false,
            crrDepartment: {
                floor: "",
                name: "",
            },

        }
        store.subscribe(() => {
            this.setState({
                crrCompany: store.getState().company.crr_company,
            }, () => {
                let listBox = this.state.crrCompany ? [...this.state.crrCompany.dataBox] : [];
                listBox.unshift(all_camera)
                this.setState({
                    listBox: listBox,
                    crrBox: all_camera,
                }, () => {
                    this.getDepartment()
                });

            })
        })
        this.itemsPerPage = 10;
    }

    componentDidMount = () => {
        this.getDepartment()
    };

    modalClose = () => {
        this.setState({
            modalDepartment: false,
        });
        this.getDepartment();
    }



    getDepartment = () => {
        if (!this.state.crrCompany) return;
        fetch(`${HOST_EKYC}/api/department/search`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': store.getState().isLogin.access_token
            },
            body: JSON.stringify({
                "search_data": this.state.valueSearch,
                "index": this.state.activePage,
                "item_per_page": this.itemsPerPage,
                "id_company": this.state.crrCompany ? this.state.crrCompany.value.toString() : '',
                "status": 1,
            })
        }).then((response) => {
            return response.json()
        }).then((dataRes) => {
            console.log(dataRes)
            if (dataRes.status === 10000) {
                this.setState({
                    listDepartment: dataRes.data,
                    total_count: dataRes.count,
                    loading: false
                })
                this.FilterStaff(this.state.activePage)
            } else {
                this.setState({
                    listDepartment: [],
                    total_count: 0,
                    loading: false
                })
            }
        }).catch((error) => {
            this.setState({
                listDepartment: [],
                total_count: 0,
                loading: false
            })
            console.log(error)
        })
    }



    FilterStaff = (activePage) => {
        const offset = (activePage - 1) * this.itemsPerPage;
        this.setState({
            offset: offset
        })
    }

    handlePageChange = (pageNumber) => {
        this.setState({
            ...this.state,
            activePage: pageNumber
        }, () => {
            this.getDepartment()
        })
    }





    btnSearch = (value) => {
        this.setState({
            activePage: 1
        }, () => {
            this.getDepartment()
        })
    }

    DeleteDepartment = (_id) => {
        fetch(`${HOST_EKYC}/api/department/delete_by_id/${_id}`, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': store.getState().isLogin.access_token
            },
        }).then((response) => {
            return response.json()
        }).then((dataRes) => {
            if (dataRes.status == 10000) {
                swal({
                    title: 'Thành công',
                    text: 'Xóa thành công',
                    buttons: false,
                    timer: 1500,
                    icon: 'success',
                });
                this.getDepartment()
            } else {
                swal({
                    title: 'Lỗi',
                    text: 'Xóa không thành công',
                    buttons: false,
                    timer: 1500,
                    icon: 'error',
                })
            }
        }).catch((error) => {
            console.log(error);
            swal({
                title: 'Lỗi',
                text: 'Xóa không thành công',
                buttons: false,
                timer: 1500,
                icon: 'error',
            })
        })
    }



    isChange = (event) => {
        if (event.key === 'Enter') {
            this.btnSearch(event.target.value.trim())
        }
        this.setState({
            valueSearch: event.target.value.trim()
        });
    }


    render() {
        var pageRangeDisplayed = 1;
        if ($(window).width() < 768) {
            pageRangeDisplayed = 3
        } else {
            pageRangeDisplayed = 5
        }

        let bulletedDepartment = this.state.listDepartment.map((e, i) => {
            return (
                <tr key={(i + 1)}>
                    <td>{(i + this.state.offset + 1)}</td>
                    <td>{e.floor}</td>
                    <td>{e.name}</td>
                    <td>
                        <button onClick={(e) => {
                            var data = this.state.listDepartment
                            var dataDepartment = {
                                name: data[i].name,
                                floor: data[i].floor,
                                obj_id: data[i]._id,
                            };
                            console.log("dataDepartment", dataDepartment)
                            this.setState({
                                crrDepartment: dataDepartment,
                                modalDepartment: true,
                            });
                        }} className="m-portlet__nav-link btn m-btn m-btn--hover-warning m-btn--icon m-btn--icon-only m-btn--pill" data-tip data-for='Edit'>
                            <i className="la la-edit" />
                        </button>
                        <ReactTooltip id='Edit' type='dark' effect='solid'>
                            <span>Sửa</span>
                        </ReactTooltip>


                        <button className="m-portlet__nav-link btn m-btn m-btn--hover-danger m-btn--icon m-btn--icon-only m-btn--pill" data-tip data-for='Delete'
                            onClick={v => {
                                v.preventDefault();
                                swal({
                                    title: "Bạn có chắc chắn?",
                                    text: "Bạn có chắc chắn muốn xóa " + "Tầng " + e.floor + " - " + "Phòng ban " + e.name,
                                    icon: "warning",
                                    buttons: true,
                                    dangerMode: true,
                                })
                                    .then(willDelete => {
                                        if (willDelete) {
                                            this.DeleteDepartment(e._id);
                                        }
                                    });
                            }}
                        ><i className="la la-trash"></i></button>
                        <ReactTooltip id='Delete' type='dark' effect='solid'>
                            <span>Xoá</span>
                        </ReactTooltip>
                    </td>
                </tr>
            )
        });


        return (
            <div className="m-grid__item m-grid__item--fluid m-wrapper">
                <div className="m-content p-2">
                    <div className="m-portlet m-portlet--tab mb-0">
                        <div className="m-portlet__head p-3">
                            <div className="m-portlet__head-caption pl-3">
                                <div className="m-portlet__head-title">
                                    <h3 className="m-portlet__head-text">
                                        Tầng - Phòng ban
                                    </h3>
                                </div>
                            </div>
                            <div className="m-portlet__head-tools pr-3">
                                <ul className="m-portlet__nav">
                                    <li className="m-portlet__nav-item">
                                        <button onClick={(e) => {
                                            this.setState({
                                                crrDepartment: {
                                                    name: "",
                                                    floor: "",
                                                },
                                                modalDepartment: true,
                                            });
                                        }} className="btn btn-accent m-btn m-btn--custom m-btn--icon m-btn--pill m-btn--air">
                                            <span>
                                                <i className="la la-plus" />
                                                <span>Thêm mới</span>
                                            </span>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="m-portlet__body m-portlet__body--no-padding">
                            <div className="row m-row--no-padding m-row--col-separator-xl">
                                <div className="col-xl-12">
                                    <div className="m-widget1 col-xl-8 mx-auto">
                                        <div className="row col-md-12 ml-0 mr-0 p-0 mb-2">
                                            <div className="col-lg-2 pl-0 m--margin-bottom-10-tablet-and-mobile">
                                                <input type="text" onKeyUp={(event) => this.isChange(event)} id="inputSearch" className="form-control m-input" placeholder="Nhập tầng/phòng ban" data-col-index={0} />
                                            </div>
                                            <div>
                                                <button
                                                    onClick={() => {
                                                        this.btnSearch(this.state.valueSearch)
                                                    }}
                                                    className="btn btn-accent m-btn m-btn--icon" id="m_search">
                                                    <span>
                                                        <i className="la la-search" />
                                                        <span>Tìm kiếm</span>
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="table-responsive text-nowrap">
                                            <table className="table table-bordered table-hover table-checkable dataTable no-footer dtr-inline collapsed">
                                                <thead>
                                                    <tr>
                                                        <th style={{ 'verticalAlign': 'middle', 'width': '100px' }}>STT</th>
                                                        <th style={{ 'verticalAlign': 'middle', 'width': '450px' }}>Tầng</th>
                                                        <th style={{ 'verticalAlign': 'middle', 'width': '450px' }}>Phòng ban</th>
                                                        <th style={{ 'verticalAlign': 'middle', 'width': '150px' }}>Thao tác</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {bulletedDepartment}
                                                </tbody>
                                            </table>
                                            <PulseLoader
                                                css={override}
                                                sizeUnit={"px"}
                                                size={12}
                                                margin={'2px'}
                                                color={'#36D7B7'}
                                                loading={this.state.loading}
                                            />

                                            <ModalDepartment
                                                data={this.state.crrDepartment}
                                                show={this.state.modalDepartment}
                                                onHide={this.modalClose}
                                            />
                                        </div>
                                        <Pagination
                                            prevPageText='Trang trước'
                                            nextPageText='Trang sau'
                                            firstPageText='Trang đầu'
                                            lastPageText='Trang cuối'
                                            activePage={this.state.activePage}
                                            itemsCountPerPage={this.itemsPerPage}
                                            totalItemsCount={this.state.total_count}
                                            pageRangeDisplayed={pageRangeDisplayed}
                                            onChange={this.handlePageChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Department);