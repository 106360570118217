import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import swal from 'sweetalert';
import { HOST_EKYC } from '../../config/index'
import { useSelector } from 'react-redux';
import axios from 'axios';

ModalFormEdit.propTypes = {
    show: PropTypes.bool,
    data: PropTypes.object,
    onHide: PropTypes.func,
    onRefresh: PropTypes.func,
};

ModalFormEdit.defaultProps = {
    show: false,
    data: null,
    onHide: null,
    onRefresh: null,
}

function ModalFormEdit(props) {
    const { show, onHide, data, onRefresh } = props;
    const [crrData, setCrrData] = useState(null);
    const token = useSelector(state => state.isLogin.access_token);
    const [imageFront, setImageFront] = useState(null);
    const [imageBack, setImageBack] = useState(null);

    const onChangeInputFront = (e) => {
        setCrrData({
            ...crrData,
            [e.target.name]: e.target.value,
        })
    }

    const handleUpdateHistory = () => {
        fetch(`${HOST_EKYC}/api/ekyc/update`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token
            },
            body: JSON.stringify(crrData)
        }).then((response) => {
            return response.json()
        }).then((dataRes) => {
            if (dataRes.result_code == 200) {
                swal({
                    title: 'Thành công',
                    text: 'Cập nhật thành công',
                    buttons: false,
                    timer: 1500,
                    icon: 'success',
                });
                onRefresh();
            } else {
                swal({
                    title: 'Lỗi',
                    text: 'Cập nhật không thành công',
                    buttons: false,
                    timer: 1500,
                    icon: 'error',
                })
            }
        }).catch((error) => {
            swal({
                title: 'Lỗi',
                text: 'Cập nhật không thành công',
                buttons: false,
                timer: 1500,
                icon: 'error',
            })
        })
    }

    const getBase64Axios = (url,typeImg) => {
        return axios.get(url, {
            responseType: 'arraybuffer',
            headers: {
                'Authorization': token
            }
          }).then(response => {
            const buffer = Buffer.from(response.data, 'base64').toString('base64')
            if (typeImg === 1) {
                setImageFront(`data:image/png;base64, ${buffer}`)
            } else {
                setImageBack(`data:image/png;base64, ${buffer}`)
            }

          }).catch(error => {
            console.log(error)
          })
      }

    useEffect(() => {
        if (crrData) {
            getBase64Axios(`${HOST_EKYC}/` + crrData.url_image_front,1);
            getBase64Axios(`${HOST_EKYC}/` + crrData.url_image_back,2);
        }
    }, [crrData])
    
    useEffect(() => {
        setCrrData(data);
        return () => {
            setCrrData(null);
        }
    }, [data]);
    if(!crrData) return <></>;
    return (
        <Modal show={show} onHide={onHide} size='lg'>
            <div className='row-sm row'>
                <div className='col-md-12'>
                    <div className='m-portlet m-portlet--tab'>
                        <Modal.Header closeButton>
                            {/* <Modal.Title>
                          
                            </Modal.Title> */}
                        </Modal.Header>
                        {
                            crrData &&
                            <>
                                <Modal.Body>
                                    <div className='row p-0 m-0'>
                                        <div className='col-12 col-md-6 py-2'>
                                            <h4>Dữ liệu ảnh mặt trước</h4>
                                            <img id="imageFront_1" className="img_front" src={crrData.url_image_front == "" ? './img/no-image.jpg' : imageFront} alt=""
                                                style={{
                                                    width: '100%',
                                                    maxHeight: '500px',
                                                    objectFit: 'contain',
                                                    height: "250px",
                                                }} />
                                        </div>
                                        <div className='col-12 col-md-6 py-2'>
                                            <h4>Dữ liệu ảnh mặt sau</h4>
                                            <img id="imageBack_2" className="img_front" src={crrData.url_image_back == "" ? './img/no-image.jpg' : imageBack} alt=""
                                                style={{
                                                    width: '100%',
                                                    maxHeight: '500px',
                                                    objectFit: 'contain',
                                                    height: "250px",
                                                }} />
                                        </div>
                                        <div className="col-12 col-md-6 py-2 input_dot">
                                            <label>Số định danh giấy tờ:</label>
                                            <input type="text" className="form-control m-input" name="id" value={crrData.id} onChange={(e) => onChangeInputFront(e)} />
                                        </div>
                                        <div className="col-12 col-md-6 py-2 input_dot">
                                            <label>Họ và tên:</label>
                                            <input type="text" className="form-control m-input" name="name" value={crrData.name} onChange={(e) => onChangeInputFront(e)} />
                                        </div>
                                        <div className="col-12 col-md-6 py-2 input_dot">
                                            <label>Ngày sinh:</label>
                                            <input type="text" className="form-control m-input" name="birthday" value={crrData.birthday === "Invalid date" ? "" : crrData.birthday} onChange={(e) => onChangeInputFront(e)} />
                                        </div>
                                        <div className="col-12 col-md-6 py-2 input_dot">
                                            <label>Giới tính:</label>
                                            <input type="text" className="form-control m-input" name="sex" value={crrData.sex} onChange={(e) => onChangeInputFront(e)} />
                                        </div>
                                        <div className="col-12 col-md-6 py-2 input_dot">
                                            <label>Quốc tịch:</label>
                                            <input type="text" className="form-control m-input" name="national" value={crrData.national} onChange={(e) => onChangeInputFront(e)} />
                                        </div>
                                        <div className="col-12 col-md-6 py-2 input_dot">
                                            <label>Hạn sử dụng thẻ:</label>
                                            <input type="text" className="form-control m-input" name="expiry" value={crrData.expiry} onChange={(e) => onChangeInputFront(e)} />
                                        </div>
                                        {/* <div className="col-12 col-md-6 py-2 input_dot">
                                            <label>Số điện thoại:</label>
                                            <input type="text" className="form-control m-input" name="phone_number" value={crrData.phone_number} onChange={(e) => onChangeInputFront(e)} />
                                        </div> */}
                                        <div className="col-12 col-md-6 py-2 input_dot">
                                            <label>Ngày cấp:</label>
                                            <input type="text" className="form-control m-input" name="issue_date" value={crrData.issue_date} onChange={(e) => onChangeInputFront(e)} />
                                        </div>
                                        <div className="col-12 col-md-6 py-2 input_dot">
                                            <label>Nơi cấp:</label>
                                            <input type="text" className="form-control m-input" name="issue_by" value={crrData.issue_by} onChange={(e) => onChangeInputFront(e)} />
                                        </div>
                                        <div className="col-12 col-md-6 py-2 input_dot">
                                            <label>Loại bằng:</label>
                                            <input type="text" className="form-control m-input" name="class" value={crrData.class} onChange={(e) => onChangeInputFront(e)} />
                                        </div>
                                        <div className="col-12 col-md-6 py-2 input_dot">
                                            <label>Quốc gia:</label>
                                            <input type="text" className="form-control m-input" name="country" value={crrData.country} onChange={(e) => onChangeInputFront(e)} />
                                        </div>
                                        {/* <div className="col-12 col-md-6 py-2 input_dot">
                                            <label>Số thẻ:</label>
                                            <input type="text" className="form-control m-input" name="id_card" value={crrData.id_card} onChange={(e) => onChangeInputFront(e)} />
                                        </div> */}
                                        {/* <div className="col-12 col-md-6 py-2 input_dot">
                                            <label>Mục đích:</label>
                                            <input type="text" className="form-control m-input" name="people" value={crrData.people} onChange={(e) => onChangeInputFront(e)} />
                                        </div> */}
                                        {/* <div className="col-12 col-md-6 py-2 input_dot">
                                            <label>Ghi chú:</label>
                                            <input type="text" className="form-control m-input" name="note" value={crrData.note} onChange={(e) => onChangeInputFront(e)} />
                                        </div> */}
                                        <div className="col-12 col-md-6 py-2 input_dot">
                                            <label>Nguyên quán:</label>
                                            <textarea type="text" className="form-control m-input" rows="2" name="origin" value={crrData.origin} onChange={(e) => onChangeInputFront(e)} />
                                        </div>
                                        <div className="col-12 col-md-6 py-2 input_dot">
                                            <label>Nơi ĐKHKTT:</label>
                                            <textarea type="text" className="form-control m-input" rows="2" name="address" value={crrData.address} onChange={(e) => onChangeInputFront(e)} />
                                        </div>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button onClick={handleUpdateHistory} variant='accent' className='m-loader--light m-loader--right'>Lưu</Button>
                                    <Button onClick={onHide} variant='secondary'>Đóng</Button>
                                </Modal.Footer>
                            </>
                        }
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default ModalFormEdit;