import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {  Modal } from 'react-bootstrap';
import Moment from 'moment';
import DatePicker from "react-datepicker";
import Pagination from "react-js-pagination";
import { PulseLoader } from 'react-spinners';
import ModalInfo from "../History/ModalInfo";
import { css } from '@emotion/core';
import { HOST_EKYC } from '../../config'
import $ from 'jquery';
import { th } from 'date-fns/locale';
import Tooltip from '@material-ui/core/Tooltip';
import ModalReturnCard from './ModalReturnCard'
import swal from 'sweetalert';
import store from '../../store';
import axios from 'axios';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;
var dataInfoDefault = {
    address: "",
    country: "",
    date: Moment(new Date()).format('DD/MM/YYYY HH:mm:ss'),
    ethnicity: "",
    expiry: "",
    id_type: "",
    issue_by: "",
    issue_date: "",
    national: "",
    religion: "",
    time: Moment(new Date()).format('HH:mm:ss'),
    id: '',
    name: '',
    birthday: '',
    sex: '',
    reason: '',
    origin: '',
    class: '',
    id_staff: '',
    image_face: '',
    image_front: '',
    image_back: '',
    id_card: '',
    note: '',
    people: '',
    save_percent: '',
}


class ModalSearch extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data_json_export: [],
            dataHistory: [],
            total_count: 0,
            offset: 0,
            activePage: 1,
            startDate: new Date(Moment().startOf('month')).setHours(0, 0, 0, 0),
            endDate: new Date(),
            keyWord:'',
            loading: false,
            dataInfo: {
                ...dataInfoDefault
            },
            statusBtn: true,
        }
        this.itemsPerPage = 10;
        
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.show !== this.props.show) {
            this.getHistory()
            this.setState({
                endDate: new Date(),
            });
        }
    }
    modalClose = () => {
        this.setState({
            ModalInfo: false,
            ModalSearch: true,
            ModalReturnCard: false
        }, () => {
            this.getHistory()
        });
    }


    handleClickSearch = () => {
        this.setState({
            dataHistory: [],
            total_count: 0,
            loading: true
        }, () => {
            this.getHistory()
        })
    }
    getHistory = () => {
        fetch(`${HOST_EKYC}/api/ekyc/history`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': store.getState().isLogin.access_token
            },
            body: JSON.stringify({
                "start_date": Moment(this.state.startDate).format("DD/MM/YYYY HH:mm:ss"),
                "end_date": Moment(this.state.endDate).format("DD/MM/YYYY HH:mm:ss"),
                "key_word":this.state.keyWord,
                "page_num": this.state.activePage,
                "page_size": this.itemsPerPage,
                "export": "0",
                "id_camera": -1,
                // "id_camera": this.props.crrBox ? this.props.crrBox.id_box_engine : -1,
                "id_company": this.props.crrCompany ? this.props.crrCompany.value : '',
            })
        }).then((response) => {
            return response.json()
        }).then((dataRes) => {
            if (dataRes.result_code == 200) {
                this.setState({
                    dataHistory: dataRes.data,
                    data_json_export: dataRes.data,
                    total_count: dataRes.total_count,
                    loading: false
                })
                this.FilterStaff(this.state.activePage)
            } else {
                this.setState({
                    dataHistory: [],
                    total_count: 0,
                    loading: false
                })
            }
        }).catch((error) => {
            this.setState({
                dataHistory: [],
                total_count: 0,
                loading: false
            })
            console.log(error)
        })
    }

    FilterStaff = (activePage) => {
        const offset = (activePage - 1) * this.itemsPerPage;
        this.setState({
            offset: offset
        })
    }
    handleChangeStart = date => {
        this.setState({
            startDate: date,
        });
    };

    handleChangeEnd = date => {
        this.setState({
            endDate: date,
        });
    };
    handlePageChange = (pageNumber) => {
        this.setState({
            ...this.state,
            activePage: pageNumber
        }, () => {
            this.getHistory()
        })
    }
    isChange = (event) => {
        if (event.key === 'Enter') {
            this.handleClickSearch()
        }
        this.setState({
            keyWord: event.target.value.trim()
        });
    }


    returnCard = (value, type) => {
        if (type === 1) {
            this.setState({
                ModalReturnCard: true,
                dataInfo: {...value, obj_id: value._id}
            });
        } else {
            swal({
                title: 'Bạn có chắc',
                text: 'Bạn có chắc muốn huỷ trả thẻ',
                icon: "warning",
                buttons: {
                    oke: 'Đồng ý',
                    cancel: 'Đóng',
                },
            }).then((isConfirm) => {
                if (isConfirm) {
                    this.cancelReturn(value)
                }
            })
        }
    }

    getBase64Axios(url, index) {
        var imageNode = document.getElementById("imageFace_"+index);
        return axios.get(url, {
            responseType: 'blob',
            headers: {
                'Authorization': store.getState().isLogin.access_token
            }
          }).then(response => {
            const buffer = URL.createObjectURL(response.data)
            imageNode.src = buffer
          }).catch(error => {
            console.log(error)
          })
      }


    cancelReturn = (value) => {
        var dataPost = value;
        fetch(`${HOST_EKYC}/api/ekyc/save`, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': store.getState().isLogin.access_token
                    },
                    body: JSON.stringify({ ...dataPost, 
                        path_front: dataPost.url_image_front,
                        path_back: dataPost.url_image_back,
                        checkout_time: "",
                        obj_id: value._id
                    })
                })
            .then(response => {
                return response.json()
            }).then(data => {
                if (data.status === 10000) {
                    swal({
                        title: "Thành công",
                        text: "Lưu thành công!",
                        icon: "success",
                        button: false,
                        timer: 2000,
                    })
                    this.getHistory()
                } else {
                    swal({
                        title: "Lưu thất bại, vui lòng kiểm tra lại!",
                        icon: "error",
                        buttons: {
                            confirm: {
                                text: 'Đóng',
                                value: null,
                                visible: true,
                                closeModal: true,
                            },
                        },
                        dangerMode: false,
                    })
                }
            }).catch((error) => {
                console.log(error)
                if (error) {
                    swal({
                        title: "Lưu thất bại, kiểm tra lại kết nối rồi thử lại!",
                        icon: "error",
                        buttons: {
                            confirm: {
                                text: 'Đóng',
                                value: null,
                                visible: true,
                                closeModal: true,
                            },
                        },
                        dangerMode: false,
                    })
                } else {
                    swal.stopLoading();
                }
            });
    }

    render() {

        var pageRangeDisplayed = 1;
        if ($(window).width() < 768) {
            pageRangeDisplayed = 3
        } else {
            pageRangeDisplayed = 5
        }

        const dataRenderHistory = this.state.dataHistory.map((value, index) => {
            return (
                <tr key={index}>
                    <td>{(index + this.state.offset + 1)}</td>
                    <td><img  id={`imageFace_${index}`} className="img_face" src={value.url_image_face ? this.getBase64Axios(`${HOST_EKYC}/` + value.url_image_face,index) : './img/photo-placeholder.png'} alt="" /></td>
                    <td style={{whiteSpace: "initial"}}>{value.name}</td>
                    <td >{value.id}</td>
                    <td >{value.phone_number}</td>
                    {/* {window.innerWidth > 1280 && <td>{value.birthday}</td>} */}
                    {/* {window.innerWidth > 1280 && <td>{value.sex}</td>} */}
                    <td style={{whiteSpace: "initial"}}>{value.work_unit}</td>
                    <td style={{whiteSpace: "initial"}}>{value.id_card}</td>
                    <td className="text-center">{value.date.split(" ")[0]}<br></br>{value.date.split(" ")[1].slice(0,5)}</td>
                    <td className="text-center">{value.checkout_time && Moment(value.checkout_time).format("DD/MM/YYYY")}<br></br>{value.checkout_time && Moment(value.checkout_time).format("HH:mm")}</td>
                    <td>
                        {/* <Tooltip title="Thông tin" placement="top" arrow>
                            <button
                                className="m-portlet__nav-link btn m-btn m-btn--hover-warning m-btn--icon m-btn--icon-only m-btn--pill"
                                onClick={() => this.getInfo(value,1)}
                            >
                                <i className="la la-eye" />
                            </button>
                        </Tooltip>
                        <Tooltip title="Trả thẻ" placement="top" arrow>
                            <button
                                className="m-portlet__nav-link btn m-btn m-btn--hover-warning m-btn--icon m-btn--icon-only m-btn--pill"
                                onClick={() => this.getInfo(value,2)}
                            >
                                <i className="la la-caret-square-o-right" />
                            </button>
                        </Tooltip> */}
                          <button onClick={() => this.returnCard(value,1)} className={`${value.checkout_time ? "d-none" : ""} btn btn-success m-btn--icon mr-2 m-loader--light m-loader--right` }>Trả thẻ</button>
                          <button onClick={() => this.returnCard(value,2)} className={`${value.checkout_time ? "" : "d-none"} btn btn-danger m-btn--icon mr-2 m-loader--light m-loader--right` }>Huỷ</button>
                    </td>
                </tr>
            )
        })

        return (
            <Modal
                {...this.props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                style={{  width: '100%' }}
                backdrop="static"
                dialogClassName={`${window.innerWidth > 1280 ? "modal-size" : "modal-size-res"}`}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div className="m-portlet__body m-portlet__body--no-padding">
                            <div className="row m-row--no-padding m-row--col-separator-xl">
                                <div className="col-xl-12">
                                    <div className="m-widget1">
                                        <div className='row m-0'>
                                            <div className="col-md-3 pl-md-0">
                                                <DatePicker
                                                    className="form-control"
                                                    selected={this.state.startDate}
                                                    dateFormat="dd/MM/yyyy HH:mm"
                                                    timeFormat="HH:mm"
                                                    showTimeSelect
                                                    onChange={this.handleChangeStart}
                                                    selectsStart
                                                    startDate={this.state.startDate}
                                                    endDate={this.state.endDate}
                                                />
                                            </div>
                                            <div className="col-md-3 pl-md-0">
                                                <DatePicker
                                                    className="form-control"
                                                    dateFormat="dd/MM/yyyy HH:mm"
                                                    timeFormat="HH:mm"
                                                    showTimeSelect
                                                    selected={this.state.endDate}
                                                    onChange={this.handleChangeEnd}
                                                    selectsEnd
                                                    startDate={this.state.startDate}
                                                    endDate={this.state.endDate}
                                                    minDate={this.state.startDate}
                                                />
                                            </div>
                                            <div className="col-lg-3 pl-0 m--margin-bottom-10-tablet-and-mobile">
                                                <input type="text" onKeyUp={(event) => this.isChange(event)} id="inputSearch" className="form-control m-input" placeholder="Số định danh / Họ Tên / Số thẻ / SĐT" />
                                            </div>
                                            <div className="col-md-3 pl-md-0">
                                                <button
                                                    onClick={(dataSearch) => {
                                                        this.handleClickSearch()

                                                    }}
                                                    className="btn btn-accent m-btn m-btn--icon" id="m_search">
                                                    <span>
                                                        <i className="la la-search" />
                                                        <span>Tìm kiếm</span>
                                                    </span>
                                                </button>
                                            </div>
                                           
                                        </div>
                                        <div className="table-responsive text-nowrap dataTables_wrapper">
                                            <table className="table table-bordered table-hover dataTable mb-0">
                                                <thead>
                                                    <tr>
                                                        <th style={{'width': '30px' }}>STT</th>
                                                        <th style={{'width': '50px' }}>Ảnh mặt</th>
                                                        <th style={{'width': '170px' }}>Họ Tên</th>
                                                        <th style={{'width': '70px' }}>Số định danh</th>
                                                        <th style={{'width': '70px' }}>SĐT</th>
                                                        {/* {window.innerWidth > 1280 && <th style={{'width': '100px' }}>Ngày sinh</th>} */}
                                                        {/* {window.innerWidth > 1280 && <th style={{'width': '70px' }}>Giới tính</th>} */}
                                                        <th style={{'width': '200px' }}>Đơn vị/Công ty</th>
                                                        <th style={{'width': '30px' }}>Số thẻ</th>
                                                        <th style={{'width': '30px' }}>Thời gian vào</th>
                                                        <th style={{'width': '30px' }}>Thời gian ra</th>
                                                        <th style={{'width': '30px' }}>Thao tác</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {dataRenderHistory}
                                                </tbody>
                                            </table>
                                            <div className="text-center">
                                                <PulseLoader
                                                    css={override}
                                                    sizeUnit={"px"}
                                                    size={12}
                                                    margin={'2px'}
                                                    color={'#36D7B7'}
                                                    loading={this.state.loading}
                                                />
                                            </div>
                                        </div>
                                        
                                        <ModalInfo
                                            show={this.state.ModalInfo}
                                            data={this.state.dataInfo}
                                            onHide={this.modalClose}
                                        />

                                        <ModalReturnCard
                                            show={this.state.ModalReturnCard}
                                            onHide={this.modalClose}
                                            data={this.state.dataInfo}
                                            crrCompany={this.props.crrCompany}
                                            crrBox={this.props.crrBox}
                                        />
                                        
                                        <Pagination
                                            prevPageText='Trang trước'
                                            nextPageText='Trang sau'
                                            firstPageText='Trang đầu'
                                            lastPageText='Trang cuối'
                                            activePage={this.state.activePage}
                                            itemsCountPerPage={this.itemsPerPage}
                                            totalItemsCount={this.state.total_count}
                                            pageRangeDisplayed={pageRangeDisplayed}
                                            onChange={this.handlePageChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        );
    }
}
ModalSearch.propTypes = {
    data: PropTypes.object,
    onHide: PropTypes.func.isRequired,
    show: PropTypes.bool
}

export default ModalSearch;