import moment from 'moment';
import React from 'react';
import { useState } from 'react';
import swal from 'sweetalert';
import { HOST_EKYC, LOGO } from '../../config';
import SuccessDeclaration from './SuccessDeclaration';
import { Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
const initialData = {
    name: '',
    birthday: '',
    sex: '',
    national: '',
    id: '',
    flyCheck: false,
    shipCheck: false,
    carCheck: false,
    vehicleOther: '',
    goDate: '',
    returnDate: '',
    startPlace: '',
    destination: '',
    workPlace: '',
    covidPlace: '',
    phoneDeclare: '',
    fever: '0',
    cough: '0',
    breath: '0',
    throat: '0',
    takeCare: '0',
    startDate: moment(new Date()).format('YYYY-MM-DD'),
}
const datePre = new Date().getTime() - (86400000 * 14);
function MedicalDeclaration() {
    const [crrData, setCrrData] = useState(initialData);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [validName, setValidName] = useState(false);
    const [validWorkPlace, setValidWorkPlace] = useState(false);
    const [validPhone, setVaidPhone] = useState(false);
    const token = useSelector(state => state.isLogin.access_token);
    const handleChangeInput = (e) => {
        setCrrData({
            ...crrData,
            [e.target.name]: e.target.value,
        })
        resetForm()
    }
    const validForm = () => {
        if (!crrData.name) {
            setValidName(true)
        }
        if (!crrData.workPlace) {
            setValidWorkPlace(true)
        }
        if (!crrData.phoneDeclare) {
            setVaidPhone(true)
        }
    }


    const resetForm = () => {
        setValidName(false)
        setValidWorkPlace(false)
        setVaidPhone(false)
    }

    const handleCheckBox = (e) => {
        setCrrData({
            ...crrData,
            [e.target.name]: e.target.checked
        })
    }
    const handleChangeRadio = (e) => {
        setCrrData({
            ...crrData,
            [e.target.name]: e.target.value,
        })
    }
    const handleSubmit = (event) => {
        validForm()
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
          event.stopPropagation();
        } else {
            const dataReq = {
                ...crrData,
                birthday: crrData.birthday ? moment(crrData.birthday).format('DD/MM/YYYY') : '',
                goDate: crrData.goDate ? moment(crrData.goDate).format('DD/MM/YYYY') : '',
                returnDate: crrData.returnDate ? moment(crrData.returnDate).format('DD/MM/YYYY') : '',
                startDate: crrData.startDate ? moment(crrData.startDate).format('DD/MM/YYYY') : '',
            };
            fetch(`${HOST_EKYC}/api/ekyc/declaration/insert`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': token
                },
                body: JSON.stringify(dataReq)
            }).then(response => {
                return response.json();
            }).then(res => {
                if (res.status === 10000) {
                    setIsSuccess(true);
                } else {
                    swal({
                        icon: "error",
                        text: 'Khai báo y tế thất bại',
                        buttons: false,
                        timer: 3000,
                        className: 'alert_medical--declaration',
                    });
                }
            })
        }
        setIsValid(true)
    }
    return (
        <>
            {
                isSuccess ?
                    <SuccessDeclaration />
                    :
                    <Form noValidate validated={isValid} onSubmit={(e) => handleSubmit(e)} className="mt-1 mb-2" style={{ maxWidth: 1200, width: '100%', margin: 'auto' }}>
                        <div style={{ padding: 10 }}><img alt="bitable-print" src={LOGO} style={{ display: 'block', marginRight: 'auto', width: 90 }} /></div>
                        <div style={{ textAlign: 'center', fontSize: '25px' }}>TỜ KHAI Y TẾ</div>
                        <div style={{ padding: '20px 20px 20px 20px ', fontSize: '17px' }}>Đây là tài liệu quan trọng, thông tin của Anh/Chị sẽ giúp cơ quan y tế liên lạc khi cần thiết để phòng chống dịch bệnh truyền nhiễm</div>
                        <div className="col-md-12 mb-3">
                            <label>Họ và tên (<span className='text-danger'>*</span>) :</label>
                            <input type="text" className="form-control m-input" name="name" value={crrData.name} onChange={(e) => handleChangeInput(e)} required/>
                            {validName && <div className='text-danger pt-2'>Vui lòng nhập thông tin</div>}
                        </div>
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-4 mb-3">
                                    <label>Năm sinh:</label>
                                    <div>
                                        <input className="form-control m-input" type="date" style={{ height: 40 }} name="birthday" value={crrData.birthday} onChange={(e) => handleChangeInput(e)} />
                                    </div>
                                </div>
                                <div className="col-md-4 mb-3">
                                    <label>Giới tính:</label>
                                    <input type="text" className="form-control m-input" name="sex" value={crrData.sex} onChange={(e) => handleChangeInput(e)} />
                                </div>
                                <div className="col-md-4 mb-3">
                                    <label>Quốc tịch:</label>
                                    <input type="text" className="form-control m-input" name="national" value={crrData.national} onChange={(e) => handleChangeInput(e)} />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12 mb-3">
                            <label>
                                Số CMND, Căn cước công dân, hộ chiếu (<span className='text-danger'>*</span>) :
                            </label>
                            <input required type="text" className="form-control m-input" name="id" value={crrData.id} onChange={(e) => handleChangeInput(e)} />
                        </div>
                        <div className="col-md-12 mb-3 bt-3">
                            <label>
                                Thông tin đi lại :
                            </label>
                            <label className="m-checkbox ml-3">
                                <input onChange={(e) => handleCheckBox(e)} type="checkbox" checked={crrData.flyCheck} name="flyCheck" /> Tàu bay
                                <span />
                            </label>
                            <label className="m-checkbox mx-3">
                                <input onChange={(e) => handleCheckBox(e)} type="checkbox" checked={crrData.shipCheck} name="shipCheck" /> Tàu thuyền
                                <span />
                            </label>
                            <label className="m-checkbox mx-3">
                                <input onChange={(e) => handleCheckBox(e)} type="checkbox" checked={crrData.carCheck} name="carCheck" /> Ô tô
                                <span />
                            </label>
                            <input type="text" placeholder="Khác(ghi rõ)" className="form-control m-input" name="vehicleOther" value={crrData.vehicleOther} onChange={(e) => handleChangeInput(e)} />
                        </div>

                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <label>Ngày khởi hành:</label>
                                    <div>
                                        <input className="form-control m-input" type="date" style={{ height: 40 }} name="goDate" value={crrData.goDate} onChange={(e) => handleChangeInput(e)} />
                                    </div>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label>Ngày về:</label>
                                    <div>
                                        <input className="form-control m-input" type="date" style={{ height: 40 }} name="returnDate" value={crrData.returnDate} onChange={(e) => handleChangeInput(e)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 mb-3">
                            <label>Địa điểm khởi hành:</label>
                            <input type="text" className="form-control m-input" name="startPlace" value={crrData.startPlace} onChange={(e) => handleChangeInput(e)} />
                        </div>
                        <div className="col-md-12 mb-3">
                            <label>Địa điểm nơi đến:</label>
                            <input type="text" className="form-control m-input" name="destination" value={crrData.destination} onChange={(e) => handleChangeInput(e)} />
                        </div>
                        <div className="col-md-12 mb-3">
                            <label>Nơi làm việc (<span className='text-danger'>*</span>) :</label>
                            <input type="text" className="form-control m-input" name="workPlace" value={crrData.workPlace} onChange={(e) => handleChangeInput(e)} required/>
                            {validWorkPlace && <div className='text-danger pt-2'>Vui lòng nhập thông tin</div>}
                        </div>
                        <div className="col-md-12 mb-3">
                            <label>{`Trong vòng 14 ngày (kể từ ngày ${moment(datePre).format('DD-MM-YYYY')} đến nay), Anh/Chị có đến các địa điểm mà Bộ Y Tế công bố ca nhiễm COVID-19 không?
                                                (Nếu có ghi rõ)`}</label>
                            <input type="text" className="form-control m-input" name="covidPlace" value={crrData.covidPlace} onChange={(e) => handleChangeInput(e)} />
                        </div>
                        <div className="col-md-12 mb-3">
                            <label>Điện thoại (<span className='text-danger'>*</span>) :</label>
                            <input type="number" className="form-control m-input" name="phoneDeclare" value={crrData.phoneDeclare} onChange={(e) => handleChangeInput(e)} required/>
                            {validPhone && <div className='text-danger pt-2'>Vui lòng nhập thông tin</div>}
                        </div>
                        <div className="col-md-12 mb-3">
                            <label>Trong vòng 14 ngày (tính đến thời điểm làm thủ tục khởi hành ) Anh/Chị có thấy xuất hiện dấu hiệu nào sau đây không?</label>
                        </div>
                        <div className="col-md-12 mb-3">
                            <table className="table table-bordered table-hover dataTable mb-3">
                                <thead>
                                    <tr>
                                        <th>
                                            <div className="text-center">
                                                Triệu chứng
                                            </div>
                                        </th>
                                        <th style={{ width: 70 }}>
                                            <div className="text-center">
                                                Có
                                            </div>
                                        </th>
                                        <th style={{ width: 70 }}>
                                            <div className="text-center">
                                                Không
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Sốt</td>
                                        <td>
                                            <div className="text-center">
                                                {<label className="m-radio">
                                                    <input onChange={(e) => handleChangeRadio(e)} value="1" checked={crrData.fever === "1"} name="fever" type="radio" />
                                                    <span />
                                                </label>}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="text-center">
                                                {<label className="m-radio">
                                                    <input onChange={(e) => handleChangeRadio(e)} value="0" checked={crrData.fever === "0"} name="fever" type="radio" />
                                                    <span />
                                                </label>}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Ho</td>
                                        <td>
                                            <div className="text-center">
                                                {<label className="m-radio">
                                                    <input onChange={(e) => handleChangeRadio(e)} value="1" checked={crrData.cough === "1"} name="cough" type="radio" />
                                                    <span />
                                                </label>}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="text-center">
                                                {<label className="m-radio">
                                                    <input onChange={(e) => handleChangeRadio(e)} value="0" checked={crrData.cough === "0"} name="cough" type="radio" />
                                                    <span />
                                                </label>}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Khó thở</td>
                                        <td>
                                            <div className="text-center">
                                                {<label className="m-radio">
                                                    <input onChange={(e) => handleChangeRadio(e)} value="1" checked={crrData.breath === "1"} name="breath" type="radio" />
                                                    <span />
                                                </label>}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="text-center">
                                                {<label className="m-radio">
                                                    <input onChange={(e) => handleChangeRadio(e)} value="0" checked={crrData.breath === "0"} name="breath" type="radio" />
                                                    <span />
                                                </label>}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Đau họng</td>
                                        <td>
                                            <div className="text-center">
                                                {<label className="m-radio">
                                                    <input onChange={(e) => handleChangeRadio(e)} value="1" checked={crrData.throat === "1"} name="throat" type="radio" />
                                                    <span />
                                                </label>}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="text-center">
                                                {<label className="m-radio">
                                                    <input onChange={(e) => handleChangeRadio(e)} value="0" checked={crrData.throat === "0"} name="throat" type="radio" />
                                                    <span />
                                                </label>}
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="col-md-12 mb-3">
                            <table className="table table-bordered table-hover dataTable mb-0">
                                <tbody>
                                    <tr>
                                        <th>
                                            <div>
                                                Trực tiếp chăm sóc người bệnh truyền nhiễm
                                            </div>
                                        </th>
                                        <th style={{ width: 70 }}>
                                            <div className="text-center">
                                                {<label className="m-radio">
                                                    <input onChange={(e) => handleChangeRadio(e)} value="1" checked={crrData.takeCare === "1"} name="takeCare" type="radio" />
                                                    <span />
                                                </label>}
                                            </div>
                                        </th>
                                        <th style={{ width: 70 }}>
                                            <div className="text-center">
                                                {<label className="m-radio">
                                                    <input onChange={(e) => handleChangeRadio(e)} value="0" checked={crrData.takeCare === "0"} name="takeCare" type="radio" />
                                                    <span />
                                                </label>}
                                            </div>
                                        </th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        {/* <div className="col-lg-6 mb-3">
                <label>Ngày khai báo:</label>
                <div>
                    <input className="form-control m-input" type="date" style={{ height: 40 }} name="startDate" value={crrData.startDate} onChange={(e) => handleChangeInput(e)} />
                </div>
            </div> */}
                        <div className="my-5 text-center">
                            <button type="submit"  className="btn btn-success" >Hoàn tất</button>
                        </div>
                    </Form>
            }
        </>

    );
}

export default MedicalDeclaration;