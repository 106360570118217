import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import Moment from 'moment';
import $ from 'jquery';
import DatePicker from "react-datepicker";
import { css } from '@emotion/core';
import ReactToPrint from 'react-to-print';
import { LOGO } from '../../config'
import { HOST_EKYC } from '../../config';
import { Form } from 'react-bootstrap';
import moment from 'moment';
import swal from 'sweetalert';
import store from '../../store';

var dataDeclare = {
    startPlace: '',
    destination: '',
    workPlace: '',
    covidPlace: '',
    phoneDeclare: '',
    vehicleOther: '',
    flyCheck: false,
    shipCheck: false,
    carCheck: false,
    fever: "0",
    cough: "0",
    breath: "0",
    throat: "0",
    takeCare: "0",
    startDate: new Date(),
    goDate: null,
    returnDate: null,
    birthday: '',
    id: '',
    name: '',
    national: '',
    sex: ''
}


var datePrint = new Date().getDate()
if (datePrint.toString().length === 1) {
    datePrint = "0" + datePrint
}

class ComponentToPrint extends Component {
    constructor(props) {
        super(props);

    }
    render() {
        var date = this.props.viewDeclaration ? new Date(Moment(this.props.dataPrint.startDate, 'DD/MM/YYYY')) : new Date(this.props.dataPrint.startDate);

        var datePre = Moment(new Date()).subtract(14, 'days').format('DD/MM/YYYY');
        if (this.props.viewDeclaration) {
            datePre = Moment(this.props.dataPrint.startDate, 'DD/MM/YYYY').subtract(14, 'days').format('DD/MM/YYYY');
        }
        console.log(date)
        var goDate = this.props.viewDeclaration ? this.props.dataPrint.goDate : Moment(this.props.dataPrint.goDate).format('DD/MM/YYYY')
        var returnDate = this.props.viewDeclaration ? this.props.dataPrint.returnDate : Moment(this.props.dataPrint.returnDate).format('DD/MM/YYYY')
        return (
            <div id="divToPrint" >
                <div className="print" id="divToPrint" style={{ display: 'none' }} >
                    <img alt="bitable-print" src={LOGO} style={{ paddingTop: '20px', display: 'block', marginRight: 'auto', width: '15%' }} />
                    <div id="print-title" style={{ textAlign: 'center', paddingTop: '40px', fontSize: '25px' }}>TỜ KHAI Y TẾ</div>
                    <div id="print" style={{ padding: '20px 20px 20px 50px ', fontSize: '17px' }}>Đây là tài liệu quan trọng, thông tin của Anh/Chị sẽ giúp cơ quan y tế liên lạc khi cần thiết để phòng chống dịch bệnh truyền nhiễm</div>
                    <div className=" div_print" id="print" style={{ display: 'flex', padding: '20px 20px 20px 50px ' }} >
                        <div id="print" style={{ paddingRight: '10px', fontSize: '17px' }}>Họ tên (viết chữ in hoa):</div><div style={{ fontSize: '17px', textTransform: 'uppercase' }}>{!this.props.dataPrint.name ? "........................................." : this.props.dataPrint.name}</div>
                    </div>
                    <div className=" div_print" style={{ display: 'flex', padding: '20px 20px 20px 50px ' }}>
                        <div id="print" style={{ paddingRight: '10px', fontSize: '17px' }}>Năm sinh:</div><div style={{ fontSize: '17px' }}>{!this.props.dataPrint.birthday ? "........................................." : this.props.dataPrint.birthday}</div>
                        <div id="print" style={{ paddingRight: '10px', paddingLeft: '20px', fontSize: '17px' }}>Giới tính:</div><div style={{ fontSize: '17px' }}>{!this.props.dataPrint.sex ? "........................................." : this.props.dataPrint.sex}</div>
                        <div id="print" style={{ paddingRight: '10px', paddingLeft: '20px', fontSize: '17px' }}>Quốc tịch:</div><div style={{ fontSize: '17px' }}>{!this.props.dataPrint.national ? "........................................." : this.props.dataPrint.national}</div>
                    </div >
                    <div className=" div_print" style={{ display: 'flex', padding: '20px 20px 20px 50px ' }}>
                        <div id="print" style={{ paddingRight: '10px', fontSize: '17px' }}>Số CMND, Căn cước công dân, hộ chiếu:</div><div style={{ fontSize: '17px' }}>{!this.props.dataPrint.id ? "........................................." : this.props.dataPrint.id}</div>
                    </div>
                    <div className=" div_print" style={{ display: 'flex', padding: '20px 20px 20px 50px ' }}>
                        <div id="print" style={{ paddingRight: '10px', fontSize: '17px' }}>Thông tin đi lại:</div>
                        <div id="print" style={{ fontSize: '17px' }}>
                            Tàu bay <input type="checkbox" checked={this.props.dataPrint.flyCheck === true ? true : false} />
                        </div>
                        <div id="print" style={{ fontSize: '17px', paddingLeft: '20px' }}>
                            Tàu thuyền <input type="checkbox" checked={this.props.dataPrint.shipCheck === true ? true : false} />
                        </div>
                        <div id="print" style={{ fontSize: '17px', paddingLeft: '20px' }}>
                            Ô tô <input type="checkbox" checked={this.props.dataPrint.carCheck === true ? true : false} />
                        </div>
                        <div id="print" style={{ fontSize: '17px', paddingLeft: '20px' }}>Khác (ghi rõ): {`${this.props.dataPrint.vehicleOther ? this.props.dataPrint.vehicleOther : "........................................................................................................."} `}</div>
                    </div>
                    <div className=" div_print" style={{ display: 'flex', padding: '20px 20px 20px 50px ' }}>
                        <div id="print" style={{ paddingRight: '10px', fontSize: '17px' }}>Ngày khởi hành:</div>
                        <div style={{ fontSize: '17px' }}>{`${this.props.dataPrint.goDate ? goDate : ".........../.........../................"}`}</div>
                        <div id="print" style={{ paddingRight: '10px', paddingLeft: '30px', fontSize: '17px' }}>Ngày về:</div>
                        <div style={{ fontSize: '17px' }}>{`${this.props.dataPrint.returnDate ? returnDate : ".........../.........../................"}`}</div>
                    </div >
                    <div className=" div_print" style={{ display: 'flex', padding: '20px 20px 20px 50px ' }} >
                        <div id="print" style={{ paddingRight: '10px', fontSize: '17px' }}>Địa điểm khởi hành :</div>
                        <div id="print" style={{ fontSize: '17px' }}>{`${this.props.dataPrint.startPlace ? this.props.dataPrint.startPlace : "..................................................................................................................................................................................."}`}</div>
                    </div>
                    <div className=" div_print" style={{ display: 'flex', padding: '20px 20px 20px 50px ' }} >
                        <div id="print" style={{ paddingRight: '10px', fontSize: '17px' }}>Địa điểm nơi đến :</div>
                        <div id="print" style={{ fontSize: '17px' }}>{`${this.props.dataPrint.destination ? this.props.dataPrint.destination : "........................................................................................................................................................................................"}`}</div>
                    </div>
                    <div className=" div_print" style={{ display: 'flex', padding: '20px 20px 20px 50px ' }} >
                        <div id="print" style={{ paddingRight: '10px', fontSize: '17px' }}>Nơi làm việc :</div>
                        <div id="print" style={{ fontSize: '17px' }}>{`${this.props.dataPrint.workPlace ? this.props.dataPrint.workPlace : "..............................................................................................................................................................................................."}`}</div>
                    </div>
                    <div className=" div_print" style={{ display: 'flex', padding: '20px 20px 20px 50px ' }} >
                        <div id="print" style={{ paddingRight: '10px', fontSize: '17px' }}>{`Trong vòng 14 ngày (kể từ ngày ${datePre} đến nay), Anh/Chị có đến các địa điểm mà Bộ Y Tế công bố ca nhiễm COVID-19 không? (Nếu có ghi rõ) :`}</div>
                    </div>
                    <div className=" div_print" style={{ display: 'flex', padding: '10px 20px 20px 50px ' }} >
                        <div id="print" style={{ fontSize: '17px' }}>{`${this.props.dataPrint.covidPlace ? this.props.dataPrint.covidPlace : "........................................................................................................................................................................................................................"}`}</div>
                    </div>
                    <div className=" div_print" style={{ display: 'flex', padding: '20px 20px 20px 50px ' }} >
                        <div id="print" style={{ paddingRight: '10px', fontSize: '17px' }}>Điện thoại :</div>
                        <div id="print" style={{ fontSize: '17px' }}>{`${this.props.dataPrint.phoneDeclare ? this.props.dataPrint.phoneDeclare : "..................................................."}`}</div>
                    </div>
                    <div className=" div_print" style={{ display: 'flex', padding: '20px 20px 20px 50px ' }} >
                        <div id="print" style={{ paddingRight: '10px', fontSize: '17px' }}>Trong vòng 14 ngày (tính đến thời điểm làm thủ tục khởi hành ) Anh/Chị có thấy xuất hiện dấu hiệu  nào sau đây không ?</div>
                    </div>
                    <div className=" div_print" id="print table" style={{ fontSize: '17px', padding: '20px 50px 10px 50px ' }}>
                        <table className="table table-bordered table-hover table-checkable dataTable no-footer dtr-inline collapsed" >
                            <thead id="print">
                                <tr style={{ textAlign: 'center' }}>
                                    <th>Triệu chứng</th>
                                    <th>Có </th>
                                    <th>Không</th>
                                </tr>
                            </thead>
                            <tbody id="print">
                                <tr >
                                    <td>Sốt</td>
                                    <td style={{ whiteSpace: 'pre', textAlign: 'center' }}>{`${this.props.dataPrint.fever === "1" ? "✓" : ""}`}</td>
                                    <td style={{ whiteSpace: 'pre', textAlign: 'center' }}>{`${this.props.dataPrint.fever === "0" ? "✓" : ""}`}</td>
                                </tr>
                                <tr >
                                    <td>Ho</td>
                                    <td style={{ whiteSpace: 'pre', textAlign: 'center' }}>{`${this.props.dataPrint.cough === "1" ? "✓" : ""}`}</td>
                                    <td style={{ whiteSpace: 'pre', textAlign: 'center' }}>{`${this.props.dataPrint.cough === "0" ? "✓" : ""}`}</td>
                                </tr>
                                <tr >
                                    <td>Khó thở</td>
                                    <td style={{ whiteSpace: 'pre', textAlign: 'center' }}>{`${this.props.dataPrint.breath === "1" ? "✓" : ""}`}</td>
                                    <td style={{ whiteSpace: 'pre', textAlign: 'center' }}>{`${this.props.dataPrint.breath === "0" ? "✓" : ""}`}</td>
                                </tr>
                                <tr >
                                    <td>Đau họng</td>
                                    <td style={{ whiteSpace: 'pre', textAlign: 'center' }}>{`${this.props.dataPrint.throat === "1" ? "✓" : ""}`}</td>
                                    <td style={{ whiteSpace: 'pre', textAlign: 'center' }}>{`${this.props.dataPrint.throat === "0" ? "✓" : ""}`}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className=" div_print" id="print" style={{ fontSize: '17px', padding: '10px 50px 10px 50px ' }}>
                        <table className="table table-bordered table-hover table-checkable dataTable no-footer dtr-inline collapsed" >
                            <thead id="print">
                                <tr style={{ textAlign: 'center' }}>
                                    <th style={{ textAlign: 'center' }}>Trực tiếp chăm sóc người bệnh truyền nhiễm </th>
                                    <th style={{ whiteSpace: 'pre', textAlign: 'center' }}>{`${this.props.dataPrint.takeCare === "1" ? "Có [ ✓ ]" : "Có [   ]"}`}</th>
                                    <th style={{ whiteSpace: 'pre', textAlign: 'center' }}>{`${this.props.dataPrint.takeCare === "0" ? "Không [ ✓ ]" : "Không [   ]"}`}</th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div className=" div_print" style={{ display: 'flex', padding: '10px 20px 5px 50px ' }} >
                        <div id="print" style={{ paddingRight: '10px', fontSize: '17px' }}>Tôi cam kết những thông tin trên là đúng sự thật.</div>
                    </div>
                    <div style={{ display: 'flex', padding: '5px 20px 2px 50px ' }} >
                        <div id="print" style={{ fontSize: '17px', whiteSpace: 'pre' }}>{`Ngày  ${date.getDate()}  Tháng  ${date.getMonth() + 1}  Năm  ${date.getFullYear()}`}</div>
                        <div id="printSign" style={{ paddingRight: '10px', paddingLeft: '480px', fontSize: '17px' }}>Kí tên</div>
                    </div>
                    <div id="print" style={{ paddingLeft: '755px', fontSize: '17px' }}>(ghi rõ họ tên)</div>
                </div>
            </div>
        )
    }
}



class ModalDeclare extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataDeclare: {
                ...dataDeclare,
            },
            isValid: false,
            access_token:""
        }

    }




    handleChangeChecked = (e) => {
        if(this.props.viewDeclaration) return;
        var { dataDeclare } = this.state
        dataDeclare[e.target.name] = e.target.checked
        this.setState({
            dataDeclare,
        });
    }

    handleChangeRadio = (value) => {
        if(this.props.viewDeclaration) return;
        var { dataDeclare } = this.state;
        dataDeclare[value.target.name] = value.target.value;
        this.setState({
            dataDeclare,
        });
    }

    onChangeDeclare = (value) => {
        if(this.props.viewDeclaration) return;
        var { dataDeclare } = this.state;
        dataDeclare[value.target.name] = value.target.value;
        this.setState({
            dataDeclare,
        });
    }

    onSave = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
          event.stopPropagation();
        } else {
            const dataPost = {
                ...this.state.dataDeclare,
                goDate: this.state.dataDeclare.goDate ? moment(this.state.dataDeclare.goDate).format('DD/MM/YYYY') : '',
                returnDate: this.state.dataDeclare.returnDate ? moment(this.state.dataDeclare.returnDate).format('DD/MM/YYYY') : '',
                startDate: this.state.dataDeclare.startDate ? moment(this.state.dataDeclare.startDate).format('DD/MM/YYYY') : '',
            }
            fetch(`${HOST_EKYC}/api/ekyc/declaration/insert`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': store.getState().isLogin.access_token
                },
                body: JSON.stringify(dataPost)
            }).then(((res) => {
                return res.json()
            })).then((rs) => {
                if (rs.status === 10000) {
                    swal({
                        icon: "success",
                        text: 'Khai báo y tế thành công',
                        buttons: false,
                        timer: 3000,
                        className: 'alert_medical--declaration',
                    });
                    this.props.onHide()
                } else {
                    swal({
                        icon: "error",
                        text: 'Khai báo y tế thất bại',
                        buttons: false,
                        timer: 3000,
                        className: 'alert_medical--declaration',
                    });
                }
            })
        }
        this.setState({isValid: true});
    }


    handleChangeGoDate = (date) => {
        if(this.props.viewDeclaration) return;
        this.setState({
            dataDeclare: { ...this.state.dataDeclare, goDate: date }
        });
    }

    handleDateClare = (date) => {
        if(this.props.viewDeclaration) return;
        this.setState({
            dataDeclare: { ...this.state.dataDeclare, startDate: date }
        });
    }

    handleChangeEndDate = (date) => {
        if(this.props.viewDeclaration) return;
        this.setState({
            dataDeclare: { ...this.state.dataDeclare, returnDate: date }
        });
    }

    handleChangeBirthday = (date) => {
        if(this.props.viewDeclaration) return;
        this.setState({
            dataDeclare: { ...this.state.dataDeclare, birthday: date }
        });
    }

    componentDidMount() {
        this.setState({
            isValid: false,
            dataDeclare: this.props.viewDeclaration ? this.props.data : {
                ...dataDeclare,
                id: this.props.data.id,
                phoneDeclare: this.props.phone,
                national: this.props.data.national,
                name: this.props.data.name,
                sex: this.props.data.sex,
                birthday: this.props.data.birthday,
            },
        })
    }

    render() {
        var datePre = Moment(new Date()).subtract(14, 'days').format('DD/MM/YYYY');
        if (this.props.viewDeclaration) {
            datePre = Moment(this.state.dataDeclare.startDate, 'DD/MM/YYYY').subtract(14, 'days').format('DD/MM/YYYY');
        }

        return (
            <Modal
                {...this.props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row'>
                        <Form validated={this.state.isValid}  id="myform" noValidate onSubmit={(e) => this.onSave(e)} className="form-group m-form__group p-0">
                            <div>
                                <div className="col-md-12">
                                    <img alt="bitable-print" src={LOGO} style={{ display: 'block', marginRight: 'auto', width: '15%' }} />
                                    <div id="print-title" style={{ textAlign: 'center', fontSize: '25px' }}>TỜ KHAI Y TẾ</div>
                                    <div id="print" style={{ padding: '20px 20px 20px 20px ', fontSize: '17px' }}>Đây là tài liệu quan trọng, thông tin của Anh/Chị sẽ giúp cơ quan y tế liên lạc khi cần thiết để phòng chống dịch bệnh truyền nhiễm</div>
                                    <div className="col-md-12 mb-3">
                                        <label>Họ và tên (<span className='text-danger'>*</span>):</label>
                                        <input required type="text" className="form-control m-input" name="name" value={this.state.dataDeclare.name} onChange={(value) => this.onChangeDeclare(value)} />
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label>Năm sinh:</label>
                                                <div>
                                                    <input type="text" className="form-control m-input" name="birthday" value={this.state.dataDeclare.birthday} onChange={(value) => this.onChangeDeclare(value)} />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <label>Giới tính:</label>
                                                <input type="text" className="form-control m-input" name="sex" value={this.state.dataDeclare.sex} onChange={(value) => this.onChangeDeclare(value)} />
                                            </div>
                                            <div className="col-md-4">
                                                <label>Quốc tịch:</label>
                                                <input type="text" className="form-control m-input" name="national" value={this.state.dataDeclare.national} onChange={(value) => this.onChangeDeclare(value)} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-12 mb-3">
                                        <label>
                                            Số CMND, Căn cước công dân, hộ chiếu (<span className='text-danger'>*</span>):
                                                        </label>
                                        <input required type="text" className="form-control m-input" name="id" value={this.state.dataDeclare.id} onChange={(value) => this.onChangeDeclare(value)} />
                                    </div>
                                    <div className="col-md-12 mb-3 bt-3">
                                        <label>
                                            Thông tin đi lại:
                                                </label>
                                        <label className="m-checkbox ml-3">
                                            <input onChange={this.handleChangeChecked} type="checkbox" checked={this.state.dataDeclare.flyCheck} name="flyCheck" /> Tàu bay
                                                    <span />
                                        </label>
                                        <label className="m-checkbox m-3">
                                            <input onChange={this.handleChangeChecked} type="checkbox" checked={this.state.dataDeclare.shipCheck} name="shipCheck" /> Tàu thuyền
                                                    <span />
                                        </label>
                                        <label className="m-checkbox m-3">
                                            <input onChange={this.handleChangeChecked} type="checkbox" checked={this.state.dataDeclare.carCheck} name="carCheck" /> Ô tô
                                                    <span />
                                        </label>
                                        <input type="text" placeholder="Khác(ghi rõ)" className="form-control m-input" name="vehicleOther" value={this.state.dataDeclare.vehicleOther} onChange={(value) => this.onChangeDeclare(value)} />
                                    </div>

                                    <div className="col-md-12 mb-3">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label>Ngày khởi hành:</label>
                                                <div>
                                                    {
                                                        this.props.viewDeclaration ?
                                                            <input className="form-control m-input" type="text" value={this.state.dataDeclare.goDate} />
                                                            :
                                                            <DatePicker
                                                                show
                                                                className="form-control"
                                                                selected={this.state.dataDeclare.goDate}
                                                                dateFormat="dd/MM/yyyy"
                                                                selectsStart
                                                                onChange={this.handleChangeGoDate}
                                                                startDate={this.state.dataDeclare.goDate}
                                                                endDate={this.state.dataDeclare.returnDate}
                                                    />
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <label>Ngày về:</label>
                                                <div>
                                                    {
                                                        this.props.viewDeclaration ?
                                                            <input className="form-control m-input" type="text" value={this.state.dataDeclare.returnDate} />
                                                            :
                                                            <DatePicker
                                                                className="form-control"
                                                                selected={this.state.dataDeclare.returnDate}
                                                                onChange={this.handleChangeEndDate}
                                                                dateFormat="dd/MM/yyyy"
                                                                selectsEnd
                                                                startDate={this.state.dataDeclare.goDate}
                                                                endDate={this.state.dataDeclare.returnDate}
                                                            />}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <label>Địa điểm khởi hành:</label>
                                        <input type="text" className="form-control m-input" name="startPlace" value={this.state.dataDeclare.startPlace} onChange={(value) => this.onChangeDeclare(value)} />
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <label>Địa điểm nơi đến:</label>
                                        <input type="text" className="form-control m-input" name="destination" value={this.state.dataDeclare.destination} onChange={(value) => this.onChangeDeclare(value)} />
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <label>Nơi làm việc (<span className='text-danger'>*</span>):</label>
                                        <input required type="text" className="form-control m-input" name="workPlace" value={this.state.dataDeclare.workPlace} onChange={(value) => this.onChangeDeclare(value)} />
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <label>{`Trong vòng 14 ngày (kể từ ngày ${datePre} đến nay), Anh/Chị có đến các địa điểm mà Bộ Y Tế công bố ca nhiễm COVID-19 không?
                                                (Nếu có ghi rõ)`}</label>
                                        <input type="text" className="form-control m-input" name="covidPlace" value={this.state.dataDeclare.covidPlace} onChange={(value) => this.onChangeDeclare(value)} />
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <label>Điện thoại (<span className='text-danger'>*</span>):</label>
                                        <input type="number" required className="form-control m-input" name="phoneDeclare" value={this.state.dataDeclare.phoneDeclare} onChange={(value) => this.onChangeDeclare(value)} />
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <label>Trong vòng 14 ngày (tính đến thời điểm làm thủ tục khởi hành ) Anh/Chị có thấy xuất hiện dấu hiệu nào sau đây không?</label>
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <table className="table table-bordered table-hover dataTable mb-3">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <div className="text-center">
                                                            Triệu chứng
                                                            </div>
                                                    </th>
                                                    <th>
                                                        <div className="text-center">
                                                            Có
                                                            </div>
                                                    </th>
                                                    <th>
                                                        <div className="text-center">
                                                            Không
                                                            </div>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Sốt</td>
                                                    <td>
                                                        <div className="text-center">
                                                            {<label className="m-radio">
                                                                <input onChange={this.handleChangeRadio} value="1" checked={this.state.dataDeclare.fever === "1"} name="fever" type="radio" />
                                                                <span />
                                                            </label>}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="text-center">
                                                            {<label className="m-radio">
                                                                <input onChange={this.handleChangeRadio} value="0" checked={this.state.dataDeclare.fever === "0"} name="fever" type="radio" />
                                                                <span />
                                                            </label>}
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Ho</td>
                                                    <td>
                                                        <div className="text-center">
                                                            {<label className="m-radio">
                                                                <input onChange={this.handleChangeRadio} value="1" checked={this.state.dataDeclare.cough === "1"} name="cough" type="radio" />
                                                                <span />
                                                            </label>}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="text-center">
                                                            {<label className="m-radio">
                                                                <input onChange={this.handleChangeRadio} value="0" checked={this.state.dataDeclare.cough === "0"} name="cough" type="radio" />
                                                                <span />
                                                            </label>}
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Khó thở</td>
                                                    <td>
                                                        <div className="text-center">
                                                            {<label className="m-radio">
                                                                <input onChange={this.handleChangeRadio} value="1" checked={this.state.dataDeclare.breath === "1"} name="breath" type="radio" />
                                                                <span />
                                                            </label>}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="text-center">
                                                            {<label className="m-radio">
                                                                <input onChange={this.handleChangeRadio} value="0" checked={this.state.dataDeclare.breath === "0"} name="breath" type="radio" />
                                                                <span />
                                                            </label>}
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Đau họng</td>
                                                    <td>
                                                        <div className="text-center">
                                                            {<label className="m-radio">
                                                                <input onChange={this.handleChangeRadio} value="1" checked={this.state.dataDeclare.throat === "1"} name="throat" type="radio" />
                                                                <span />
                                                            </label>}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="text-center">
                                                            {<label className="m-radio">
                                                                <input onChange={this.handleChangeRadio} value="0" checked={this.state.dataDeclare.throat === "0"} name="throat" type="radio" />
                                                                <span />
                                                            </label>}
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <table className="table table-bordered table-hover dataTable mb-0">
                                            <tbody>
                                                <tr>
                                                    <th>
                                                        <div className="text-center">
                                                            Trực tiếp chăm sóc người bệnh truyền nhiễm
                                                            </div>
                                                    </th>
                                                    <th>
                                                        <div className="text-center">
                                                            {<label className="m-radio">
                                                                <input onChange={this.handleChangeRadio} value="1" checked={this.state.dataDeclare.takeCare === "1"} name="takeCare" type="radio" /> Có
                                                                    <span />
                                                            </label>}
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div className="text-center">
                                                            {<label className="m-radio">
                                                                <input onChange={this.handleChangeRadio} value="0" checked={this.state.dataDeclare.takeCare === "0"} name="takeCare" type="radio" /> Không
                                                                    <span />
                                                            </label>}
                                                        </div>
                                                    </th>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    {
                                        this.props.viewDeclaration && <div className="col-lg-6 mb-3">
                                            <label>Ngày khai báo:</label>
                                            <div>
                                                <input className="form-control m-input" type="text" value={this.state.dataDeclare.startDate} />
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </Form>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <ReactToPrint
                        trigger={() => <button type="button" className="btn btn-success m-btn--icon mr-2 m-loader--light m-loader--right text-left">In</button>}
                        content={() => this.componentRef}
                    />
                    {
                        !this.props.viewDeclaration &&
                        <>
                            <button type="submit" form="myform" className={"btn btn-success m-btn--icon mr-2 m-loader--light m-loader--right "} >Hoàn tất</button>
                            <button className={"btn btn-danger m-btn--icon mr-2 m-loader--light m-loader--right "} onClick={this.props.onHide}>Hủy</button>
                        </>
                    }
                    {<ComponentToPrint ref={el => (this.componentRef = el)} dataPrint={this.state.dataDeclare} viewDeclaration={this.props.viewDeclaration}/>}
                </Modal.Footer>
            </Modal>
        );
    }
}

export default ModalDeclare;