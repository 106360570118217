import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {  Modal } from 'react-bootstrap';
import makeAnimated from 'react-select/animated';
import { HOST_EKYC } from '../../config'
import { Tab, Tabs } from 'react-bootstrap';


var dataInfoDefault = {
    address: "",
    country: "",
    date: '',
    ethnicity: "",
    expiry: "",
    id_type: "",
    issue_by: "",
    issue_date: "",
    national: "",
    religion: "",
    time: '',
    id: '',
    name: '',
    birthday: '',
    sex: '',
    reason: '',
    origin: '',
    class: '',
    id_staff: '',
    image_face: '',
    image_front: '',
    image_back: '',
    id_card: '',
    note: '',
    people: '',
    save_percent: '',
}



class ModalInfo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataInfo: {
                ...dataInfoDefault
            },
        }

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.show === true) {
            var info = nextProps.data;
            console.log("data", info)
            var { dataInfo } = this.state;

            dataInfo.url_image_face = info.url_image_face;
            dataInfo.url_image_front = info.url_image_front;
            dataInfo.url_image_back = info.url_image_back;
            dataInfo.id = info.id;
            dataInfo.name = info.name;
            dataInfo.birthday = info.birthday;
            dataInfo.sex = info.sex;
            dataInfo.ethnicity = info.ethnicity;
            dataInfo.national = info.national;
            dataInfo.origin = info.origin;
            dataInfo.address = info.address;
            dataInfo.expiry = info.expiry;
            dataInfo.religion = info.religion;
            dataInfo.issue_date = info.issue_date;
            dataInfo.issue_by = info.issue_by;
            dataInfo.class = info.class;
            dataInfo.reason = info.reason;
            dataInfo.id_card = info.id_card;
            dataInfo.people = info.people;
            dataInfo.note = info.note;
            dataInfo.date = info.date;
            this.setState({
                dataInfo
            });


        }
    }

    render() {

        return (
            <Modal
                {...this.props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                style={{ width: '100%' }}
                backdrop="true"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tabs defaultActiveKey="update" transition={false} id="uncontrolled-tab-example">
                        <Tab eventKey="update" title="Thông tin cá nhân">
                            <div className="col-md-12">
                                <div className='row'>
                                    <div className="col-md-6">
                                        <div className="form-group m-form__group p-0">
                                            <h3>Dữ liệu ảnh mặt trước</h3>
                                            <div className="mt-4 row">
                                                <div className="col-lg-12 mb-3">
                                                    <img className="img_front" src={this.state.dataInfo.url_image_front == "" ? './img/no-image.jpg' : `${HOST_EKYC}/` + this.state.dataInfo.url_image_front} alt=""
                                                        style={{
                                                            width: '100%',
                                                            maxHeight: '500px',
                                                            objectFit: 'contain',
                                                            height: "250px",
                                                        }} />
                                                </div>

                                                <div className="col-lg-12 mb-3">
                                                    <label>
                                                        Số định danh giấy tờ:&nbsp;
                                                        </label>
                                                    <label className='font-weight-bold'>
                                                        {this.state.dataInfo.id}
                                                    </label>
                                                </div>
                                                <div className="col-lg-12 pr-1 mb-3">
                                                    <label>Họ và tên:&nbsp;</label>
                                                    <label className='font-weight-bold'>
                                                        {this.state.dataInfo.name}
                                                    </label>
                                                </div>
                                                <div className="col-lg-12 pr-1 mb-3">
                                                    <label>Ngày sinh:&nbsp;</label>
                                                    <label className='font-weight-bold'>
                                                        {this.state.dataInfo.birthday}
                                                    </label>
                                                </div>
                                                <div className="col-lg-6 pr-1 mb-3">
                                                    <label>Quốc gia:&nbsp;</label>
                                                    <label className='font-weight-bold'>
                                                        {this.state.dataInfo.national}
                                                    </label>
                                                </div>
                                                <div className="col-lg-6 pl-1 mb-3">
                                                    <label>Quốc tịch:&nbsp;</label>
                                                    <label className='font-weight-bold'>
                                                        {this.state.dataInfo.national}
                                                    </label>
                                                </div>
                                                <div className="col-lg-6 pr-1 mb-3">
                                                        <label>Giới tính:&nbsp;</label>
                                                        <label className='font-weight-bold'>
                                                            {this.state.dataInfo.sex}
                                                        </label>
                                                    </div>
                                                    <div className="col-lg-6 pr-1 mb-3">
                                                        <label>Loại bằng:&nbsp;</label>
                                                        <label className='font-weight-bold'>
                                                            {this.state.dataInfo.class}
                                                        </label>
                                                    </div>
                                                <div className="col-lg-12 mb-3">

                                                    <label>Ngày hết hạn:&nbsp;</label>
                                                    <label className='font-weight-bold'>
                                                        {this.state.dataInfo.expiry}
                                                    </label>
                                                </div>

                                                <div className="col-lg-12 mb-3">
                                                    <label>Nguyên quán:&nbsp;</label>
                                                    <label className='font-weight-bold'>
                                                        {this.state.dataInfo.origin}
                                                    </label>
                                                </div>
                                                <div className="col-lg-12 mb-3">
                                                    <label>Nơi ĐKHKTT:&nbsp;</label>
                                                    <label className='font-weight-bold'>
                                                        {this.state.dataInfo.address}
                                                    </label>
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group m-form__group p-0">
                                            <h3>Dữ liệu ảnh mặt sau</h3>
                                            <div className="mt-4 row">
                                                <div className="col-lg-12 mb-3">
                                                    <img className="img_front" src={this.state.dataInfo.url_image_back == "" ? "./img/no-image.jpg" : `${HOST_EKYC}/` + this.state.dataInfo.url_image_back} alt=""
                                                        style={{
                                                            width: '100%',
                                                            maxHeight: '500px',
                                                            objectFit: 'contain',
                                                            height: "250px",
                                                        }} />
                                                </div>
                                                <div className="col-lg-12 mb-3">
                                                    <label>Cấp bởi:&nbsp;</label>
                                                    <label className='font-weight-bold'>
                                                        {this.state.dataInfo.issue_by}
                                                    </label>
                                                </div>
                                                <div className="col-lg-12 mb-3">
                                                    <label>Ngày cấp:&nbsp;</label>
                                                    <label className='font-weight-bold'>
                                                        {this.state.dataInfo.issue_date}
                                                    </label>
                                                </div>
                                                <div className="col-lg-12 mb-3">
                                                    <label>Số thẻ:&nbsp;</label>
                                                    <label className='font-weight-bold'>
                                                        {this.state.dataInfo.id_card}
                                                    </label>
                                                </div>
                                                <div className="col-lg-12 mb-3">
                                                    <label>Mục đích:&nbsp;</label>
                                                    <label className='font-weight-bold'>
                                                        {this.state.dataInfo.people}
                                                    </label>
                                                </div>
                                                <div className="col-lg-12 mb-3">
                                                    <label>Nội dung làm việc&nbsp;</label>
                                                    <label className='font-weight-bold'>
                                                        {this.state.dataInfo.reason}
                                                    </label>
                                                </div>
                                                <div className="col-lg-12 mb-3">
                                                    <label>Ghi chú:&nbsp;</label>
                                                    <label className='font-weight-bold'>
                                                        {this.state.dataInfo.note}
                                                    </label>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </Tab>
                        {/* {
                            <Tab eventKey="merge" title="Thông tin phương tiện">

                            </Tab>
                        } */}
                    </Tabs>
                </Modal.Body>
                <Modal.Footer>

                    {/* <Button variant="accent" id="button_addStaff" className="m-loader--light m-loader--right" onClick={}>{}</Button>
                    <Button variant="secondary" onClick={this.props.onHide}>{}</Button> */}
                </Modal.Footer>
            </Modal>
        );
    }
}
ModalInfo.propTypes = {
    data: PropTypes.object,
    onHide: PropTypes.func.isRequired,
    show: PropTypes.bool
}

export default ModalInfo;