import React, { Component } from 'react';
import swal from 'sweetalert';
import { css } from '@emotion/core';
import { PulseLoader } from 'react-spinners';
import Pagination from "react-js-pagination";
import Moment from 'moment';
import { HOST_EKYC } from '../../config'
import $ from 'jquery';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import store from '../../store';
import { id } from 'date-fns/esm/locale';
import Tooltip from '@material-ui/core/Tooltip';
import ModalEditAppointment from './modalEditAppointment';
import ModalInfo from './ModalInfo';
import { withRouter } from 'react-router-dom';
import ModalRegister from '../BiTable/modalRegister';
import { setIntegrate } from '../../actions/company';
import axios from 'axios';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

var dataInfoDefault = {
    address: "",
    country: "",
    date: Moment(new Date()).format('DD/MM/YYYY HH:mm:ss'),
    ethnicity: "",
    expiry: "",
    id_type: "",
    issue_by: "",
    issue_date: "",
    national: "",
    religion: "",
    time: Moment(new Date()).format('HH:mm:ss'),
    id: '',
    name: '',
    birthday: '',
    sex: '',
    expiry: '',
    reason: '',
    origin: '',
    class: '',
    id_staff: '',
    image_face: '',
    image_front: '',
    image_back: '',
    id_card: '',
    note: '',
    people: '',
    save_percent: '',
}


const all_camera = {
    id_box_engine: -1,
    title: 'Tất cả camera',
}


//In file 


class HistoryAppointment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data_json_export: [],
            dataHistory: [],
            dataAppointment: [],
            total_count: 0,
            offset: 0,
            activePage: 1,
            startDate: new Date(),
            endDate: null,
            keyWord: '',
            loading: true,
            ModalInfo: false,
            dataInfo: {
                ...dataInfoDefault
            },
            listBox: store.getState().company.crr_company ? [{
                id_box_engine: -1,
                title: 'Tất cả camera',
            }].concat(store.getState().company.crr_company.dataBox) : [all_camera],
            listCam: store.getState().company.crr_company ? store.getState().company.crr_company.dataBox2 : [],
            crrBox: all_camera,
            crrCompany: store.getState().company.crr_company ? store.getState().company.crr_company : null,
            showModalEdit: false,
            showModalInfo: false,
            crrValue: null,
            search :"",
            showModalRegister: false,
            listCamConfig: [],
            listPerson: [],
            appointment: true,
        }
        store.subscribe(() => {
            this.setState({
                crrCompany: store.getState().company.crr_company,
            }, () => {
                let listBox = this.state.crrCompany ? [...this.state.crrCompany.dataBox] : [];
                listBox.unshift(all_camera)
                this.setState({
                    listBox: listBox,
                    listCam: this.state.crrCompany ? this.state.crrCompany.dataBox2 : [],
                    crrBox: all_camera,
                }, () => {
                    this.getAppointmentSchedule();
                    this.getListCamera();
                    this.getListPerson();
                });
            })
        })
        this.itemsPerPage = 5;
    }
    
    componentDidMount = () => {
        this.getAppointmentSchedule();
        this.getListCamera();
        this.getListPerson();

    };
    modalClose = () => {
        this.setState({
            ModalInfo: false,
        });
    }
    changeHandleBox = (e, value) => {
        this.setState({
            crrBox: value,
        })
    }

    modalClose = () => {
        this.setState({
            ModalInfo: false,
        });
    }



    getAppointmentSchedule = () => {
        const { integrate, obj_id } = this.props;
        if (!this.state.crrCompany && !integrate) return;
        if (integrate) {
            fetch(`${HOST_EKYC}/api/appointment_schedule/get_by_creator`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': store.getState().isLogin.access_token
                },
                body: JSON.stringify({
                    obj_id: obj_id,
                    start_date: this.state.startDate ? Moment(this.state.startDate).format("YYYY-MM-DD") : '',
                    end_date: this.state.endDate ? Moment(this.state.endDate).format("YYYY-MM-DD") : '2999-12-31',
                    index: this.state.activePage,
                    item_per_page: this.itemsPerPage,
                    search: this.state.search,
                })
            }).then((response) => {
                return response.json()
            }).then((dataRes) => {
                this.setState({
                    loading: false,
                })
                if (dataRes && dataRes.status === 10000) {
                    this.setState({
                        dataAppointment: dataRes.data.schedules,
                        total_count: dataRes.data.count,
                    })
                }
            }).catch((error) => {
                this.setState({
                    dataAppointment: [],
                    loading: false
                })
                console.log(error)
            })
        } else {
            fetch(`${HOST_EKYC}/api/appointment_schedule/get_all`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': store.getState().isLogin.access_token
                },
                body: JSON.stringify({
                    "id_company": this.state.crrCompany ? this.state.crrCompany.value.toString() : '',
                    "start_date": this.state.startDate ? Moment(this.state.startDate).format("YYYY-MM-DD") : '',
                    "end_date": this.state.endDate ? Moment(this.state.endDate).format("YYYY-MM-DD") : '2999-12-31',
                    "search": this.state.search,
                    'index': this.state.activePage,
                    'item_per_page': this.itemsPerPage,
                })
            }).then((response) => {
                return response.json()
            }).then((dataRes) => {
                if (dataRes.status == 10000) {
                    this.setState({
                        dataAppointment: dataRes.data.schedules,
                        total_count: dataRes.data.count,
                        loading: false
                    })
                } else {
                    this.setState({
                        dataAppointment: [],
                        loading: false
                    })
                }
            }).catch((error) => {
                this.setState({
                    dataAppointment: [],
                    loading: false
                })
                console.log(error)
            })
        }
    }

    getListCamera = () => {
        const id_company = this.props.integrate ? this.props.id_company : this.state.crrCompany ? this.state.crrCompany.value : '';
        if (!id_company) return;
        fetch(`${HOST_EKYC}/api/config/${id_company}/get_by_company`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': store.getState().isLogin.access_token,
            },
        })
            .then(res => res.json())
            .then(data => {
                if (data.data) {
                    let cams = data.data.cameras.map(map => ({ label: map.alias_camera + ' (' + map.id_camera + ')', value: map.id_camera }))
                    this.setState({
                        listCamConfig: cams,
                    })
                }
            })

    }
    getListPerson = async () => {
        const id_company = this.props.integrate ? this.props.id_company : this.state.crrCompany ? this.state.crrCompany.value : '';
        if (!id_company) return;
        const dataReq = {
            name: '',
            address: '',
            phone_number: '',
            index: 0,
            item_per_page: 0,
            id_company: parseInt(id_company)
        }
        const url = `${HOST_EKYC}/api/customer/search`;
        const result = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': store.getState().isLogin.access_token
            },
            body: JSON.stringify(dataReq)
        });
        const response = await result.json();
        const dataRes = response.data.customers;
        var optionPerson = [{ 'value': -1, 'label': "Tạo mới" }]
        for (let i = 0; i < dataRes.length; i++) {
            const element = dataRes[i];
            let label = element.work_unit !== "" && element.id ? element.name +" - "+ element.id +" - "+ element.work_unit : element.id ? element.name +" - "+ element.id : element.name
            optionPerson.push({ 'value': element._id, 'label': label })
        }
        // let persons = dataRes.map(map => ({ label: map.id ? map.name +" - "+ map.id : map.name, value: map._id }));
        this.setState({
            listPerson: optionPerson
        })
    }

    onModalAddClose = () => {
        this.getListPerson();
    }



    FilterStaff = (activePage) => {
        const offset = (activePage - 1) * this.itemsPerPage;
        this.setState({
            offset: offset
        })
    }

    handlePageChange = (pageNumber) => {
        this.setState({
            ...this.state,
            activePage: pageNumber
        }, () => {
            this.getAppointmentSchedule()
        })
    }

    handleChangeStart = date => {
        this.setState({
            startDate: date ? date : new Date(),
        });
    };

    handleChangeEnd = date => {
        this.setState({
            endDate: date,
        });
    };

    handleClickSearch = () => {
        this.setState({
            loading: true
        }, () => {
            this.getAppointmentSchedule()
        })
    }

    reset = (e) => {
        this.setState({
            activePage: 1,
            dataHistory: [],
            data_json_export: [],
            loading: true,
            crrBox: all_camera,
        }, () => {
            this.getAppointmentSchedule()
            
        });
    }

    isChange = (event) => {
        if (event.key === 'Enter') {
            this.handleClickSearch()
        }
        this.setState({
            search: event.target.value.trim()
        });
    }

    getInfo = (value) => {
        var { dataInfo } = this.state;
        dataInfo.url_image_face = value.url_image_face;
        dataInfo.url_image_front = value.url_image_front;
        dataInfo.url_image_back = value.url_image_back;
        dataInfo.id = value.id;
        dataInfo.name = value.name;
        dataInfo.birthday = value.birthday;
        dataInfo.country = value.country;
        dataInfo.sex = value.sex;
        dataInfo.ethnicity = value.ethnicity;
        dataInfo.national = value.national;
        dataInfo.origin = value.origin;
        dataInfo.address = value.address;
        dataInfo.expiry = value.expiry;
        dataInfo.religion = value.religion;
        dataInfo.issue_date = value.issue_date;
        dataInfo.issue_by = value.issue_by;
        dataInfo.class = value.class;
        dataInfo.reason = value.reason;
        dataInfo.id_card = value.id_card;
        dataInfo.people = value.people;
        dataInfo.note = value.note;
        dataInfo.date = value.date;
        this.setState({
            ModalInfo: true,
            dataInfo
        });

    }

    handleDelete = (data) => {
        swal({
            title: 'Bạn có chắc',
            text: 'Bạn có chắc muốn xóa lịch hẹn',
            icon: "warning",
            buttons: {
                oke: 'Xóa',
                cancel: 'Đóng',
            },
        }).then(async (value) => {
            if (value === 'oke') {
                if (!this.state.crrCompany) return;
                fetch(`${HOST_EKYC}/api/appointment_schedule/insert_or_update`, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': store.getState().isLogin.access_token
                    },
                    body: JSON.stringify({
                        obj_id: data._id,
                        start_date: data.start_date,
                        start_time: data.start_time,
                        end_date: data.end_date,
                        end_time: data.end_time,
                        description: data.description,
                        id_company: data.id_company, 
                        ids_staff: data.ids_staff,
                        ids_camera: data.ids_camera,
                        is_active: 0
                    })
                }).then((response) => {
                    return response.json()
                }).then((dataRes) => {
                    if (dataRes.status == 10000) {
                        swal({
                            title: 'Thành công',
                            text: 'Xóa thành công',
                            buttons: false,
                            timer: 1500,
                            icon: 'success',
                        });
                        this.getAppointmentSchedule();
                    } else {
                        swal({
                            title: 'Lỗi',
                            text: 'Xóa không thành công',
                            buttons: false,
                            timer: 1500,
                            icon: 'error',
                        })
                    }
                }).catch((error) => {
                    console.log(error);
                    swal({
                        title: 'Lỗi',
                        text: 'Xóa không thành công',
                        buttons: false,
                        timer: 1500,
                        icon: 'error',
                    })
                })
            }
        })

    }

    handleEdit = (value,nameCustomer) => {
        this.setState({
            showModalEdit: true,
            showModalInfo: false,
            crrValue: {...value, nameCustomer: nameCustomer},
        })
    }

    handleEditInfo = (value) => {
        this.setState({
            showModalInfo: true,
            showModalEdit: false,
            crrValue: value,
        })
    }
    
    closeModalEdit = () => {
        this.setState({
            showModalEdit: false,
            showModalEditInfo: false,
            crrValue: null,
        })
    }


    onHideModalRegister = (refresh) => {
        this.setState({
            showModalRegister: false
        })
        if(refresh){
            this.getAppointmentSchedule();
        }
    }

    getBase64Axios(url, index) {
        var imageNode = document.getElementById("imageFace_"+index);
        return axios.get(url, {
            responseType: 'blob',
            headers: {
                'Authorization': store.getState().isLogin.access_token
            }
          }).then(response => {
            const buffer = URL.createObjectURL(response.data)
            imageNode.src = buffer
          }).catch(error => {
            console.log(error)
          })
    }


    render() {
        var pageRangeDisplayed = 1;
        if ($(window).width() < 768) {
            pageRangeDisplayed = 3
        } else {
            pageRangeDisplayed = 5
        }

        const dataRenderHistory = this.state.dataAppointment.map((value, index) => {
            const date = `${value.end_date} ${value.end_time}:00`;
            var nameCustomer = ""
            var imgCustomer = ""
            var nameNotPhone = ""
            if (value.customers !== undefined && value.customers.length > 0) {
                nameCustomer = value.customers.map(map => (`${map.name} ${map.id ? `(${map.id})` : ""} `)).join(', ');
                nameNotPhone = value.customers.map(map => (map.name)).join(', ');
                imgCustomer = value.customers.length > 0 ? value.customers[0].url_face : "";
            }
            const checkDate = Moment(date).format('YYYY-MM-DD HH:mm:ss') == 'Invalid Date' ? null : Moment(date).format('YYYY-MM-DD HH:mm:ss') < Moment(new Date()).format('YYYY-MM-DD HH:mm:ss') ? true : false;
            return (
                <tr key={index}>
                    <td>{(index + this.state.offset + 1)}</td>
                    <td><img  id={`imageFace_${index}`} className="img_face" src={(Object.keys(value.history).length !== 0 && value.history.url_face) ? this.getBase64Axios(`${HOST_EKYC}/` + value.history.url_face,index) : (Object.keys(value.history).length !== 0 && !value.history.url_face) ? './img/photo-placeholder.png' : (Object.keys(value.history).length === 0 && value.customers.length > 0 && imgCustomer !== "") ? this.getBase64Axios(`${HOST_EKYC}/` + imgCustomer, index)  : './img/photo-placeholder.png'} alt="" /></td>
                    <td style={{whiteSpace: "initial"}}>{Object.keys(value.history).length !== 0 ? `${value.history.name} ${value.history.id ? `(${value.history.id})` : ""} ` : nameCustomer}</td>
                    <td>{value.start_time}</td>
                    <td>{value.end_time}</td>
                    <td>{Moment(value.start_date).format("DD-MM-YYYY")}</td>
                    <td>{Moment(value.end_date).format("DD-MM-YYYY")}</td>
                    <td>{value.guardian}</td>
                    <td>{Object.keys(value.department).length !== 0  ? value.department.floor : ""}</td>
                    <td>{Object.keys(value.department).length !== 0  ? value.department.name : ""}</td>
                    <td>{value.description}</td>
                    {
                        checkDate ? <td className='text-danger'>Hết lịch hẹn</td> : <td className='text-success'>Còn lịch hẹn</td>
                    }
                    <td>
                   
                        <Tooltip title="Edit" placement="top" arrow>
                            <button
                                className="m-portlet__nav-link btn m-btn m-btn--hover-warning m-btn--icon m-btn--icon-only m-btn--pill"
                                onClick={() => this.handleEdit(value,nameNotPhone)}
                            >
                                <i className="la la-edit" />
                            </button>
                        </Tooltip>
                        <Tooltip title="Delete" placement="top" arrow>
                            <button
                                className="m-portlet__nav-link btn m-btn m-btn--hover-danger m-btn--icon m-btn--icon-only m-btn--pill"
                                onClick={() => this.handleDelete(value)}
                            >
                                <i className="la la-trash" />
                            </button>
                        </Tooltip>
                    </td>
                </tr>
            )
        })

        return (
            <div className="m-grid__item m-grid__item--fluid m-wrapper">
                <div className="m-content p-2">
                    <div className="m-portlet m-portlet--tab mb-0">
                        <div className="m-portlet__head p-3">
                            <div className="m-portlet__head-caption pl-3">
                                <div className="m-portlet__head-title">
                                    <h3 className="m-portlet__head-text">
                                        Quản lí lịch hẹn
                                    </h3>
                                </div>
                            </div>
                            <div className="m-portlet__head-tools pr-3">
                                    <button
                                    onClick={() => { this.setState({ showModalRegister: true }) }}
                                        className='btn btn-accent m-btn m-btn--custom m-btn--icon m-btn--pill m-btn--air'
                                    >

                                        <span>
                                            <i className='la la-plus' />
                                            <span>
                                                Thêm mới
                                            </span>
                                        </span>
                                    </button>
                            </div>
                        </div>
                        <div className="m-portlet__body m-portlet__body--no-padding">
                            <div className="row m-row--no-padding m-row--col-separator-xl">
                                <div className="col-xl-12">
                                    <div className="m-widget1">
                                        <div className='row m-0'>
                                            {/* <div className="col-md-2 pl-md-0">
                                                <Autocomplete
                                                    className='auto_select'
                                                    options={this.state.listBox}
                                                    getOptionLabel={option => option.title}
                                                    value={this.state.crrBox}
                                                    onChange={this.changeHandleBox}
                                                    disableClearable
                                                    renderInput={params => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            placeholder="Chọn thiết bị..."
                                                            fullWidth
                                                        />
                                                    )}
                                                />
                                            </div> */}
                                            <div className="col-md-2 pl-md-0">
                                                <DatePicker
                                                    className="form-control"
                                                    selected={this.state.startDate}
                                                    dateFormat="dd/MM/yyyy"
                                                    onChange={this.handleChangeStart}
                                                    placeholderText='Ngày bắt đầu...'
                                                    selectsStart
                                                    startDate={this.state.startDate}
                                                    endDate={this.state.endDate}
                                                />
                                            </div>
                                            <div className="col-md-2 pl-md-0">
                                                <DatePicker
                                                    className="form-control"
                                                    dateFormat="dd/MM/yyyy"
                                                    selected={this.state.endDate}
                                                    onChange={this.handleChangeEnd}
                                                    placeholderText='Ngày kết thúc...'
                                                    selectsEnd
                                                    startDate={this.state.startDate}
                                                    endDate={this.state.endDate}
                                                    minDate={this.state.startDate}
                                                />
                                            </div>
                                            <div className="col-lg-2 pl-0 m--margin-bottom-10-tablet-and-mobile">
                                                <input type="text" onKeyUp={(event) => this.isChange(event)} id="inputSearch" className="form-control m-input" placeholder="Họ Tên" />
                                            </div>
                                            <div className='col-md-2 pl-md-0 d-flex'>
                                                <button
                                                    onClick={(dataSearch) => {
                                                        this.handleClickSearch()

                                                    }}
                                                    className="btn btn-accent m-btn m-btn--icon mr-3" id="m_search">
                                                    <span>
                                                        <i className="la la-search" />
                                                        <span>Tìm kiếm</span>
                                                    </span>
                                                </button>
                                                <button
                                                    onClick={(dataSearch) => {
                                                        this.reset()

                                                    }}
                                                    className="btn btn-secondary m-btn m-btn--icon" id="m_reset">
                                                    <span>
                                                        <i className="la la-rotate-left" />
                                                        <span>Tải lại</span>
                                                    </span>
                                                </button>
                                            </div>
                                            
                                        </div>
                                        <div className="table-responsive text-nowrap dataTables_wrapper">
                                            <table className="table table-bordered table-hover dataTable mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>STT</th>
                                                        <th>Ảnh Mặt</th>
                                                        <th style={{width: "300px"}}>Họ Tên</th>
                                                        <th>Thời gian vào</th>
                                                        <th>Thời gian ra</th>
                                                        <th>Ngày bắt đầu</th>
                                                        <th>Ngày kết thúc</th>
                                                        <th>Người bảo lãnh</th>
                                                        <th>Tầng</th>
                                                        <th>Phòng ban</th>
                                                        <th>Nội dung làm việc</th>
                                                        <th>Trạng thái</th>
                                                        <th>Thao tác</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {dataRenderHistory}
                                                </tbody>
                                            </table>
                                            <div className="text-center">
                                                <PulseLoader
                                                    css={override}
                                                    sizeUnit={"px"}
                                                    size={12}
                                                    margin={'2px'}
                                                    color={'#36D7B7'}
                                                    loading={this.state.loading}
                                                />
                                            </div>
                                        </div>
                                        {
                                            this.state.showModalEdit &&
                                            <ModalEditAppointment 
                                            show={this.state.showModalEdit} 
                                            data={this.state.crrValue} 
                                            onHide={this.closeModalEdit} 
                                            onRefresh={this.getAppointmentSchedule} 
                                            listCam = {this.state.listCamConfig}
                                            creator={this.props.obj_id}
                                        />
                                        }
                                        {
                                            this.state.showModalInfo &&
                                            <ModalInfo
                                            show={this.state.showModalInfo} 
                                            data={this.state.crrValue} 
                                            onHide={this.closeModalEdit} 
                                            onRefresh={this.getAppointmentSchedule} 
                                            />
                                        }
                                        <Pagination
                                            prevPageText='Trang trước'
                                            nextPageText='Trang sau'
                                            firstPageText='Trang đầu'
                                            lastPageText='Trang cuối'
                                            activePage={this.state.activePage}
                                            itemsCountPerPage={this.itemsPerPage}
                                            totalItemsCount={this.state.total_count}
                                            pageRangeDisplayed={pageRangeDisplayed}
                                            onChange={this.handlePageChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.state.showModalRegister && <ModalRegister 
                    show={this.state.showModalRegister} 
                    appointment={this.state.appointment} 
                    integrate={this.props.integrate}
                    objID={this.props.obj_id}
                    onHide={this.onHideModalRegister} 
                    listCam={this.state.listCamConfig} 
                    listPerson={this.state.listPerson}
                    onModalAddClose={this.onModalAddClose}
                    idCompany={this.props.integrate ? this.props.id_company : this.state.crrCompany ? this.state.crrCompany.value : ''}
                    name_staff={this.props.integrate ? this.props.name_staff : ''}
                    />
                }
            </div>
        );
    }
}


export default withRouter(HistoryAppointment);