import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import swal from 'sweetalert';
import Select from "react-select";
import Store from '../../store';
import $ from 'jquery';
import { HOST_EKYC } from '../../config';

class ModalDepartment extends Component {

    constructor(props) {
        super(props);
        this.state = {
            crrDepartment: {
                name: "",
                floor: "",
                obj_id: ""
            },
            crrCompany: Store.getState().company.crr_company ? Store.getState().company.crr_company : null,
        }
        Store.subscribe(() => {
            this.setState({
                crrCompany: Store.getState().company.crr_company,
            })
        });
    }

    updateDepartment = async (dataDepartment, event) => {
        var form = $("#formAddDepartment")
        if (form[0].checkValidity() === false) {
            event.preventDefault()
            event.stopPropagation()
            form.addClass('was-validated')
        } else {
            fetch(`${HOST_EKYC}/api/department/insert_or_update`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': Store.getState().isLogin.access_token
                },
                body: JSON.stringify({
                    "obj_id": dataDepartment.obj_id ? dataDepartment.obj_id : "",
                    "name": dataDepartment.name,
                    "status": 1,
                    "floor": dataDepartment.floor,
                    "id_company": this.state.crrCompany ? this.state.crrCompany.value.toString() : '',
                })
            }).then((response) => {
                return response.json()
            }).then((dataRes) => {
                if (dataRes.status === 10000) {
                    if (dataDepartment.obj_id) {
                        swal("Thành công", "Sửa thành công", "success", {
                            buttons: false,
                            timer: 1500,
                        });
                    } else {
                        swal("Thành công", "Tạo mới thành công", "success", {
                            buttons: false,
                            timer: 1500,
                        });
                    }
                    return this.props.onHide();
                } else {
                    if (dataDepartment.obj_id) {
                        swal("Thất bại", "Sửa thất bại", "error", {
                            buttons: false,
                            timer: 1500,
                        });
                    } else {
                        swal("Thất bại", "Thêm mới thất bại", "error", {
                            buttons: false,
                            timer: 1500,
                        });
                    }
                }
            }).catch((error) => {
                this.setState({
                    listDepartment: [],
                    total_count: 0,
                    loading: false
                })
                console.log(error)
            })
        }
    }


    departmentHandle(e) {
        var dataDepartment = this.state.crrDepartment;
        dataDepartment[e.target.name] = e.target.value.replace(/</g, "").replace(/>/g, "");
        this.setState({ crrDepartment: dataDepartment });
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.show === true) {
            console.log(nextProps)
            this.setState({
                crrDepartment: nextProps.data,
            });
        }
    }


    render() {
        let click_handle = (event) => {
            this.updateDepartment(this.state.crrDepartment, event);
        }

        return (
            <Modal
                {...this.props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form id="formAddDepartment">
                        <div className="col-xl-12">
                            <div className="m-widget14">
                                <div className="form-group m-form__group">
                                    <label htmlFor="Name">Tầng <span className="text-danger"> *</span></label>
                                    <input className="form-control m-input"
                                        name='floor'
                                        value={this.state.crrDepartment.floor === '' ? '' : this.state.crrDepartment.floor}
                                        onChange={e => this.departmentHandle(e)}
                                        placeholder="Tên"
                                        required />
                                </div>
                                <div className="form-group m-form__group">
                                    <label htmlFor="Name">Phòng ban <span className="text-danger"> *</span></label>
                                    <input className="form-control m-input"
                                        name='name'
                                        value={this.state.crrDepartment.name === '' ? '' : this.state.crrDepartment.name}
                                        onChange={e => this.departmentHandle(e)}
                                        placeholder="Tên"
                                        required />
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="accent" id="button_addDepartment" className="m-loader--light m-loader--right" onClick={click_handle}>Lưu</Button>
                    <Button variant="secondary" onClick={() => {
                        this.props.onHide();
                        this.setState({
                        })
                    }}>Đóng</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}


export default ModalDepartment;