import { SET_COMPANY } from '../../actions/company';

const initialState = {
    list_company: [],
    crr_company: null,
};
export default function company(state = initialState, action) {
    switch(action.type){
        case SET_COMPANY:
            return{
                ...state,
                list_company: action.payload.list_company ? action.payload.list_company : state.list_company,
                crr_company: action.payload.crr_company ? action.payload.crr_company : state.crr_company,
            };
        default:
            return state;
    }
}
