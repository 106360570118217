import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import $ from 'jquery';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { setListCompany } from '../../actions/company';
import { HOST_CLOUD, LOGO, ENGINE_NAME } from '../../config';
import store from '../../store';
import {role} from '../../actions/role'

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLogin: true,
            access_token: null,
            user: null,
            listCompany: [],
            crrcompany: { title: '' },
        }
    }


    componentWillMount() {
        var access_token = localStorage.getItem('access_token')
        this.setState({
            access_token: access_token
        });
    }


    componentDidMount() {
        this.getUser();
    }


    getUser = () => {
        fetch(`${HOST_CLOUD}/api/user/getbytoken`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.state.access_token
            }
        }).then((response) => {
            if (response.status === 401) {
                this.setState({
                    isLogin: false,
                })
                // return null;
            }
            return response.json();
        }).then((result) => {
            if (result.status === 10000) {
                this.setState({
                    user: result.data
                }, () => {
                    this.getCompany();
                    store.dispatch(role(result.data.rule));
                })
            }
            else {
                this.setState({
                    isLogin: false,
                })
            }
        }).catch((error) => {
            if (error) {
                this.setState({
                    isLogin: false,
                })
            }
        });
    }
    getCompany = () => {
        fetch(`${HOST_CLOUD}/api/company/getbyuser1`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.state.access_token
            }
        }).then((response) => {
            return response.json()
        }).then(async (data) => {
            if (data.status === 10000) {
                try {
                    for (let index = 0; index < data.companies.length; index++) {
                        data.companies[index].listBoxDelete = []
                        for (let i = 0; i < data.companies[index].boxes_engine.length; i++) {
                            if (ENGINE_NAME.split(',').every(every => every != data.companies[index].boxes_engine[i].name_engine)) {
                                data.companies[index].listBoxDelete.push(data.companies[index].boxes_engine[i])
                                data.companies[index].boxes_engine.splice(i, 1);
                                i--;
                            }else {
                                data.companies[index].boxes_engine[i].title = `BITABLE_${data.companies[index].boxes_engine[i].id_box_engine}`
                            }
                        }
                    }
                    this.setState({
                        listCompany: data.companies,
                        crrcompany: { title: data.companies[0].name, value: data.companies[0].id_company, dataBox: data.companies[0].boxes_engine , dataBox2: data.companies[0].listBoxDelete},
                    }, () => {  
                        store.dispatch(setListCompany(this.state.listCompany, this.state.crrcompany));
                    })
                } catch (error) {
                    console.log(error);
                }
            }
        })}

    changeHandleCompany = (e, value) => {
        if(localStorage.getItem('crrBox')){
            localStorage.setItem('crrBox', null);
        }
        if(localStorage.getItem('infoTable')){
            localStorage.removeItem('infoTable');
        }
        if(localStorage.getItem('crrIP')){
            localStorage.setItem('crrIP', null);
        }
        if(localStorage.getItem('list_IP')){
            localStorage.setItem('list_IP', null);
        }
        this.setState({
            crrcompany: value,
        }, () => {
            store.dispatch(setListCompany('', this.state.crrcompany))
        })
    }
    onClickOpen = () => {
        $('#m_aside_left').addClass('m-aside-left--on')
        $('#body_form').addClass('m-aside-left--on')
        $('#m_aside_left_toggle').addClass('m-aside-left-toggler--active')
        $('#root').append(
            $('<div/>', {
                'class': 'm-aside-left-overlay',
            }).on({
                'click': function () {
                    $('#m_aside_left').removeClass('m-aside-left--on')
                    $('#body_form').removeClass('m-aside-left--on')
                    $('#m_aside_left_toggle').removeClass('m-aside-left-toggler--active')
                    $('.m-aside-left-overlay').remove()
                }
            })
        )
    }



    render() {
        if (this.state.isLogin === false && !this.props.integrate) {
            
            return (
                <Redirect to={'/login'} />
            )
        }
        var options = [];
        for (var i = 0; i < this.state.listCompany.length; i++) {
            options.push(
                { title: this.state.listCompany[i].name, value: this.state.listCompany[i].id_company, dataBox: this.state.listCompany[i].boxes_engine ,dataBox2: this.state.listCompany[i].listBoxDelete })
        }
        return (
            <>
                {
                    this.props.integrate ? <></>
                        :
                        <header id="m_header" className="m-grid__item m-header" m-minimize="minimize" m-minimize-mobile="minimize" m-minimize-offset={200} m-minimize-mobile-offset={200}>
                            <div className="m-container m-container--fluid m-container--full-height">
                                <div className="m-stack m-stack--ver m-stack--desktop  m-header__wrapper">
                                    <div className="m-stack__item m-brand m-brand--mobile">
                                        <div className="m-stack m-stack--ver m-stack--general">
                                            <div className="m-stack__item m-stack__item--middle m-brand__logo">
                                                <a href="/" className="m-brand__logo-wrapper">
                                                    <img alt="" src={LOGO} className="logo_img" />

                                                </a>
                                            </div>
                                            <div className="m-stack__item m-stack__item--middle m-brand__tools">
                                                {/* BEGIN: Responsive Aside Left Menu Toggler */}
                                                <a href="#/" id="m_aside_left_toggle_mobile" className="m-brand__icon m-brand__toggler m-brand__toggler--left">
                                                    <span />
                                                </a>
                                                {/* END */}
                                                {/* BEGIN: Topbar Toggler */}
                                                <a id="m_aside_header_topbar_mobile_toggle" href="#/" className="m-brand__icon">
                                                    <i className="flaticon-more" />
                                                </a>
                                                {/* BEGIN: Topbar Toggler */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="m-stack__item m-stack__item--middle m-stack__item--left m-header-head" id="m_header_nav">
                                        <div className="m-stack m-stack--ver m-stack--desktop">
                                            <div className="m-stack__item m-stack__item--middle m-stack__item--fit">
                                                <a className="m-aside-left-toggler m-aside-left-toggler--left m_aside_left_toggler" onClick={() => this.onClickOpen()}>
                                                    <span />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="m-stack__item m-stack__item--middle m-stack__item--center" style={{ 'width': '160px' }}>
                                        <a href="/" className="m-brand m-brand--desktop">
                                            <img alt="" src={LOGO} className="logo_img" />
                                        </a>
                                    </div>
                                    <div className="m-stack__item m-stack__item--right">
                                        <div id="m_header_topbar" className="m-topbar  m-stack m-stack--ver m-stack--general width-100">
                                            <div className="m-stack__item m-topbar__nav-wrapper">

                                                <ul className="m-topbar__nav m-nav m-nav--inline width-100 ml-0">
                                                    <li className="m-nav__item m-topbar__user-profile m-topbar__user-profile--img">
                                                        <div className="m-nav__link m-dropdown__toggle">
                                                            <div className="row">
                                                                {/* <div className="m-topbar__welcome" style={{ 'lineHeight': '50px','color':'#b1b1b5' }}>Company: </div> */}
                                                                <div className="m-topbar__welcome mr-4 select_company">
                                                                    <Autocomplete
                                                                        options={options}
                                                                        getOptionLabel={option => option.title}
                                                                        value={this.state.crrcompany}
                                                                        onChange={this.changeHandleCompany}
                                                                        disableClearable
                                                                        style={{ width: 160 }}
                                                                        renderInput={params => (
                                                                            <TextField
                                                                                {...params}
                                                                                variant="outlined"
                                                                                placeholder="Company"
                                                                                fullWidth
                                                                            />
                                                                        )}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="m-nav__item m-dropdown m-dropdown--medium m-dropdown--arrow  m-dropdown--align-right m-dropdown--mobile-full-width m-dropdown--skin-light icon_logout" m-dropdown-toggle="click">
                                                        <a href="#" className="m-nav__link m-dropdown__toggle">
                                                            <span className="m-topbar__userpic">
                                                                <img src="/img/photo-placeholder.png" className="m--img-rounded m--marginless m--img-centered" alt="" />
                                                            </span>
                                                            <span className="m-nav__link-icon m-topbar__usericon  m--hide">
                                                                <span className="m-nav__link-icon-wrapper"><i className="flaticon-user-ok" /></span>
                                                            </span>
                                                        </a>
                                                        <div className="m-dropdown__wrapper">
                                                            <span className="m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust" />
                                                            <div className="m-dropdown__inner">
                                                                <div className="m-dropdown__header m--align-center">
                                                                    <div className="m-card-user m-card-user--skin-light">
                                                                        <div className="m-card-user__pic">
                                                                            <img src="/img/photo-placeholder.png" className="m--img-rounded m--marginless" alt="" />
                                                                        </div>
                                                                        <div class="m-card-user__details">
                                                                            <span class="m-card-user__name m--font-weight-500">{this.state.user !== null && this.state.user.first_name + ' ' + this.state.user.last_name}</span>
                                                                            <a href="#/" class="m-card-user__email m--font-weight-300 m-link">{this.state.user !== null && this.state.user.email}</a></div>
                                                                    </div>
                                                                </div>
                                                                <div className="m-dropdown__body">
                                                                    <div className="m-dropdown__content">
                                                                        <ul className="m-nav m-nav--skin-light">
                                                                            <li className="m-nav__separator m-nav__separator--fit">
                                                                            </li>
                                                                            <li className="m-nav__item">
                                                                                <a className="btn m-btn--pill btn-secondary m-btn m-btn--custom m-btn--label-brand m-btn--bolder"
                                                                                    onClick={() => {
                                                                                        localStorage.removeItem("access_token");
                                                                                        window.location.href = "/login";
                                                                                    }}
                                                                                >Đăng Xuất</a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </header>
                }
            </>
        );
    }
}

export default Header;