import { css } from '@emotion/core';
import React, { Component } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import FadeLoader from 'react-spinners/FadeLoader';
import toastr from 'reactjs-toastr';
import { HOST_EKYC, HOST_BITABLE_CONNECT, HOST_VERIFI, TYPE_OCR } from '../../config'
import 'toasted-notes/src/styles.css';
import swal from 'sweetalert';
import { ProgressBar } from 'react-bootstrap';
import { PulseLoader } from 'react-spinners';
import $ from 'jquery';
import Moment from 'moment';
import openSocket from 'socket.io-client';
import { da } from 'date-fns/locale';
const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

var setTimeoutGetFace;
var setTimeoutCMTFront;
var setTimeoutCMTBack;
var IntervalStream;
var dataDocumentDefault = {
    name: '',
    base64: '',
}
var dataSaveDefault = {

    image_document: '',
    file_name: ''
}
class BiTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingImage1: false,
            disabled_bitable: false,
            disabled_api_url: false,
            loadingBtn: false,
            dataBitable: localStorage.getItem("infoTable") !== null ? JSON.parse(localStorage.getItem("infoTable")) : { name: '', url: '' },
            api_url_send_data: localStorage.getItem("infoApiUrl"),
            dataDocument: {
                ...dataDocumentDefault
            },
            dataSave: {
                ...dataSaveDefault
            },
        }
        this.socket_cmt = null;
        this.Stream();
    }


    componentWillMount() {
        var { disabled_bitable, disabled_api_url } = this.state
        if (localStorage.getItem("infoTable") !== null) {
            if (JSON.parse(localStorage.getItem("infoTable")).name !== "") {
                disabled_bitable = true
            } else {
                disabled_bitable = false
            }
            if (localStorage.getItem("infoApiUrl") !== null && localStorage.getItem("infoApiUrl") !== '') {
                disabled_api_url = true
            } else {
                disabled_api_url = false
            }
            this.setState({
                disabled_bitable,
                disabled_api_url,
            });
        }

    }

    checkSocket = (socket, type) => {
        if (this.state.dataBitable.stream_socket == undefined || this.state.dataBitable.stream_socket == '') return;
        console.log('update socket', this.state.dataBitable.stream_socket)
        if (socket !== undefined && socket !== null) {
            socket.disconnect();
            if (type == 'cmt') {
                this.setState({
                    // cameraFace: msg.image,
                    cameraCMT: ""
                });
            }
            console.log('Socket Reva Disconnect');
        }

        socket = openSocket(`${this.state.dataBitable.stream_socket}`);
        if (type == 'cmt') {
            this.socket_cmt = socket
        }
        // socket = openSocket(`http://192.168.3.249:3000`)
        socket.on('connection');
        socket.emit('joined', type + '_stream')
        socket.on('image', async (msg) => {
            if (type == 'cmt') {
                this.setState({
                    // cameraFace: msg.image,
                    cameraCMT: msg.image
                });
            }

        });
        socket.on('disconnect', () => {
            console.log('Socket Reva Disconnect');
        });
    };


    toDataURL = (url, callback) => {
        var xhr = new XMLHttpRequest();
        xhr.onload = function () {
            var reader = new FileReader();
            reader.onloadend = function () {
                callback(reader.result);
            }
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    }

    onFilesChange1 = (files) => {
        this.setState({
            loadingImage1: true,
            dataDocument: {
                ...dataDocumentDefault
            },
        }, async () => {
            var { dataBitable } = this.state;
            var checktimeout = false
            if (dataBitable.name !== "") {
                if (dataBitable.url != "") {
                    setTimeoutCMTFront = setTimeout(() => {
                        toastr.error("Đọc dữ liệu thất bại", 'Title', { displayDuration: 3000 })
                        this.setState({
                            dataDocument: {
                                ...dataDocumentDefault
                            },
                            loadingImage1: false
                        });
                        checktimeout = true
                    }, 30000);
                    var image = await fetch(`http://${dataBitable.url}:8096/bitable?option=11&timeout=4`, {
                        method: 'GET',
                    }).then((response) => {
                        return response.json()
                    }).then((data) => {
                        clearTimeout(setTimeoutCMTFront)
                        var { dataDocument, dataSave } = this.state;
                        dataDocument.base64 = data.data[0].images[0].base64
                        dataSave.image_document = data.data[0].images[0].base64
                        this.setState({
                            dataDocument,
                        });
                        return data;
                    }).catch((error) => {
                        if (error) {
                            clearTimeout(setTimeoutCMTFront)
                            if (checktimeout === false) {
                                this.setState({
                                    dataDocument: {
                                        ...dataDocumentDefault
                                    },
                                    loadingImage1: false
                                });
                                toastr.error("Đọc dữ liệu thất bại, Thiết bị Bitable chưa được kết nối!", 'Thất bại!', { displayDuration: 3000 })
                            }
                        }
                    });
                    if (checktimeout === false) {
                        this.setState({

                            loadingImage1: false
                        });
                    }
                    //     if (image !== undefined) {
                    //         if (image.code == "401") {
                    //             this.setState({
                    //                 dataDocument: {
                    //                     ...dataDocumentDefault
                    //                 },
                    //                 percent: 0,
                    //                 loadingImage1: false
                    //             });
                    //             toastr.error(image.error, 'Title', { displayDuration: 3000 })
                    //         } else {
                    //             // fetch(`${HOST_EKYC}/api/ekyc/recog`, {
                    //             fetch(`${HOST_VERIFI}/v3.1/ocr/recognition`, {
                    //                 method: 'POST',
                    //                 headers: {
                    //                     'key': 'qV3weotvEwlJ1G5cErvsUgxco6m1LMYX',
                    //                     'Content-Type': 'application/json'
                    //                 },
                    //                 body: JSON.stringify({
                    //                     'image': image.data[0].images[0].base64,
                    //                     "customer_id": `${TYPE_OCR}`,
                    //                     "type_id": "",
                    //                     "pre": "0"
                    //                 })
                    //             }).then((response) => {
                    //                 return response.json()
                    //             }).then((data) => {
                    //                 if (data.result_code === 200) {

                    //                 } else {
                    //                     toastr.error("Đọc dữ liệu thất bại", 'Title', { displayDuration: 3000 })
                    //                     this.setState({
                    //                         // dataFront: {
                    //                         //     ...dataFrontDefault
                    //                         // },
                    //                         loadingImage1: false
                    //                     });
                    //                 }
                    //             }).catch((error) => {
                    //                 if (error) {
                    //                     toastr.error("Đọc dữ liệu thất bại", 'Title', { displayDuration: 3000 })
                    //                     this.setState({
                    //                         dataDocument: {
                    //                             ...dataDocumentDefault
                    //                         },
                    //                         loadingImage1: false
                    //                     });
                    //                 }
                    //             })
                    //         }
                    //     } else {
                    //         toastr.error("Đọc dữ liệu thất bại", 'Title', { displayDuration: 3000 })
                    //         this.setState({
                    //             dataDocument: {
                    //                 ...dataDocumentDefault
                    //             },
                    //             loadingImage1: false
                    //         });
                    //     }
                    // }
                } else {
                    this.setState({
                        dataDocument: {
                            ...dataDocumentDefault
                        },
                        loadingImage1: false
                    }, () => {
                        swal({
                            title: "Đọc dữ liệu thất bại, vui lòng kiểm tra lại!",
                            icon: "error",
                            buttons: {
                                confirm: {
                                    text: 'Đóng',
                                    value: null,
                                    visible: true,
                                    closeModal: true,
                                },
                            },
                            dangerMode: false,
                        })
                    });
                }
            } else {
                this.setState({
                    dataDocument: {
                        ...dataDocumentDefault
                    },
                    loadingImage1: false
                }, () => {
                    swal({
                        title: "Bạn chưa cập nhập thiết bị!",
                        icon: "error",
                        buttons: {
                            confirm: {
                                text: 'Đóng',
                                value: null,
                                visible: true,
                                closeModal: true,
                            },
                        },
                        dangerMode: false,
                    })
                });
            }
        });
    }


    Stream = () => {
        var { dataBitable } = this.state;
        this.checkSocket(this.socket_cmt, 'cmt');

    }

    UpdateBiTable = () => {

        let dataBitable = this.state.dataBitable;
        if (dataBitable.name === "" && dataBitable.url === "") {
            swal({
                title: "Bạn chưa nhập tên thiết bị hoặc URL!",
                icon: "warning",
                buttons: {
                    confirm: {
                        text: 'Đóng',
                        value: null,
                        visible: true,
                        closeModal: true,
                    },
                },
                dangerMode: false,
            })
            return
        }
        if (dataBitable.name === "" && dataBitable.url !== "") {
            swal({
                title: "Bạn chưa nhập tên thiết bị!",
                icon: "warning",
                buttons: {
                    confirm: {
                        text: 'Đóng',
                        value: null,
                        visible: true,
                        closeModal: true,
                    },
                },
                dangerMode: false,
            })
            return
        }
        if (dataBitable.name !== "") {
            fetch(`${HOST_BITABLE_CONNECT}/get-ip-box?id=${dataBitable.name}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            }).then((response) => {
                return response.json()
            }).then((data) => {
                if (data.url !== undefined) {
                    var dataUrlSplit1 = data.url.split('//');
                    var dataUrlSplit2 = dataUrlSplit1[1].split(':');
                    dataBitable.url = dataUrlSplit2[0];
                    dataBitable.type_connect = data.type_connect;
                    dataBitable.lan = data.lan_url;
                    dataBitable.wifi = data.wifi_url;
                    dataBitable.stream_socket = data.stream_socket;
                    this.setState({ dataBitable }, () => {
                        this.Stream();
                        localStorage.setItem("infoTable", JSON.stringify(this.state.dataBitable));
                        this.setState({
                            disabled_bitable: true
                        });
                        swal({
                            title: "Thành công",
                            text: "Cập nhật thành công!",
                            icon: "success",
                            button: false,
                            timer: 2000,
                        })
                    })
                } else {
                    dataBitable.url = '';
                    dataBitable.type_connect = '';
                    dataBitable.lan = '';
                    dataBitable.wifi = '';
                    dataBitable.stream_socket = ''
                    this.setState({
                        disabled_bitable: true,
                        dataBitable
                    }, () => {
                        swal({
                            title: "Cập nhật thất bại, Thiết bị Bitable chưa được kết nối!",
                            icon: "error",
                            buttons: {
                                confirm: {
                                    text: 'Đóng',
                                    value: null,
                                    visible: true,
                                    closeModal: true,
                                },
                            },
                            dangerMode: false,
                        })
                    });
                }
            }).catch((error) => {
                if (error) {
                    dataBitable.url = '';
                    dataBitable.type_connect = '';
                    dataBitable.lan = '';
                    dataBitable.wifi = '';
                    this.setState({
                        disabled_bitable: true,
                        dataBitable
                    }, () => {
                        swal({
                            title: "Cập nhật thất bại, Thiết bị Bitable chưa được kết nối!",
                            icon: "error",
                            buttons: {
                                confirm: {
                                    text: 'Đóng',
                                    value: null,
                                    visible: true,
                                    closeModal: true,
                                },
                            },
                            dangerMode: false,
                        })
                    });
                }
            })
        } else {

            this.setState({
                disabled_bitable: true
            }, () => {
                swal({
                    title: "Thành công",
                    text: "Cập nhập URL thành công",
                    icon: "success",
                    button: false,
                    timer: 2000,
                })
            });
        }
    }


    onClickClose = () => {
        this.setState({
        });
    }
    base64toBlob = (base64Data, contentType) => {
        contentType = contentType || '';
        var sliceSize = 1024;
        var byteCharacters = atob(base64Data);
        var bytesLength = byteCharacters.length;
        var slicesCount = Math.ceil(bytesLength / sliceSize);
        var byteArrays = new Array(slicesCount);

        for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
            var begin = sliceIndex * sliceSize;
            var end = Math.min(begin + sliceSize, bytesLength);

            var bytes = new Array(end - begin);
            for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
                bytes[i] = byteCharacters[offset].charCodeAt(0);
            }
            byteArrays[sliceIndex] = new Uint8Array(bytes);
        }
        return new Blob(byteArrays, { type: contentType });
    }


    save_document = () => {
        var dateTime=Moment(new Date()).format('DD/MM/YYYY HH:mm:ss')
        dateTime=dateTime.replace(/[/: ]/g,"")
        console.log("aa",dateTime)
        if (this.state.dataDocument.base64 === "") {
            swal('Cảnh báo!', "Bạn chưa lấy ảnh tài liệu", "warning");
            this.setState({
                loadingBtn: false
            });
            return
        }
        swal({
            title: "Bạn có chắc chắn muốn lưu dữ liệu",
            icon: "warning",
            buttons: true,
            dangerMode: false,
        })
            .then(name => {
                if (!name) throw null;
                this.setState({
                    loadingBtn: true
                });
                var base64String = this.state.dataDocument.base64
                if (this.state.dataDocument.name === ""){
                    var filename = dateTime+".png"
                }
                else{
                    var filename = this.state.dataDocument.name+"_"+dateTime+".png"
                }
               
                var blob = this.base64toBlob(base64String, "image/png")
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement("a");
                a.href = url;
                a.download = filename;
                a.click();
                this.setState({
                    loadingBtn: false
                }, () => {
                    this.onClickClose()
                });
                swal({
                    title: "Thành công",
                    text: "Cập nhật thành công!",
                    icon: "success",
                    button: false,
                    timer: 2000,
                })

                
            }).catch((error) => {
                if (error) {
                    this.setState({
                        loadingBtn: false
                    }, () => {
                        swal({
                            title: "Thành công",
                            text: "Cập nhật thành công!",
                            icon: "success",
                            button: false,
                            timer: 2000,
                        })
                    });
                    
                } else {
                    swal.stopLoading();
                    swal.close();
                    this.setState({
                        loadingBtn: false
                    });
                }
            });

    }


    onChangeBiTable = (value) => {
        var dataBitable = this.state.dataBitable;
        dataBitable[value.target.name] = value.target.value;
        this.setState({
            dataBitable: dataBitable
        });
    }

    onChangeApiURL = (value) => {
        this.setState({
            api_url_send_data: value.target.value
        });
    }
    onChangeBiTableUrl = (value) => {
        var dataBitable = this.state.dataBitable;
        dataBitable[value.target.name] = value.target.value;
        dataBitable.stream_socket=value.target.value +':3000';
        this.Stream();
        this.setState({
            
            dataBitable: dataBitable
        });
    }

    onChangeFileName = (value) => {
        var { dataSave, dataDocument } = this.state;
        dataDocument[value.target.name] = value.target.value;
        dataSave[value.target.name] = value.target.value;
        this.setState({
            dataDocument,
            dataSave
        });
    }


    CloseBiTable = () => {
        this.setState({
            disabled_bitable: false,
        });
    }

    UpdateApiURL = () => {
        var api_url_send_data = this.state.api_url_send_data
        localStorage.setItem("infoApiUrl", api_url_send_data);
        this.setState({
            disabled_api_url: true,
        });
    }
    CloseApiURL = () => {
        this.setState({
            disabled_api_url: false,
        });
    }

    render() {
        return (
            <div className="col-lg-12 pt-3">
                <div className="m-portlet" id="form_reva">
                    {/*begin::Form*/}
                    <div className="m-portlet__head row">
                        <div className="m-portlet__head-caption col-md-6">
                            <div className="col-md-12 row text-center m-0 pl-4">
                                <input type="text" className="form-control m-input col-md-4 mr-3" placeholder="Tên thiết bị BiTable" name="name" value={this.state.dataBitable.name} onChange={(value) => this.onChangeBiTable(value)} disabled={this.state.disabled_bitable} />

                                <input type="text" className="form-control m-input col-md-4 mr-3" placeholder="IP Address" name="url" value={this.state.dataBitable.url} onChange={(value) => this.onChangeBiTableUrl(value)} disabled={this.state.disabled_bitable} />
                                {
                                    this.state.disabled_bitable !== false
                                        ?
                                        <button className="btn btn-warning" onClick={this.CloseBiTable}>Sửa</button>
                                        :
                                        <button className="btn btn-success mr-3" onClick={this.UpdateBiTable}>Cập nhật</button>
                                }
                            </div>
                        </div>
                        <div className="m-portlet__head-tools col-md-6">
                            <div className="form-group m-form__group ml-auto col-md-5 pr-3 mb-0">
                                <div className="input-group">
                                    <input type="text" className="form-control m-input" placeholder="Nhập API Url http://...." name="api_url" value={this.state.api_url_send_data} onChange={(value) => this.onChangeApiURL(value)} disabled={this.state.disabled_api_url} />
                                    <div className="input-group-append">
                                        {
                                            this.state.disabled_api_url !== false
                                                ?
                                                <button className="btn btn-warning m-0" onClick={this.CloseApiURL}>Sửa</button>
                                                :
                                                <button className="btn btn-success m-0" onClick={this.UpdateApiURL}>Cập nhật URL</button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="m-form m-form--fit m-form--label-align-right m-form--group-seperator-dashed">
                        <div className="m-portlet__body p-5">
                            <div className="row">
                                <div className="col-md-8">
                                    <div className="row">
                                        <div className='col-lg-4 col-md-4 py-3'>
                                            <img
                                                src={
                                                    this.state.cameraCMT
                                                        ? 'data:image/jpeg;base64,' + this.state.cameraCMT
                                                        : './img/camera-placeholder.png'
                                                }
                                                alt=''
                                                style={{
                                                    width: '100%',
                                                    maxHeight: '500px',
                                                    objectFit: 'cover',
                                                }}
                                            />
                                        </div>
                                        <div className="col-lg-8 col-md-8 py-3">
                                            <LoadingOverlay
                                                active={this.state.loadingImage1}
                                                spinner={<FadeLoader
                                                    css={override}
                                                    sizeUnit={"px"}
                                                    size={150}
                                                    color={'rgb(54, 215, 183)'}
                                                    loading={true}
                                                />}
                                                styles={{
                                                    overlay: (base) => ({
                                                        ...base,
                                                        background: 'rgba(17, 17, 17, 0.24)'
                                                    })
                                                }}
                                            >
                                                <a href='#' onClick={this.onFilesChange1}>
                                                    <img alt="img" id="previewImage1" src={this.state.dataDocument.base64 == "" ? "./img/anhscan.png" : 'data:image/jpeg;base64,' + this.state.dataDocument.base64} style={{ "width": "100%", "height": "500px" }} />
                                                </a>
                                            </LoadingOverlay>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className='row'>
                                        <div className="col-md-12">
                                            <div className="form-group m-form__group p-0">
                                                <h3>Thông tin tài liệu</h3>
                                                <div className="mt-4 row">
                                                    <div className="col-lg-12 mb-3">
                                                        <label>
                                                            Tên tài liệu:
                                                        </label>
                                                        <input type="text" className="form-control m-input" name="name" value={this.state.dataDocument.name} onChange={(value) => this.onChangeFileName(value)} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 text-center p-3">
                                            <button className={"btn btn-success m-btn--icon mr-2 m-loader--light m-loader--right " + (this.state.loadingBtn === true && 'm-loader')} disabled={this.state.loadingBtn} onClick={this.save_document}>Lưu dữ liệu</button>
                                            <button className={"btn btn-danger m-btn--icon mr-2 m-loader--light m-loader--right "} onClick={this.onClickClose}>Hủy</button>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                        {/*end::Form*/}
                    </div>
                </div>
            </div>
        );
    }

}

export default BiTable;
