import React, { useState, useEffect } from 'react';
import Select from "react-select";
import Moment from 'moment';
import DatePicker from "react-datepicker";
import swal from 'sweetalert';
import swals from '@sweetalert/with-react';
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import { HOST_EKYC } from '../../config/index'
import { KeyboardTimePicker } from '@material-ui/pickers'
import { useSelector } from 'react-redux';
import DOMPurify from 'dompurify';

function ModalEditAppointment(props) {


    const { show, onHide, data, onRefresh, listCam, creator } = props;

    const [crrData, setCrrData] = useState(null);

    const initial = {
        ids_camera: [],
        start_time: Moment(new Date()).format("HH:mm"),
        end_time: Moment(new Date()).format("HH:mm"),
        start_date: Moment(new Date()).format("YYYY-MM-DD"),
        end_date: Moment(new Date()).format("YYYY-MM-DD"),
        description: "",
        obj_department: "",
        guardian: ""
    }
    const token = useSelector(state => state.isLogin.access_token);
    const [optionsCams, setOptionsCams] = useState([])
    const [crrRegister, setCrrRegister] = useState(initial)
    const [valueCam, setValueCam] = useState([])
    const [valueDepartment, setValueDepartment] = useState(null)
    const [listDepartment, setListDepartment] = useState([])
    const [valueTime, setValueTime] = useState({
        start_time: new Date(),
        end_time: "",
    })



    const getDeparment = (id_company) => {
        fetch(`${HOST_EKYC}/api/department/get_by_company/${id_company}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token
            },
        })
            .then(response => {
                return response.json()
            }).then(data => {
                if (data.status == 10000) {
                    var dataOption = []
                    for (let i = 0; i < data.data.length; i++) {
                        dataOption.push({value: data.data[i]._id, label: "Tầng " + data.data[i].floor + " - " + "Phòng ban " + data.data[i].name});
                    }
                    setListDepartment(dataOption)
                }
            }).catch((error) => {
                console.log(error)
            });
    }





    const handleChangeHourStartTime = startTime => {
        const start_time = Moment(startTime).format("HH:mm");
            setValueTime({ ...valueTime, start_time: startTime })
            setCrrData({ ...crrData, start_time })
    }


    const handleChangeHourEndTime = endTime => {
        var DoorOpenHand = crrData;
        if (Moment(endTime).format("HH:mm") > DoorOpenHand['start_time']) {
            setValueTime({ ...valueTime, end_time: endTime })
            const end_time = Moment(endTime).format("HH:mm");
            setCrrData({ ...crrData, end_time })
        } else {
            swal("Cảnh báo", "Thời gian vào phải nhỏ hơn thời gian ra", "warning");
        }
    }

    const handleChangeDayStartTime = startDay => {
        const start_date = Moment(startDay).format("YYYY-MM-DD");
        const end_date = Moment(startDay).format("YYYY-MM-DD");

        setCrrData({
            ...crrData,
            start_date,
            end_date,
        })

    }

    const handleChangeDayEndTime = endDay => {
        const end_date = Moment(endDay).format("YYYY-MM-DD");
        setCrrData({ ...crrData, end_date })
    }

    const handleChangeCams = (value) => {
        let ids_camera = [];
        if (value) {
            const is_all = value.some(some => some.value === -1);
            if (is_all) {
                setValueCam([optionsCams[0]]);
                ids_camera = optionsCams.map(map => map.value).filter(filter => filter !== -1);
                setCrrData({ ...crrData, ids_camera })
                return;
            }
            ids_camera = value.map(map => map.value);
            setValueCam(value)
            setCrrData({ ...crrData, ids_camera })
            return;
        }
        setValueCam(value)
        setCrrData({ ...crrData, ids_camera })
    }

    const handleAllCams = () => {
        const ids_camera = optionsCams.map(map => map.value);

        setCrrData({ ...crrData, ids_camera })
        setValueCam(optionsCams);
    }


    const onChangeReson = (e) => {
        crrData[e.target.name] = e.target.value.replace(/</g, "").replace(/>/g, "");
        setCrrData({ ...crrData })
    }


    useEffect(() => {
        setOptionsCams(listCam)
        let valueCams = []
        if (data.ids_camera.length === listCam.length - 1 && data.ids_camera.length > 1) {
            valueCams.push({ label: 'Tất cả cam', value: -1 })
        } else {
            for (let i = 0; i < data.ids_camera.length; i++) {
                for (let j = 0; j < listCam.length; j++) {
                    if (data.ids_camera[i] === listCam[j].value) {
                        valueCams.push({ 'value': data.ids_camera[i], 'label': listCam[j].label })
                    }
                }
            }
        }
        if (Object.keys(data.department).length !== 0) {
         setValueDepartment({ value: data.department._id, label: "Tầng " + data.department.floor + " - " + "Phòng ban " + data.department.name})
        }
        setValueCam(valueCams)
        const endTimeSplit = data.end_time.split(':')
        const startTimeSplit = data.start_time.split(':')
        setValueTime({ ...valueTime, end_time: new Date().setHours(endTimeSplit[0], endTimeSplit[1]), start_time: new Date().setHours(startTimeSplit[0], startTimeSplit[1]) })
    }, [show])

    const handleChangeDepartment = (value) => {
        setValueDepartment(value)
        setCrrData({ ...crrData, obj_department: value.value })
    }


    useEffect(() => {
        setCrrData(data);
        getDeparment(data.id_company)

        return () => {
            setCrrData(null);
        }
    }, [data]);

    console.log(crrData)

    if (!crrData) return <></>;

    const handleEditSchedule = () => {
        if (crrData.start_time > crrData.end_time) {
            swals("Cảnh báo", "Thời gian ra phải lớn hơn thời gian vào", "warning");
        } else {
        fetch(`${HOST_EKYC}/api/appointment_schedule/insert_or_update`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token
            },
            body: JSON.stringify({
                creator: creator ? creator : '',
                obj_id: crrData._id,
                start_date: crrData.start_date,
                start_time: crrData.start_time,
                end_date: crrData.end_date,
                end_time: crrData.end_time,
                description: DOMPurify.sanitize(crrData.description),
                id_company: crrData.id_company,
                ids_staff: crrData.ids_staff,
                ids_camera: crrData.ids_camera,
                obj_department: valueDepartment ? valueDepartment.value : "",
                guardian: DOMPurify.sanitize(crrData.guardian),
                obj_id_history: Object.keys(crrData.history).length !== 0 ? crrData.history._id : '',
            })
        }).then((response) => {
            return response.json()
        }).then((dataRes) => {
            if (dataRes.status == 10000) {
                swal({
                    title: 'Thành công',
                    text: 'Cập nhật thành công',
                    buttons: false,
                    timer: 1500,
                    icon: 'success',
                });
                onRefresh();
                onHide()
            } else {
                swal({
                    title: 'Lỗi',
                    text: 'Cập nhật không thành công',
                    buttons: false,
                    timer: 1500,
                    icon: 'error',
                })
            }
        }).catch((error) => {
            swal({
                title: 'Lỗi',
                text: 'Cập nhật không thành công',
                buttons: false,
                timer: 1500,
                icon: 'error',
            })
        })
    }
    }

    return (
        <Modal
            show={show}
            onHide={onHide}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Sửa lịch hẹn
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="col-lg-12 mb-3">
                    <div className="row pl-2 pb-4">
                        <label className="m-0 pl-3">Khách hàng: <b>{Object.keys(crrData.history).length !== 0 ? crrData.history.name : crrData.nameCustomer }</b></label>
                    </div>
                    <div className="col-xl-12  p-0">
                        <div className="row pt-0 m-2">

                            <div className="form-group m-form__group col-md-12 p-0 mb-3">
                                <label>Biface</label>
                                <div className='d-flex align-items-center'>
                                    <Select
                                        className="select w-100 mr-2"
                                        getOptionValue={(option) => option.label}
                                        onChange={value => handleChangeCams(value)}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        options={optionsCams}
                                        value={valueCam}
                                        placeholder="Biface"
                                    />
                                    <button className="btn btn-success m-btn--icon mr-2 m-loader--light m-loader--right" onClick={handleAllCams}>
                                        Tất cả
                                    </button>
                                </div>
                                <div className="col-md-12 mb-3 p-0">
                                        <label>Người bảo lãnh:</label>
                                        <textarea rows="1" type="text" className="form-control m-input" name="guardian" value={crrData.guardian} onChange={(e) => onChangeReson(e)} />
                                </div>
                                <div className="form-group m-form__group col-md-12 p-0 mt-3">
                                        <label>Tầng - Phòng ban</label>
                                        <div className='d-flex align-items-center'>
                                            <Select
                                            className="select w-100 mr-2"
                                            getOptionValue={(option) => option.label}
                                            onChange={value => handleChangeDepartment(value)}
                                            options={listDepartment}
                                            value={valueDepartment}
                                            placeholder="Tầng - phòng ban"
                                        />
                                        </div>
                                    </div>
                            </div>

                            <div className="form-group m-form__group col-md-6 pl-0">
                                <label>Thời gian vào</label>
                                <div>
                                    <KeyboardTimePicker
                                        placeholder='HH:mm'
                                        mask='__:__'
                                        ampm={false}
                                        style={{ width: "100%" }}
                                        value={valueTime.start_time}
                                        onChange={handleChangeHourStartTime}
                                        invalidDateMessage="Định dạng thời gian sai"
                                    />
                                </div>
                            </div>
                            <div className="form-group m-form__group col-md-6 pr-0">
                                <label>Thời gian ra</label>
                                <div>
                                    <KeyboardTimePicker
                                        placeholder='HH:mm'
                                        mask='__:__'
                                        ampm={false}
                                        style={{ width: "100%" }}
                                        value={valueTime.end_time}
                                        onChange={handleChangeHourEndTime}
                                        invalidDateMessage="Định dạng thời gian sai"
                                    />
                                </div>
                            </div>
                            <div className="form-group m-form__group col-md-12 p-0">
                                <label>Ngày hẹn</label>
                                <div>
                                    <DatePicker
                                        className="form-control m-input m_datetimepicker_6"
                                        selected={moment(crrData.start_date).toDate()}
                                        // minDate={moment(crrData.start_date).toDate()}
                                        onChange={handleChangeDayStartTime}
                                        dateFormat="dd-MM-yyyy"
                                        placeholderText="Start Date"
                                    />
                                </div>
                            </div>
                            {/* <div className="form-group m-form__group col-md-6 pr-0">
                                <label>Ngày kết thúc</label>
                                <div>
                                    <DatePicker
                                        className="form-control m-input m_datetimepicker_6"
                                        selected={moment(crrData.end_date).toDate()}
                                        onChange={handleChangeDayEndTime}
                                        // minDate={subDays(new Date(crrRegister.start_date),0)}
                                        dateFormat="dd-MM-yyyy"
                                        placeholderText="End Date"
                                    />
                                </div>
                            </div> */}
                         
                            <div className="col-md-12 mb-3 p-0">
                                <label>Nội dung làm việc</label>
                                <textarea type="text" className="form-control m-input" name="description" value={crrData.description} onChange={(e) => onChangeReson(e)} />
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className={"btn btn-success m-btn--icon mr-2 m-loader--light m-loader--right "} onClick={() => handleEditSchedule()} >Hoàn tất</button>
                <button className={"btn btn-danger m-btn--icon mr-2 m-loader--light m-loader--right "} onClick={onHide}>Hủy</button>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalEditAppointment;
