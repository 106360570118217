import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import swal from 'sweetalert';
import $ from 'jquery';
import QueueAnim from 'rc-queue-anim';
import { HOST_CLOUD,LOGO } from '../config'
import Store from '../store';
import { login } from '../actions/isLogin';

const status = localStorage.getItem('status')
const infoTable = localStorage.getItem('infoTable')
const crrBox = localStorage.getItem('crrBox')
const crrIP = localStorage.getItem('crrIP')
class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: 0,
            loadingbtn: false,
            datalogin: {
                email: "",
                password: "",
            }
        }

        localStorage.clear();
        localStorage.setItem('status',status);
        localStorage.setItem('infoTable',infoTable);
        localStorage.setItem('crrBox',crrBox);
        localStorage.setItem('crrIP',crrIP);
    }

    CheckLogin = async (event) => {
        var form = $("#formLoginCheck")
        if (form[0].checkValidity() === false) {
            event.preventDefault()
            event.stopPropagation()
            form.addClass('was-validated')
        } else {
            event.preventDefault()
            event.stopPropagation()
            this.setState({
                loadingbtn: true
            }, () => {
                if (this.state.datalogin.password.length < 6) {
                    this.setState({
                        error: 1,
                        loadingbtn: false
                    })
                } else {
                    fetch(`${HOST_CLOUD}/api/login`, {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(this.state.datalogin)
                    }).then((response) => {
                        if (response.status === 500) {
                            this.setState({ 
                                error: 0,
                                loadingbtn: false 
                            })
                            swal("Cảnh báo!", "Hiện tại hệ thống của chúng tôi đang nâng cấp, vui lòng đăng nhập lại sau 30 phút", "info");
                            return;
                        }
                        return response.json()
                    }).then((data) => {
                        if (data.status === 10000) {
                            this.setState({
                                 error: 0,
                                 loadingbtn: false
                             })
                            localStorage.setItem("access_token", "Bearer " + data.access_token);
                            Store.dispatch(login("Bearer " + data.access_token));
                            localStorage.setItem("api_url", data.api_url);
                            localStorage.setItem("api2_url", data.api2_url);
                            window.location.href = "/bitable";
                            // return;
                        } else if (data.status === 10003) {
                            this.setState({
                                loadingbtn: false
                            })
                            swal("Cảnh báo!", "Tài khoản không tồn tại", "warning");
                        } else if (data.status === 10005) {
                            this.setState({
                                loadingbtn: false
                            })
                            swal("Cảnh báo", "Tài khoản của bạn đã bị khóa, vui lòng chờ 30 phút rồi đăng nhập lại hoặc liên hệ admin", "warning");
                        } else {
                            this.setState({ 
                                error: 1, 
                                loadingbtn: false
                            })
                        }
                    }).catch((error) => {
                        if (error) {
                            this.setState({
                                loadingbtn: false
                            })
                            swal("Lỗi!", "Vui lòng kiểm tra lại đường truyền", "error");
                        }
                    })
                }
            })
            
        }
    }

    onEnterPress = (e) => {
        if (e.keyCode === 13 && e.shiftKey === false) {
            if (e.target.name === 'email') {
                this.refs.password.focus();
            }
            else {
                e.preventDefault();
                this.CheckLogin(e);
            }
        }
    }

    HandleLogin = (e) => {
        var datalogin = this.state.datalogin;
        datalogin[e.target.name] = e.target.value.trim();
        this.setState({ datalogin: datalogin });
    }

    render() {
        return (
            <section className="login-block" style={{ 'width': '100%', 'height': '100vh' }}>
                <div className="container col-md-6 col-lg-5 col-xl-3">
                    <Row>
                        <Col md={4} className="login-sec col-md-12">

                            <form id="formLoginCheck">
                                <QueueAnim duration={1000} type="scale">
                                    <div className="text-center pb-5" key="1">
                                        <a className="m-brand__logo-wrapper">
                                            <img alt="" src={LOGO} style={{ 'width': '200px' }} />
                                        </a>
                                    </div>
                                </QueueAnim>
                                <div className="login-form" id="formLogin">
                                    <label id="label_check" className={"form-check-label pb-2" + (this.state.error === 1 ? "" : " d-none")}>
                                        <div className="text-danger">* Sai email hoặc mật khẩu</div>
                                    </label>
                                    < div className="form-group">
                                        <label htmlFor="exampleInputEmail1" className="text-uppercase">Email</label>
                                        <input type="text" name="email" id="exampleInputEmail1" className="form-control" value={this.state.datalogin.email} placeholder="Nhập email..." required onKeyDown={this.onEnterPress} onChange={(e) => {
                                            this.HandleLogin(e)
                                        }} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputPassword1" className="text-uppercase">Mật Khẩu</label>
                                        <input type="password" ref='password' name="password" value={this.state.datalogin.password} className="form-control" placeholder="Nhập mật khẩu..." required onKeyDown={this.onEnterPress} onChange={(e) => {
                                            this.HandleLogin(e)
                                        }} />
                                    </div>
                                    <div className="form-check pl-0 text-center pb-5 pt-4">
                                        <button className={"btn btn-login m-loader--light m-loader--right " + (this.state.loadingbtn === true && ' m-loader')} disabled={this.state.loadingbtn} id="btnLogin" style={{ 'width': '150px' }} onClick={(event) => {
                                            this.CheckLogin(event);
                                        }}>Đăng Nhập</button>
                                    </div>
                                </div>
                            </form>

                        </Col>
                    </Row>
                </div>
            </section >
        )
    }
}
export default Login;
