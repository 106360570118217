import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'react-bootstrap';
import ReactToPrint from 'react-to-print';
import QRCode from 'qrcode.react';

class ComponentToPrint extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { url } = this.props;
        return (
            <div style={{ padding: '50px 50px 0' }}>
                {
                    url ?
                        <div>

                            <p className='text-center' style={{ fontSize: 50, fontFamily: 'auto', lineHeight: 1.5 }}>QUÉT VÀO HÌNH BÊN DƯỚI ĐỂ KHAI BÁO Y TẾ</p>
                            <div className='mt-4 text-center'>
                                <QRCode value={url} size={450} level={'M'} />
                            </div>
                            <div style={{ fontSize: 16, padding: '15px 0' }}>Đường link: {url}</div>
                        </div>
                        :
                        <p className='text-center' style={{ fontSize: 50, fontFamily: 'auto', lineHeight: 1.5, padding: '50px 0 100px' }}>Vui lòng nhập đường link!</p>
                }
            </div>
        );
    }
}

export class ModalQR extends React.Component {
    render() {
        const { show, onHide, url } = this.props;

        return (
            <>
                <Modal
                    size="lg"
                    show={show}
                    onHide={onHide}
                    aria-labelledby="example-modal-sizes-title-lg"
                    backdrop="static"
                    className="modal_qr"
                >
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body style={{ padding: 0 }}>
                        <ComponentToPrint ref={el => (this.componentRef = el)} url={url} />
                    </Modal.Body>
                    <Modal.Footer>
                        {
                            url && <ReactToPrint
                                trigger={() => <button type="button" className="btn btn-accent">Print QRCode</button>}
                                content={() => this.componentRef}
                            />
                    }
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

ModalQR.propTypes = {
    show: PropTypes.bool,
    onHide: PropTypes.func,
    url: PropTypes.string,
};


export default ModalQR;