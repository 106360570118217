import React, { Component } from 'react';
import swal from 'sweetalert';
import Switch from "react-switch";
import { Row, Col, Image } from 'react-bootstrap';
import { PulseLoader } from 'react-spinners';
import $ from 'jquery';
import Select from "react-select";
import store from '../../store';
import { HOST_EKYC } from '../../config';
import ModalQR from './ModalQR';
const nameTab = 'config'

const host = window.location.origin;
class Setting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            optionsCams: [{ 'value': '1', 'label': 'Cam 1' }, { 'value': '2', 'label': 'Cam 2' }],
            valueCams: [],
            id_cameras: [],
            crrCompany: store.getState().company.crr_company ? store.getState().company.crr_company : null,
            listCam: store.getState().company.crr_company ? store.getState().company.crr_company.dataBox2 : [],
            id_conifg: "",
            url: `${host}/tokhaiyte`,
            showModal: false,
        };
        store.subscribe(() => {
            this.setState({
                crrCompany: store.getState().company.crr_company,
            }, () => {
                this.setState({
                    listCam: store.getState().company.crr_company ? store.getState().company.crr_company.dataBox2 : [],
                });
                this.getData()
            })
        })
    }

    handleChangeCams = (value) => {
        this.setState({ 
            valueCams: value === null ? [] : value 
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.listCam.length !== this.state.listCam.length) {
            var optionCams = []
            for (let i = 0; i < this.state.listCam.length; i++) {
                optionCams.push({"value": this.state.listCam[i].id_box_engine, "label": `${this.state.listCam[i].alias_box_engine ? this.state.listCam[i].alias_box_engine + "": ""} (${this.state.listCam[i].id_box_engine})` , "alias": this.state.listCam[i].alias_box_engine ? this.state.listCam[i].alias_box_engine : ""})
            }
            this.setState({optionCams});
        }
    }

    componentDidMount() {
        this.getData()
        var optionCams = []
            for (let i = 0; i < this.state.listCam.length; i++) {
                optionCams.push({"value": this.state.listCam[i].id_box_engine, "label": `${this.state.listCam[i].alias_box_engine ? this.state.listCam[i].alias_box_engine + "": ""} (${this.state.listCam[i].id_box_engine})` , "alias": this.state.listCam[i].alias_box_engine ? this.state.listCam[i].alias_box_engine : ""})
            }
            this.setState({optionCams});
    }   

    

    getData = () => {
        if(!this.state.crrCompany) return;
        var idCompany = this.state.crrCompany ? this.state.crrCompany.value : ''
        fetch(`${HOST_EKYC}/api/config/${idCompany}/get_by_company`,{
            method:"GET",
            headers: {
                "Content-Type": "application/json",
                'Authorization': store.getState().isLogin.access_token
            },
        }).then(response =>{
            return response.json();
        }).then(data => {
            if (data.status === 10000) {
                var crrCam = []
                if (data.data !== null) {
                    if (data.data.cameras.length > 0) {
                        for (let i = 0; i < data.data.cameras.length; i++) {
                            crrCam.push({"value": data.data.cameras[i].id_camera, "label": `${data.data.cameras[i].alias_camera} (${data.data.cameras[i].id_camera})`, "alias": data.data.cameras[i].alias_camera})
                        }
                    }
                    this.setState({
                        valueCams: crrCam,
                        id_config: data.data._id
                    });
                }
            }
        })
    }
    
    onSave = () => {
        var cameras = [] 
        for (let i = 0; i < this.state.valueCams.length; i++) {
            cameras.push({"id_camera": this.state.valueCams[i].value, "alias_camera": this.state.valueCams[i].alias})
        }

        fetch(`${HOST_EKYC}/api/config/insert_or_update`,{
            method:"POST",
            headers: {
                "Content-Type": "application/json",
                'Authorization': store.getState().isLogin.access_token
            },
            body: JSON.stringify({
                cameras: cameras,
                web_hook: "",
                id_company: this.state.crrCompany ? this.state.crrCompany.value.toString() : '',
                id_config: this.state.id_config
            })
        }).then(response =>{
            return response.json();
        }).then(res => {
            if (res.status === 10000) {
                swal('Thành công!', "Lưu thành công", "success");
            } else {
                swal('Thất bại!', "Lưu thất bại", "error");
            }
        })
    }
    


    render() {

        return (
            <>
                <div className="m-grid__item m-grid__item--fluid m-wrapper p-2">
                    <div className="m-content">
                        {/*begin::Portlet*/}
                        <div className="m-portlet m-portlet--space">
                            <div className="m-portlet__head">
                                <div className="m-portlet__head-caption">
                                    <div className="m-portlet__head-title">
                                        <h3 className="m-portlet__head-text">
                                            Cài đặt
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            <div className="m-portlet__body">
                                <div className="row d-flex align-items-center mb-5">
                                    <div className="col-md-3 col-xl-2">Camera: </div>
                                    <div className="col-md-5 col-xl-5">
                                        <Select
                                            className="select"
                                            getOptionValue={(option) => option.label}
                                            onChange={value => this.handleChangeCams(value)}
                                            isMulti
                                            closeMenuOnSelect={false}
                                            options={this.state.optionCams}
                                            value={this.state.valueCams}
                                            placeholder="Camera"
                                        />
                                    </div>
                                    <div className="col">
                                        <button className="btn btn-success m-btn--icon mr-2 m-loader--light m-loader--right " onClick={this.onSave}>Xác nhận</button>
                                    </div>
                                </div>
                                <div className="row d-flex align-items-center mb-5">
                                    <div className="col-md-3 col-xl-2">Đường link trang nhập tờ khai y tế: </div>
                                    <div className="input-group col-md-5 col-xl-5">
                                        <input onChange={e => this.setState({ url: e.target.value.replace(/</g, "").replace(/>/g, "") })} className='form-control m-input' value={this.state.url} type="text" name="url" placeholder="Đường link..." aria-describedby="url" />
                                    </div>
                                    <div className="col">
                                        <button className="btn btn-success m-btn--icon mr-2 m-loader--light m-loader--right " onClick={() => this.setState({ showModal: true })}>Xem QR Code</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <ModalQR show={this.state.showModal} onHide={() => this.setState({ showModal: false })} url={this.state.url} />
            </>
        );
    }
}

export default Setting;