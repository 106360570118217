import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import Select, { components } from "react-select";
import { PulseLoader } from 'react-spinners';
import { css } from '@emotion/core';
import { HOST_EKYC } from '../../config';
import { useSelector } from 'react-redux';
import swal from 'sweetalert';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

ModalAdd.propTypes = {

};

function ModalAdd(props) {
    const { show, onHide, onSubmitAdd, idCompany } = props;
    const [dataPost, setDataPost] = useState({
        id: '',
        name: '',
        phone_number: '',
        work_unit: '',
    })
    const [isValidName, setIsValidName] = useState(false);
    const [isValidId, setIsValidId] = useState(false);
    const token = useSelector(state => state.isLogin.access_token);

    const onChange = (e) => {
        setDataPost({
            ...dataPost,
            [e.target.name]: e.target.value.replace(/</g, "").replace(/>/g, ""),
        })
    }

    const onSubmit = () => {
        if (dataPost.name === '') {
            setIsValidName(true);
        }
        if (dataPost.id === '') {
            setIsValidId(true);
        }
        if (dataPost.name !== '' && dataPost.id !== '') {
            createPerson(dataPost)
        }
    }

    const createPerson = (data) => {
        fetch(`${HOST_EKYC}/api/customer/insert_or_update`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token
            },
            body: JSON.stringify({...data,id_company: idCompany})
        })
            .then(response => {
                return response.json()
            }).then(data => {
                if (data.status == 10000) {
                    onSubmitAdd(dataPost,data.data);
                    // onHide(true);
                } else {
                    swal({
                        icon: 'error',
                        title: 'Thất bại',
                        text: 'Tạo mới nhân viên thất bại!',
                        timer: 1500,
                        buttons: false,
                    })
                }
            }).catch((error) => {
                console.log(error)
                swal({
                    icon: 'error',
                    title: 'Thất bại',
                    text: 'Tạo mới nhân viên thất bại!',
                    timer: 1500,
                    buttons: false,
                })
            });
    }

    return (
        <Modal
            show={show}
            onHide={onHide}
            backdrop="static"
            dialogClassName="modal-size-5"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Tạo khách hàng mới
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="col-lg-12 mb-3">
                        <div className="row col-md-12 p-0 m-0">
                            <div className="col-md-6 mb-3">
                                <label>Số định danh <span className="text-danger">(*)</span></label>
                                <input type="text" className={`form-control m-input  ${isValidId && dataPost.id === "" ? "border_red" : ""}`} name="id"
                                value={dataPost.id} 
                                onChange={(e) => onChange(e)} 
                                required
                                />
                            </div>
                            <div className="col-md-6 mb-3">
                                <label>Họ tên <span className="text-danger">(*)</span></label>
                                <input type="text" className={`form-control m-input  ${isValidName && dataPost.name === "" ? "border_red" : ""}`} name="name"
                                value={dataPost.name} 
                                onChange={(e) => onChange(e)} 
                                required
                                />
                            </div>
                        </div>
                        <div className="row col-md-12 p-0 m-0">
                            <div className="col-md-6 mb-3">
                                <label>Số điện thoại</label>
                                <input type="text" className="form-control m-input" name="phone_number"
                                value={dataPost.phone_number} 
                                onChange={(e) => onChange(e)} 
                                />
                            </div>
                            <div className="col-md-6 mb-3">
                                <label>Đơn vị công tác</label>
                                <input type="text" className="form-control m-input" name="work_unit"
                                value={dataPost.work_unit} 
                                onChange={(e) => onChange(e)} 
                                />
                            </div>
                        </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className={"btn btn-success m-btn--icon mr-2 m-loader--light m-loader--right "}
                onClick={() => onSubmit()}
                >Tiếp tục</button>
                <button className={"btn btn-danger m-btn--icon mr-2 m-loader--light m-loader--right "}
                    onClick={onHide}
                >Hủy</button>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalAdd;