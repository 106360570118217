import {ROLE} from '../../actions/role';

const initialState = {
    role:  null
};
export default function role(state = initialState, action) {
    switch(action.type){
        case ROLE:
            return{
                role: action.payload.role
            };
        default:
            return state;
    }
}
