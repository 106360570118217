export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export function login(access_token){
    return {
        type: LOGIN,
        payload:{
            access_token: access_token
        }
    }
}
export function logout(){
    return {
        type: LOGOUT
    }
}
