import {LOGIN, LOGOUT } from '../../actions/isLogin';

let access_token = localStorage.getItem('access_token');
const initialState = {
    isLogin: access_token === null ? false : true ,
    access_token:  access_token
};
export default function isLogin(state = initialState, action) {
    switch(action.type){
        case LOGIN:
            return{
                isLogin: true,
                access_token: action.payload.access_token
            };
        case LOGOUT:
            return{
                isLogin: false,
                access_token: null
            };
        default:
            return state;
    }
}
