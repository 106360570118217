var _HOST_EKYC = ""
var _HOST_BITABLE_CONNECT = ""
var _HOST_VERIFI = ""
var _HOST_CLOUD = ""
var _ENGINE_NAME = ''
var _LOGO = "/img/BI_Logo.png"
var _TYPE_CUSTOMER = ''
var _HIDE_REGISTER_CUSTOMERS = ''
var _HIDE_SCAN_DOCUMENT = ''



_HOST_EKYC = process.env.REACT_APP_HOST_EKYC
_HOST_BITABLE_CONNECT = process.env.REACT_APP_HOST_BITABLE_CONNECT
_HOST_VERIFI = process.env.REACT_APP_HOST_VERIFI
_HOST_CLOUD = process.env.REACT_APP_HOST_CLOUD
_ENGINE_NAME = process.env.REACT_APP_BOX_ENGINE_NAME
_TYPE_CUSTOMER = process.env.REACT_APP_TYPE_CUSTOMERS.split(',')
_HIDE_REGISTER_CUSTOMERS = process.env.REACT_APP_HIDE_REGISTER_CUSTOMERS
_HIDE_SCAN_DOCUMENT = process.env.REACT_APP_HIDE_SCAN_DOCUMENT

export const HOST_EKYC = _HOST_EKYC
export const HOST_BITABLE_CONNECT = _HOST_BITABLE_CONNECT
export const HOST_VERIFI = _HOST_VERIFI
export const HOST_CLOUD = _HOST_CLOUD
export const LOGO = _LOGO
export const ENGINE_NAME = _ENGINE_NAME
export const TYPE_CUSTOMER = _TYPE_CUSTOMER
export const HIDE_REGISTER_CUSTOMERS = _HIDE_REGISTER_CUSTOMERS
export const HIDE_SCAN_DOCUMENT = _HIDE_SCAN_DOCUMENT


