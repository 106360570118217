import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import swal from 'sweetalert';
import { HOST_EKYC } from '../../config/index'
import { useSelector } from 'react-redux';
import $ from 'jquery';

ModalFormEdit.propTypes = {
    show: PropTypes.bool,
    data: PropTypes.object,
    onHide: PropTypes.func,
    onRefresh: PropTypes.func,
};

ModalFormEdit.defaultProps = {
    show: false,
    data: null,
    onHide: null,
    onRefresh: null,
}

function ModalFormEdit(props) {
    const { show, onHide, data, onRefresh, idCompany } = props;
    const [crrData, setCrrData] = useState(null);

    const [isValidName, setIsValidName] = useState(false);
    const [isValidId, setIsValidId] = useState(false);
    const token = useSelector(state => state.isLogin.access_token);

    const onChange = (e) => {
        setCrrData({
            ...crrData,
            [e.target.name]: e.target.value.replace(/</g, "").replace(/>/g, ""),
        })
    }

    const handleUpdateHistory = (event) => {
        var form = $("#formAdd")
        if (form[0].checkValidity() === false) {
            event.preventDefault()
            event.stopPropagation()
            form.addClass('was-validated')
        } else {
            let dataPost = {
                id: crrData.id,
                name: crrData.name,
                phone_number: crrData.phone_number,
                work_unit: crrData.work_unit,
                id_company: idCompany,
                obj_id: crrData._id,
            }
            fetch(`${HOST_EKYC}/api/customer/insert_or_update`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
                body: JSON.stringify(dataPost)
            }).then((response) => {
                return response.json()
            }).then((dataRes) => {
                if (dataRes.status === 10000) {
                    swal({
                        title: 'Thành công',
                        text: crrData._id !== "" ? 'Cập nhật thành công' : "Thêm mới thành công",
                        buttons: false,
                        timer: 1500,
                        icon: 'success',
                    });
                    onRefresh();
                    onHide()
                } else {
                    swal({
                        title: 'Lỗi',
                        text: 'Cập nhật không thành công',
                        buttons: false,
                        timer: 1500,
                        icon: 'error',
                    })
                }
            }).catch((error) => {
                swal({
                    title: 'Lỗi',
                    text: 'Cập nhật không thành công',
                    buttons: false,
                    timer: 1500,
                    icon: 'error',
                })
            })
        }
    }

    useEffect(() => {
        setCrrData(data);
        return () => {
            setCrrData(null);
        }
    }, [data]);
    console.log(crrData)
    if (!crrData) return <></>;
    return (
        <Modal show={show} onHide={onHide} size='lg'>
            <div className='row-sm row'>
                <div className='col-md-12'>
                    <div className='m-portlet m-portlet--tab'>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {crrData._id === "" ? "Thêm mới khách hàng" : "Sửa khách hàng"}
                            </Modal.Title>
                        </Modal.Header>
                        {
                            crrData &&
                            <>
                                <Modal.Body>
                                    <form id="formAdd">
                                        <div className="col-lg-12 mb-3">
                                            <div className="row col-md-12 p-0 m-0">
                                                <div className="col-md-6 mb-3">
                                                    <label>Số định danh <span className="text-danger">(*)</span></label>
                                                    <input type="text" className={`form-control m-input`} name="id"
                                                        value={crrData.id}
                                                        onChange={(e) => onChange(e)}
                                                        required
                                                    />
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <label>Họ tên <span className="text-danger">(*)</span></label>
                                                    <input type="text" className={`form-control m-input`} name="name"
                                                        value={crrData.name}
                                                        onChange={(e) => onChange(e)}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="row col-md-12 p-0 m-0">
                                                <div className="col-md-6 mb-3">
                                                    <label>Số điện thoại</label>
                                                    <input type="text" className="form-control m-input" name="phone_number"
                                                        value={crrData.phone_number}
                                                        onChange={(e) => onChange(e)}
                                                    />
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <label>Đơn vị công tác</label>
                                                    <input type="text" className="form-control m-input" name="work_unit"
                                                        value={crrData.work_unit}
                                                        onChange={(e) => onChange(e)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button onClick={handleUpdateHistory} variant='accent' className='m-loader--light m-loader--right'>Lưu</Button>
                                    <Button onClick={onHide} variant='secondary'>Đóng</Button>
                                </Modal.Footer>
                            </>
                        }
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default ModalFormEdit;