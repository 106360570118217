import React from 'react';
import PropTypes from 'prop-types';

SuccessDeclaration.propTypes = {
    
};

function SuccessDeclaration(props) {
    return (
            <div style={{ display: "flex", minHeight: '100vh', flexDirection: 'column', alignItems: "center", justifyContent: 'center'}}>
                <span className='text-success'><i class="far fa-check-circle" style={{ fontSize: 150 }}></i></span>
                <div style={{ fontSize: 20, marginTop: 20 }}>Khai báo y tế thành công</div>
                <div style={{ fontSize: 20, marginTop: 20 }}>Cảm ơn bạn đã tham gia khai báo y tế.</div>
                <div style={{ fontSize: 20, marginTop: 20 }}>Chúc bạn một ngày tốt lành!</div>
            </div>
    );
}

export default SuccessDeclaration;