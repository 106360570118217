import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import HistoryAppointment from '../HistoryAppointment';
import Header from '../layouts/Header';

History.propTypes = {
    
};

function History(props) {
    
    const [idCompany, setIDCompany] = useState('');
    const [objID, setObjID] = useState('');
    const [nameStaff, setNameStaff] = useState('');

    const location = useLocation();

    useEffect(() => {
        if (location.search) {
            let path = location.search.replace('?', '').split('&');
            let obj = {}
            path.forEach(value => {
                let arr = value.split('=');
                obj[arr[0]] = arr[1]
            })
            setNameStaff(decodeURIComponent(obj.name_staff))
            setIDCompany(obj.id_company);
            setObjID(obj.obj_id);
        }
    }, [location])

    return (
        <>
            <Header integrate={true}/>
            {
                objID && <HistoryAppointment integrate={true} id_company={idCompany} obj_id={objID} name_staff={nameStaff}/>
            }
        </>
    );
}

export default History;