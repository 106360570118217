import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import swal from 'sweetalert';
import { HOST_EKYC } from '../../config';
import Moment from 'moment';
import store from '../../store';

var dataInfoDefault = {
    address: "",
    country: "",
    date: '',
    ethnicity: "",
    expiry: "",
    id_type: "",
    issue_by: "",
    issue_date: "",
    national: "",
    religion: "",
    time: '',
    id: '',
    name: '',
    birthday: '',
    sex: '',
    reason: '',
    origin: '',
    class: '',
    id_staff: '',
    image_face: '',
    image_front: '',
    image_back: '',
    id_card: '',
    note: '',
    people: '',
    save_percent: '',
}

class ModalReturnCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            startDate: new Date(),
            dataInfo: {
                ...dataInfoDefault
            }
        }

    }

    handleChangeDate = (date) => {
        this.setState({
            startDate: date
        });
    }

    onCloseModal = () => {
        this.props.onHide()
    }

    onSave = () => {
        var dataPost = this.state.dataInfo;
        if (!this.state.startDate) {
            swal('Cảnh báo!', "Vui lòng nhập thời gian trả thẻ", "warning");
            this.setState({
                loadingBtn: false
            });
            return
        }
        if (Moment(this.state.startDate).format("YYYY/MM/DD HH:mm") < Moment(dataPost.date, "DD/MM/YYYY HH:mm").format("YYYY/MM/DD HH:mm")) {
            swal('Cảnh báo!', "Thời gian trả thẻ lớn hơn thời gian vào", "warning");
            this.setState({
                loadingBtn: false
            });
            return
        }
        fetch(`${HOST_EKYC}/api/ekyc/save`, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': store.getState().isLogin.access_token
                    },
                    body: JSON.stringify({ ...dataPost, 
                        path_front: dataPost.url_image_front,
                        path_back: dataPost.url_image_back,
                        checkout_time: Moment(this.state.startDate).format("YYYY/MM/DD HH:mm:ss")
                    })
                })
            .then(response => {
                return response.json()
            }).then(data => {
                if (data.status === 10000) {
                    swal({
                        title: "Thành công",
                        text: "Lưu thành công!",
                        icon: "success",
                        button: false,
                        timer: 2000,
                    })
                    this.onCloseModal()
                } else {
                    swal({
                        title: "Lưu dữ liệu thất bại, vui lòng kiểm tra lại!",
                        icon: "error",
                        buttons: {
                            confirm: {
                                text: 'Đóng',
                                value: null,
                                visible: true,
                                closeModal: true,
                            },
                        },
                        dangerMode: false,
                    })
                }
            }).catch((error) => {
                if (error) {
                    swal({
                        title: "Lưu dữ liệu thất bại, kiểm tra lại kết nối rồi thử lại!",
                        icon: "error",
                        buttons: {
                            confirm: {
                                text: 'Đóng',
                                value: null,
                                visible: true,
                                closeModal: true,
                            },
                        },
                        dangerMode: false,
                    })
                } else {
                    swal.stopLoading();
                    swal.close();
                }
            });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.show === true) {
            var info = nextProps.data;
            this.setState({
                dataInfo: info,
                startDate: new Date(),
            });

        }
    }
    render() {
        return (
            <Modal
                {...this.props}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                // style={{  width: '100%' }}
                backdrop="static"
                dialogClassName="modal-size-card"
            >
                <Modal.Header closeButton>
                    <h5><b>{this.state.dataInfo.name} - {this.state.dataInfo.id}</b></h5>
                </Modal.Header>
                <Modal.Body>
                    <div className="m-portlet__body m-portlet__body--no-padding">
                        <div className="row m-row--no-padding m-row--col-separator-xl">
                            <div className="w-100 text-center">
                                <h5 ><>Nhập thời gian trả thẻ</></h5>
                                <div className="w-100 mt-3 pr-5 pl-5">
                                    <DatePicker
                                        className="form-control"
                                        selected={this.state.startDate}
                                        dateFormat="dd/MM/yyyy HH:mm"
                                        timeFormat="HH:mm"
                                        showTimeSelect
                                        placeholder=""
                                        onChange={this.handleChangeDate}
                                        selectsStart
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>

                    <button variant="accent" className={"btn btn-success m-btn--icon mr-2 m-loader--light m-loader--right "} onClick={this.onSave} >Xác nhận</button>
                    <button variant="secondary" className={"btn btn-danger m-btn--icon mr-2 m-loader--light m-loader--right "} onClick={this.onCloseModal}>Huỷ</button>
                </Modal.Footer>
            </Modal>
        );
    }
}


export default ModalReturnCard;