import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import $ from 'jquery';
import {HIDE_SCAN_DOCUMENT} from "../../config"
import Store from '../../store';
class MenuBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataRole: Store.getState().role.role,
        }
        Store.subscribe(() => {
            this.setState({
                dataRole: Store.getState().role.role,
            });
        });
    }


    onClickClose = () => {
        $('#m_aside_left').removeClass('m-aside-left--on')
        $('#body_form').removeClass('m-aside-left--on')
        $('#m_aside_left_toggle').removeClass('m-aside-left-toggler--active')
        $('.m-aside-left-overlay').remove()
    }

    render() {
        var active = '';
        if (window.location.pathname === "/") {
            active = 'm-menu__item--active'
        }

        return (
            <div id="m_aside_left" className="m-aside-left  m-aside-left--skin-dark">
                {/* BEGIN: Aside Menu */}
                <div id="m_ver_menu" className="m-aside-menu  m-aside-menu--skin-dark m-aside-menu--submenu-skin-dark " data-menu-vertical="true" m-menu-scrollable={1} m-menu-dropdown-timeout={500}>
                    <ul className="m-menu__nav  m-menu__nav--dropdown-submenu-arrow ">
                        <li className="m-menu__section m-menu__section--first">
                            {/* <h4 className="m-menu__section-text">Departments</h4> */}
                            <i className="m-menu__section-icon flaticon-more-v3" />
                        </li>
                        <li className="m-menu__item  m-menu__item--submenu" aria-haspopup="true" m-menu-submenu-toggle="hover">
                            <NavLink to="/bitable" className={"m-menu__link " + active} activeClassName="m-menu__item--active" onClick={() => this.onClickClose()}>
                                <i className="m-menu__link-icon flaticon-symbol" />
                                <span className="m-menu__link-title">
                                    <span className="m-menu__link-wrap">
                                        <span className="m-menu__link-text">BiTable</span>
                                    </span>
                                </span>
                            </NavLink>
                        </li>
                        {HIDE_SCAN_DOCUMENT !== "1" ?
                            <li className="m-menu__item  m-menu__item--submenu" aria-haspopup="true" m-menu-submenu-toggle="hover">
                                <NavLink to="/scandocument" className={"m-menu__link " + active} activeClassName="m-menu__item--active" onClick={() => this.onClickClose()}>
                                    <i className="m-menu__link-icon flaticon-symbol" />
                                    <span className="m-menu__link-title">
                                        <span className="m-menu__link-wrap">
                                            <span className="m-menu__link-text">ScanDocument</span>
                                        </span>
                                    </span>
                                </NavLink>
                            </li> : ""
                        }
                        <li className="m-menu__item  m-menu__item--submenu" aria-haspopup="true" m-menu-submenu-toggle="hover">
                            <NavLink to="/history" className={"m-menu__link "} activeClassName="m-menu__item--active" onClick={() => this.onClickClose()}>
                                <i className="m-menu__link-icon flaticon-interface-9" />
                                <span className="m-menu__link-title">
                                    <span className="m-menu__link-wrap">
                                        <span className="m-menu__link-text">Lịch sử Ra / Vào</span>
                                    </span>
                                </span>
                            </NavLink>
                        </li>
                        <li className="m-menu__item  m-menu__item--submenu" aria-haspopup="true" m-menu-submenu-toggle="hover">
                            <NavLink to="/appointment" className={"m-menu__link "} activeClassName="m-menu__item--active" onClick={() => this.onClickClose()}>
                                <i className="m-menu__link-icon flaticon-calendar" />
                                <span className="m-menu__link-title">
                                    <span className="m-menu__link-wrap">
                                        <span className="m-menu__link-text">Quản lí lịch hẹn</span>
                                    </span>
                                </span>
                            </NavLink>
                        </li>
                        <li className="m-menu__item  m-menu__item--submenu" aria-haspopup="true" m-menu-submenu-toggle="hover">
                            <NavLink to="/customer" className={"m-menu__link "} activeClassName="m-menu__item--active" onClick={() => this.onClickClose()}>
                                <i className="m-menu__link-icon flaticon-users" />
                                <span className="m-menu__link-title">
                                    <span className="m-menu__link-wrap">
                                        <span className="m-menu__link-text">Khách hàng</span>
                                    </span>
                                </span>
                            </NavLink>
                        </li>
                        {["1", "2"].includes(this.state.dataRole) &&
                            <li className="m-menu__item  m-menu__item--submenu" aria-haspopup="true" m-menu-submenu-toggle="hover">
                            <a href="#/" className="m-menu__link m-menu__toggle">
                                <i className="m-menu__link-icon flaticon-settings" />
                                <span className="m-menu__link-text">Cài đặt</span>
                                <i className="m-menu__ver-arrow la la-angle-right" />
                            </a>
                            <div className="m-menu__submenu ">
                                <span className="m-menu__arrow" />
                                <ul className="m-menu__subnav">
                                    <li className="m-menu__item " aria-haspopup="true">
                                        <NavLink to="/setting" className="m-menu__link " activeClassName="m-menu__item--active" onClick={() => this.onClickClose()}>
                                            <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                                                <span />
                                            </i>
                                            <span className="m-menu__link-text">Cài đặt chung</span>
                                        </NavLink>
                                    </li>
                                    <li className="m-menu__item " aria-haspopup="true">
                                        <NavLink to="/setting-department" className="m-menu__link " activeClassName="m-menu__item--active" onClick={() => this.onClickClose()}>
                                            <i className="m-menu__link-bullet m-menu__link-bullet--dot">
                                                <span />
                                            </i>
                                            <span className="m-menu__link-text">Tầng - Phòng ban</span>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        }
                      
                    </ul>
                </div>
            </div>
        );
    }
}

export default MenuBar;