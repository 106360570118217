import React, { Component } from 'react';
import swal from 'sweetalert';
import { css } from '@emotion/core';
import { PulseLoader } from 'react-spinners';
import Pagination from "react-js-pagination";
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import Moment from 'moment';
import { HOST_EKYC } from '../../config'
import $ from 'jquery';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ModalInfo from "./ModalInfo";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import store from '../../store';
import { id } from 'date-fns/esm/locale';
import ModalFormEdit from './ModalFormEdit';
import ReactToPrint, {onBeforeGetContent} from 'react-to-print';
import Tooltip from '@material-ui/core/Tooltip';
import { LOGO } from '../../config'
import ModalDeclare from '../BiTable/ModalDeclare';
import { withRouter } from 'react-router'; 
import axios from 'axios';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

var dataInfoDefault = {
    address: "",
    country: "",
    date: Moment(new Date()).format('DD/MM/YYYY HH:mm:ss'),
    ethnicity: "",
    expiry: "",
    id_type: "",
    issue_by: "",
    issue_date: "",
    national: "",
    religion: "",
    time: Moment(new Date()).format('HH:mm:ss'),
    id: '',
    name: '',
    birthday: '',
    sex: '',
    expiry: '',
    reason: '',
    origin: '',
    class: '',
    id_staff: '',
    image_face: '',
    image_front: '',
    image_back: '',
    id_card: '',
    note: '',
    people: '',
    save_percent: '',
}


const all_camera = {
    id_box_engine: -1,
    title: 'Tất cả Bitable',
}

var dataPrint = {
    name : '',
    birthday : '',
    sex : '',
    national : '',
    id : '',
    breath  : '',
    carCheck  :'',
    cough  : '',
    covidPlace  : '',
    destination  : '',
    fever  :'',
    flyCheck  : '',
    goDate  : '',
    phoneDeclare  : '',
    returnDate  : '',
    shipCheck  : '',
    startDate  : '',
    startPlace  : '',
    takeCare  : '',
    throat  : '',
    vehicleOther  : '',
    workPlace  : '',
}

//In file 
class ComponentToPrint extends Component {
    constructor(props) {
        super(props);

    }
    render() {
        var datePrint = new Date().getDate()
        if (datePrint.toString().length === 1) {
            datePrint = "0" + datePrint
        }
        var datePrint2 = new Date(this.props.date).getDate()
        if (datePrint2.toString().length === 1) {
            datePrint2 = "0" + datePrint2
        }
        var startDate = this.props.dataPrint.startDate

        if (startDate) {
            var dataSplit = startDate.split('/');
            var dateConverted;
            if (dataSplit[2].split(" ").length > 1) {
                var hora = dataSplit[2].split(" ")[1].split(':');
                dataSplit[2] = dataSplit[2].split(" ")[0];
                dateConverted = new Date(dataSplit[2], dataSplit[1] - 1, dataSplit[0], hora[0], hora[1]);
            } else {
                dateConverted = new Date(dataSplit[2], dataSplit[1] - 1, dataSplit[0]);

            }
        }

        if (startDate) {
            var datePre = Moment(dateConverted).subtract(14, 'days').format('DD/MM/YYYY')

        } else {
            var datePre = Moment(new Date()).subtract(14, 'days').format('DD/MM/YYYY')
        }
        var dateSlice = startDate.slice(0,2)
        var monthSlice = startDate.slice(3,5)
        var yearSlice = startDate.slice(6,11)
        
        return (
            <div id="divToPrint" >
            <div className="print" id="divToPrint" style={{ display: 'none' }} >
                <img alt="bitable-print" src={LOGO} style={{ paddingTop: '20px', display: 'block', marginRight: 'auto', width: '15%' }} />
                <div id="print-title" style={{ textAlign: 'center', paddingTop: '40px', fontSize: '25px' }}>TỜ KHAI Y TẾ</div>
                <div id="print" style={{ padding: '20px 20px 20px 50px ', fontSize: '17px' }}>Đây là tài liệu quan trọng, thông tin của Anh/Chị sẽ giúp cơ quan y tế liên lạc khi cần thiết để phòng chống dịch bệnh truyền nhiễm</div>
                <div className=" div_print" id="print" style={{ display: 'flex', padding: '20px 20px 20px 50px ' }} >
                    <div id="print" style={{ paddingRight: '10px', fontSize: '17px' }}>Họ tên (viết chữ in hoa):</div><div style={{ fontSize: '17px', textTransform: 'uppercase' }}>{this.props.dataPrint.name ? this.props.dataPrint.name : "........................................." }</div>
                </div>
                <div className=" div_print" style={{ display: 'flex', padding: '20px 20px 20px 50px ' }}>
                    <div id="print" style={{ paddingRight: '10px', fontSize: '17px' }}>Năm sinh:</div><div style={{ fontSize: '17px' }}>{this.props.dataPrint.birthday === "Invalid date" ? "........................................." : this.props.dataPrint.birthday }</div>
                    <div id="print" style={{ paddingRight: '10px', paddingLeft: '20px', fontSize: '17px' }}>Giới tính:</div><div style={{ fontSize: '17px' }}>{this.props.dataPrint.sex ?  this.props.dataPrint.sex : "........................................."}</div>
                    <div id="print" style={{ paddingRight: '10px', paddingLeft: '20px', fontSize: '17px' }}>Quốc tịch:</div><div style={{ fontSize: '17px' }}>{this.props.dataPrint.national ? this.props.dataPrint.national : "........................................." }</div>
                </div >
                <div className=" div_print" style={{ display: 'flex', padding: '20px 20px 20px 50px ' }}>
                    <div id="print" style={{ paddingRight: '10px', fontSize: '17px' }}>Số CMND, Căn cước công dân, hộ chiếu:</div><div style={{ fontSize: '17px' }}>{this.props.dataPrint.id ? this.props.dataPrint.id : "........................................." }</div>
                </div>
                <div className=" div_print" style={{ display: 'flex', padding: '20px 20px 20px 50px ' }}>
                    <div id="print" style={{ paddingRight: '10px', fontSize: '17px' }}>Thông tin đi lại:</div>
                    <div id="print" style={{ fontSize: '17px' }}>
                        Tàu bay <input type="checkbox" checked={(this.props.dataPrint && this.props.dataPrint.flyCheck) ? true : false} />
                    </div>
                    <div id="print" style={{ fontSize: '17px', paddingLeft: '20px' }}>
                        Tàu thuyền <input type="checkbox" checked={(this.props.dataPrint && this.props.dataPrint.shipCheck) ? true : false} />
                    </div>
                    <div id="print" style={{ fontSize: '17px', paddingLeft: '20px' }}>
                        Ô tô <input type="checkbox" checked={(this.props.dataPrint && this.props.dataPrint.carCheck) ? true : false} />
                    </div>
                    <div id="print" style={{ fontSize: '17px', paddingLeft: '20px' }}>Khác (ghi rõ): {`${this.props.dataPrint.vehicleOther ? this.props.dataPrint.vehicleOther : "........................................................................................................."} `}</div>
                </div>
                <div className=" div_print" style={{ display: 'flex', padding: '20px 20px 20px 50px ' }}>
                    <div id="print" style={{ paddingRight: '10px', fontSize: '17px' }}>Ngày khởi hành:</div>
                    <div style={{ fontSize: '17px' }}>{`${this.props.dataPrint.goDate ? this.props.dataPrint.goDate : ".........../.........../................"}`}</div>
                    <div id="print" style={{ paddingRight: '10px', paddingLeft: '30px', fontSize: '17px' }}>Ngày về:</div>
                    <div style={{ fontSize: '17px' }}>{`${this.props.dataPrint.returnDate ? this.props.dataPrint.returnDate : ".........../.........../................"}`}</div>
                </div >
                <div className=" div_print" style={{ display: 'flex', padding: '20px 20px 20px 50px ' }} >
                    <div id="print" style={{ paddingRight: '10px', fontSize: '17px' }}>Địa điểm khởi hành :</div>
                    <div id="print" style={{ fontSize: '17px' }}>{`${this.props.dataPrint.startPlace ? this.props.dataPrint.startPlace : "..................................................................................................................................................................................."}`}</div>
                </div>
                <div className=" div_print" style={{ display: 'flex', padding: '20px 20px 20px 50px ' }} >
                    <div id="print" style={{ paddingRight: '10px', fontSize: '17px' }}>Địa điểm nơi đến :</div>
                    <div id="print" style={{ fontSize: '17px' }}>{`${this.props.dataPrint.destination ? this.props.dataPrint.destination : "........................................................................................................................................................................................"}`}</div>
                </div>
                <div className=" div_print" style={{ display: 'flex', padding: '20px 20px 20px 50px ' }} >
                    <div id="print" style={{ paddingRight: '10px', fontSize: '17px' }}>Nơi làm việc :</div>
                    <div id="print" style={{ fontSize: '17px' }}>{`${this.props.dataPrint.workPlace ? this.props.dataPrint.workPlace : "..............................................................................................................................................................................................."}`}</div>
                </div>
                <div className=" div_print" style={{ display: 'flex', padding: '20px 20px 20px 50px ' }} >
                    <div id="print" style={{ paddingRight: '10px', fontSize: '17px' }}>{`Trong vòng 14 ngày (kể từ ngày ${datePre} đến nay), Anh/Chị có đến các địa điểm mà Bộ Y Tế công bố ca nhiễm COVID-19 không? (Nếu có ghi rõ) :`}</div>
                </div>
                <div className=" div_print" style={{ display: 'flex', padding: '10px 20px 20px 50px ' }} >
                    <div id="print" style={{ fontSize: '17px' }}>{`${this.props.dataPrint.covidPlace ? this.props.dataPrint.covidPlace : "........................................................................................................................................................................................................................"}`}</div>
                </div>
                <div className=" div_print" style={{ display: 'flex', padding: '20px 20px 20px 50px ' }} >
                    <div id="print" style={{ paddingRight: '10px', fontSize: '17px' }}>Điện thoại :</div>
                    <div id="print" style={{ fontSize: '17px' }}>{`${this.props.dataPrint.phoneDeclare ? this.props.dataPrint.phoneDeclare : "..................................................."}`}</div>
                </div>
                <div className=" div_print" style={{ display: 'flex', padding: '20px 20px 20px 50px ' }} >
                    <div id="print" style={{ paddingRight: '10px', fontSize: '17px' }}>Trong vòng 14 ngày (tính đến thời điểm làm thủ tục khởi hành ) Anh/Chị có thấy xuất hiện dấu hiệu  nào sau đây không ?</div>
                </div>
                <div className=" div_print" id="print table" style={{ fontSize: '17px', padding: '20px 50px 10px 50px ' }}>
                    <table className="table table-bordered table-hover table-checkable dataTable no-footer dtr-inline collapsed" >
                        <thead id="print">
                            <tr style={{ textAlign: 'center' }}>
                                <th>Triệu chứng</th>
                                <th>Có </th>
                                <th>Không</th>
                            </tr>
                        </thead>
                        <tbody id="print">
                            <tr >
                                <td>Sốt</td>
                                <td style={{ whiteSpace: 'pre', textAlign: 'center' }}>{`${this.props.dataPrint.fever === "1" ? "✓" : ""}`}</td>
                                <td style={{ whiteSpace: 'pre', textAlign: 'center' }}>{`${this.props.dataPrint.fever === "0" || this.props.dataPrint.fever === null ? "✓" : ""}`}</td>
                            </tr>
                            <tr >
                                <td>Ho</td>
                                <td style={{ whiteSpace: 'pre', textAlign: 'center' }}>{`${this.props.dataPrint.cough === "1" ? "✓" : ""}`}</td>
                                <td style={{ whiteSpace: 'pre', textAlign: 'center' }}>{`${this.props.dataPrint.cough === "0" || this.props.dataPrint.fever === null? "✓" : ""}`}</td>
                            </tr>
                            <tr >
                                <td>Khó thở</td>
                                <td style={{ whiteSpace: 'pre', textAlign: 'center' }}>{`${this.props.dataPrint.breath === "1" ? "✓" : ""}`}</td>
                                <td style={{ whiteSpace: 'pre', textAlign: 'center' }}>{`${this.props.dataPrint.breath === "0" || this.props.dataPrint.fever === null? "✓" : ""}`}</td>
                            </tr>
                            <tr >
                                <td>Đau họng</td>
                                <td style={{ whiteSpace: 'pre', textAlign: 'center' }}>{`${this.props.dataPrint.throat === "1" ? "✓" : ""}`}</td>
                                <td style={{ whiteSpace: 'pre', textAlign: 'center' }}>{`${this.props.dataPrint.throat === "0" || this.props.dataPrint.fever === null? "✓" : ""}`}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className=" div_print" id="print" style={{ fontSize: '17px', padding: '10px 50px 10px 50px ' }}>
                    <table className="table table-bordered table-hover table-checkable dataTable no-footer dtr-inline collapsed" >
                        <thead id="print">
                            <tr style={{ textAlign: 'center' }}>
                                <th style={{ textAlign: 'center' }}>Trực tiếp chăm sóc người bệnh truyền nhiễm </th>
                                <th style={{ whiteSpace: 'pre', textAlign: 'center' }}>{`${this.props.dataPrint.takeCare === "1" ? "Có [ ✓ ]" : "Có [   ]"}`}</th>
                                <th style={{ whiteSpace: 'pre', textAlign: 'center' }}>{`${this.props.dataPrint.takeCare === "0" || this.props.dataPrint.takeCare === null ? "Không [ ✓ ]" : "Không [   ]"}`}</th>
                            </tr>
                        </thead>
                    </table>
                </div>
                <div className=" div_print" style={{ display: 'flex', padding: '10px 20px 5px 50px ' }} >
                    <div id="print" style={{ paddingRight: '10px', fontSize: '17px' }}>Tôi cam kết những thông tin trên là đúng sự thật.</div>
                </div>
                <div style={{ display: 'flex', padding: '5px 20px 2px 50px ' }} >
                    <div id="print" style={{ fontSize: '17px', whiteSpace: 'pre' }}>{`${this.props.dataPrint.startDate ? `Ngày  ${dateSlice}  Tháng  ${monthSlice}  Năm  ${yearSlice} ` : `Ngày  ${datePrint}  Tháng  ${new Date().getMonth() + 1}  Năm  ${new Date().getFullYear()}  `}`}</div>
                    <div id="printSign" style={{ paddingRight: '10px', paddingLeft: '490px', fontSize: '17px' }}>Kí tên</div>
                </div>
                <div id="print" style={{ paddingLeft: '755px', fontSize: '17px' }}>(ghi rõ họ tên)</div>
            </div>
            </div>

        )
    }
}
const initialDataDeclare = {
    startPlace: '',
    destination: '',
    workPlace: '',
    covidPlace: '',
    phoneDeclare: '',
    vehicleOther: '',
    flyCheck: false,
    shipCheck: false,
    carCheck: false,
    fever: "0",
    cough: "0",
    breath: "0",
    throat: "0",
    takeCare: "0",
    startDate: new Date(),
    goDate: '',
    returnDate: '',
    birthday: '',
    id: '',
    name: '',
    national: '',
    sex: ''
}
class History extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data_json_export: [],
            dataHistory: [],
            total_count: 0,
            offset: 0,
            activePage: 1,
            startDate: null,
            endDate: new Date(),
            keyWord:'',
            loading: true,
            ModalInfo: false,
            dataInfo: {
                ...dataInfoDefault
            },
            listBox: store.getState().company.crr_company ? [{
                id_box_engine: -1,
                title: 'Tất cả Bitable',
            }].concat(store.getState().company.crr_company.dataBox) : [all_camera],
            crrBox: all_camera,
            crrCompany: store.getState().company.crr_company ? store.getState().company.crr_company : null,
            showModalEdit: false,
            crrValue: null,
            dataPrint: {
                ...dataPrint
            },
            ModalDeclare: false,
            dataDeclare: initialDataDeclare,
            type_export: 1,
            dataRole: store.getState().role.role,
        }
        store.subscribe(() => {
            this.setState({
                crrCompany: store.getState().company.crr_company,
                dataRole: store.getState().role.role,
            }, () => {
                    let listBox = this.state.crrCompany ? [...this.state.crrCompany.dataBox] : [];
                    listBox.unshift(all_camera)
                    this.setState({
                        listBox: listBox,
                        crrBox: all_camera,
                    }, () => { this.getHistory() });
                    
            })
        })
        this.itemsPerPage = 5;
    }
    
    componentDidMount = () => {
        this.getHistory()
    };
    modalClose = () => {
        this.setState({
            ModalInfo: false,
        });
    }
    changeHandleBox = (e, value) => {
        this.setState({
            crrBox: value,
        })
    }

    modalClose = () => {
        this.setState({
            ModalInfo: false,
        });
    }

    getHistory = () => {
        if(!this.state.crrCompany) return;
        fetch(`${HOST_EKYC}/api/ekyc/history`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': store.getState().isLogin.access_token
            },
            body: JSON.stringify({
                "start_date": this.state.startDate ? Moment(this.state.startDate).format("DD/MM/YYYY HH:mm:ss") : '',
                "end_date": this.state.endDate ? Moment(this.state.endDate).format("DD/MM/YYYY HH:mm:ss") : '',
                "key_word":this.state.keyWord,
                "page_num": this.state.activePage,
                "page_size": this.itemsPerPage,
                "export": "0",
                "id_camera": this.state.crrBox.id_box_engine,
                "id_company": this.state.crrCompany ? this.state.crrCompany.value : '',
            })
        }).then((response) => {
            return response.json()
        }).then((dataRes) => {
            if (dataRes.result_code == 200) {
                this.setState({
                    dataHistory: dataRes.data,
                    data_json_export: dataRes.data,
                    total_count: dataRes.total_count,
                    loading: false
                })
                this.FilterStaff(this.state.activePage)
            } else {
                this.setState({
                    dataHistory: [],
                    total_count: 0,
                    loading: false
                })
            }
        }).catch((error) => {
            this.setState({
                dataHistory: [],
                total_count: 0,
                loading: false
            })
            console.log(error)
        })
    }

    _exporter;
    ExportExcel = (type) => {
        if(!this.state.crrCompany) return;
        fetch(`${HOST_EKYC}/api/ekyc/history`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': store.getState().isLogin.access_token
            },
            body: JSON.stringify({
                "start_date": this.state.startDate ? Moment(this.state.startDate).format("DD/MM/YYYY HH:mm:ss") : "",
                "end_date": this.state.endDate ? Moment(this.state.endDate).format("DD/MM/YYYY HH:mm:ss") : "",
                "key_word":this.state.keyWord,
                "page_num": this.state.activePage,
                "page_size": this.itemsPerPage,
                "export": "1",
                "id_camera": this.state.crrBox.id_box_engine,
                "id_company": this.state.crrCompany ? this.state.crrCompany.value : '',
            })
        }).then((response) => {
            return response.json()
        }).then((dataRes) => {
            if (dataRes.result_code == 200) {
                for (let i = 0; i < dataRes.data.length; i++) {
                    dataRes.data[i]['offset'] = i + 1
                    if (dataRes.data[i].schedules.length > 0) {
                        dataRes.data[i]['guardian'] = dataRes.data[i].schedules[0].guardian
                    } else {
                        dataRes.data[i]['guardian'] = ""
                    }

                    if (dataRes.data[i].schedules.length > 0) {
                        dataRes.data[i]['description'] = dataRes.data[i].schedules[0].description
                    } else {
                        dataRes.data[i]['description'] = ""
                    }

                    if (dataRes.data[i].schedules.length > 0 && dataRes.data[i].schedules[0].department) {
                        dataRes.data[i]['department_name'] = dataRes.data[i].schedules[0].department.name
                    } else {
                        dataRes.data[i]['department_name'] = ""
                    }

                    if (dataRes.data[i].declaration !== null) {
                        dataRes.data[i]['declaration'] = "Có"
                    } else {
                        dataRes.data[i]['declaration'] = "Không"
                    }

                    if (dataRes.data[i].schedules.length > 0 && dataRes.data[i].schedules[0].department) {
                        dataRes.data[i]['floor'] = dataRes.data[i].schedules[0].department.floor
                    } else {
                        dataRes.data[i]['floor'] = ""
                    }

                    if (dataRes.data[i].date !== "") {
                        dataRes.data[i]['date_in'] = dataRes.data[i].date.split(" ")[0] + " " + dataRes.data[i].date.split(" ")[1].slice(0, 5)
                    } else {
                        dataRes.data[i]['date_in'] = ""
                    }

                    if (dataRes.data[i].date !== "") {
                        dataRes.data[i]['time_in'] = dataRes.data[i].date.split(" ")[1].slice(0, 5)
                    } else {
                        dataRes.data[i]['time_in'] = ""
                    }

                    if (dataRes.data[i].checkout_time !== "") {
                        dataRes.data[i]['date_out'] = Moment(dataRes.data[i].checkout_time).format("DD/MM/YYYY HH:mm") 
                    } else {
                        dataRes.data[i]['date_out'] = ""
                    }

                }
                this.setState({
                    data_json_export: dataRes.data,
                    loading: false,
                    type_export: type
                })
                this._exporter.save();
            } else {
                this.setState({
                    data_json_export: [],
                    loading: false
                })
            }
        }).catch((error) => {
            console.log(error)
        })
    }


    FilterStaff = (activePage) => {
        const offset = (activePage - 1) * this.itemsPerPage;
        this.setState({
            offset: offset
        })
    }

    handlePageChange = (pageNumber) => {
        this.setState({
            ...this.state,
            activePage: pageNumber
        }, () => {
            this.getHistory()
        })
    }

    handleChangeStart = date => {
        this.setState({
            startDate: date,
        });
    };

    handleChangeEnd = date => {
        this.setState({
            endDate: date,
        });
    };

    handleClickSearch = () => {
        this.setState({
            dataHistory: [],
            total_count: 0,
            loading: true
        }, () => {
            this.getHistory()
        })
    }

    reset = (e) => {
        this.setState({
            activePage: 1,
            dataHistory: [],
            data_json_export: [],
            loading: true,
            crrBox: all_camera,
        }, () => {
            this.getHistory();
        });
    }

    isChange = (event) => {
        if (event.key === 'Enter') {
            this.handleClickSearch()
        }
        this.setState({
            keyWord: event.target.value.trim()
        });
    }

    getInfo = (value) => {
        var { dataInfo } = this.state;
        dataInfo.url_image_face=value.url_image_face;
        dataInfo.url_image_front=value.url_image_front;
        dataInfo.url_image_back=value.url_image_back;
        dataInfo.id=value.id;
        dataInfo.name=value.name;
        dataInfo.birthday=value.birthday;
        dataInfo.country=value.country;
        dataInfo.sex=value.sex;
        dataInfo.ethnicity=value.ethnicity;
        dataInfo.national=value.national;
        dataInfo.origin=value.origin;
        dataInfo.address=value.address;
        dataInfo.expiry=value.expiry;
        dataInfo.religion=value.religion;
        dataInfo.issue_date=value.issue_date;
        dataInfo.issue_by=value.issue_by;
        dataInfo.class=value.class;
        dataInfo.reason=value.reason;
        dataInfo.id_card=value.id_card;
        dataInfo.people=value.people;
        dataInfo.note=value.note;
        dataInfo.date=value.date;
        this.setState({
            ModalInfo: true,
            dataInfo
        });

    }

    handleDelete = (_id) => {
        swal({
            title: 'Bạn có chắc',
            text: 'Bạn có chắc muốn xóa lịch sử',
            icon: "warning",
            buttons: {
                oke: 'Xóa',
                cancel: 'Đóng',
            },
        }).then(async (value) => {
            if (value === 'oke') {
                if (!this.state.crrCompany) return;
                fetch(`${HOST_EKYC}/api/ekyc/delete`, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': store.getState().isLogin.access_token
                    },
                    body: JSON.stringify({
                        _id: _id,
                    })
                }).then((response) => {
                    return response.json()
                }).then((dataRes) => {
                    if (dataRes.result_code == 200) {
                        swal({
                            title: 'Thành công',
                            text: 'Xóa thành công',
                            buttons: false,
                            timer: 1500,
                            icon: 'success',
                        });
                        this.getHistory();
                    } else {
                        swal({
                            title: 'Lỗi',
                            text: 'Xóa không thành công',
                            buttons: false,
                            timer: 1500,
                            icon: 'error',
                        })
                    }
                }).catch((error) => {
                    console.log(error);
                    swal({
                        title: 'Lỗi',
                        text: 'Xóa không thành công',
                        buttons: false,
                        timer: 1500,
                        icon: 'error',
                    })
                })
            }
        })
        
    }

    handleEdit = (value) => {
        this.setState({
            showModalEdit: true,
            crrValue: value,
        })
    }
    closeModalEdit = () => {
        this.setState({
            showModalEdit: false,
            crrValue: null,
        })
    }




    handleBeforeGetContent = (value) => {
        var {dataPrint} = this.state;
        dataPrint.name = value.name ? value.name : value.declaration ? value.declaration.name : '';
        dataPrint.birthday = value.birthday ? value.birthday : value.declaration ? value.declaration.birthday : '';
        dataPrint.sex = value.sex ? value.sex : value.declaration ? value.declaration.sex : '';
        dataPrint.national = value.national ? value.national : value.declaration ? value.declaration.national : '';
        dataPrint.id = value.id ? value.id : value.declaration ? value.declaration : '';
        dataPrint.breath  = value.declaration ? value.declaration.breath : '';
        dataPrint.carCheck  = value.declaration ? value.declaration.carCheck : '';
        dataPrint.cough  = value.declaration ? value.declaration.cough : '';
        dataPrint.covidPlace  = value.declaration ? value.declaration.covidPlace : '';
        dataPrint.destination  = value.declaration ? value.declaration.destination : '';
        dataPrint.fever  = value.declaration ? value.declaration.fever : '';
        dataPrint.flyCheck  = value.declaration ? value.declaration.flyCheck : '';
        dataPrint.goDate  = value.declaration ? value.declaration.goDate : '';
        dataPrint.phoneDeclare  = value.declaration ? value.declaration.phoneDeclare : '';
        dataPrint.returnDate  = value.declaration ? value.declaration.returnDate : '';
        dataPrint.shipCheck  = value.declaration ? value.declaration.shipCheck : '';
        dataPrint.startDate  = value.declaration ? value.declaration.startDate : '';
        dataPrint.startPlace  = value.declaration ? value.declaration.startPlace : '';
        dataPrint.takeCare  = value.declaration ? value.declaration.takeCare : '';
        dataPrint.throat  = value.declaration ? value.declaration.throat : '';
        dataPrint.vehicleOther  = value.declaration ? value.declaration.vehicleOther : '';
        dataPrint.workPlace  = value.declaration ? value.declaration.workPlace : '';
        this.setState({
            dataPrint,
        });
        return new Promise((resolve) => {
            setTimeout(() => {
              this.setState({ text: "Print!!"}, resolve);
            }, 1000);
        });
    }

    handleViewDeclaration = (value) => {
        let data = {
            startPlace: '',
            destination: '',
            workPlace: '',
            covidPlace: '',
            phoneDeclare: '',
            vehicleOther: '',
            flyCheck: false,
            shipCheck: false,
            carCheck: false,
            fever: "0",
            cough: "0",
            breath: "0",
            throat: "0",
            takeCare: "0",
            startDate: Moment(new Date()).format('DD/MM/YYYY'),
            goDate: '',
            returnDate: '',
            birthday: '',
            id: '',
            name: '',
            national: '',
            sex: ''
        }
        if (value.declaration) {
            data = value.declaration;
        } else {
            data = {
                ...data,
                name: value.name,
                birthday: value.birthday,
                sex: value.sex,
                national: value.national,
                id: value.id,
                phone_number: value.phone_number
            }
        }
        this.setState({
            dataDeclare: data,
            ModalDeclare: true
        })
    }

    handleAdditional = (dataAddition) => {
        this.props.history.push('/', dataAddition);
    }

    getBase64Axios(url,typeImg, index) {
        const type = typeImg === 1 ? "imageFace_" : typeImg === 2 ? "imageFront_" : "imageBack_"
        var imageNode = document.getElementById(type+index);
        return axios.get(url, {
            responseType: 'blob',
            headers: {
                'Authorization': store.getState().isLogin.access_token
            }
          }).then(response => {
            const buffer = URL.createObjectURL(response.data)
            imageNode.src = buffer
          }).catch(error => {
            console.log(error)
          })
      }

    render() {
        var pageRangeDisplayed = 1;
        if ($(window).width() < 768) {
            pageRangeDisplayed = 3
        } else {
            pageRangeDisplayed = 5
        }

        const dataRenderHistory = this.state.dataHistory.map((value, index) => {
            return (
                <tr key={index}>
                    <td>{(index + this.state.offset + 1)}</td>
                    <td><img id={`imageFace_${index}`} className="img_face"  src={value.url_image_face ? this.getBase64Axios(`${HOST_EKYC}/` + value.url_image_face,1, index)  : './img/photo-placeholder.png'} alt="" /></td>
                    <td><img id={`imageFront_${index}`} className="img_front" src={value.url_image_front ? this.getBase64Axios(`${HOST_EKYC}/` + value.url_image_front,2, index) : './img/no-image.jpg'} alt="" /></td>
                    <td><img id={`imageBack_${index}`} className="img_back" src={value.url_image_back ? this.getBase64Axios(`${HOST_EKYC}/` + value.url_image_back,3, index) : './img/no-image.jpg'} alt="" /></td>
                    <td >{value.name}</td>
                    <td><a style={{textDecoration: "underline", cursor: "pointer"}} onClick={() => this.handleEdit(value)}>{value.id}</a></td>
                    <td>{value.phone_number}</td>
                    <td style={{whiteSpace: "initial"}}>{value.work_unit}</td>
                    <td className="text-center">{value.date.split(" ")[0]}<br></br>{value.date.split(" ")[1].slice(0,5)}</td>
                    <td className="text-center">{value.checkout_time && Moment(value.checkout_time).format("DD/MM/YYYY")}<br></br>{value.checkout_time && Moment(value.checkout_time).format("HH:mm")}</td>
                    <td style={{whiteSpace: "initial"}}>{value.schedules.length > 0 ? value.schedules[0].description : ""}</td>
                    <td style={{whiteSpace: "initial"}}>{value.schedules.length > 0 ? value.schedules[0].guardian : ""}</td>
                    <td style={{whiteSpace: "initial"}}>{(value.schedules.length > 0 && value.schedules[0].department) ? value.schedules[0].department.name : ""}</td>
                    <td style={{whiteSpace: "initial"}}>{(value.schedules.length > 0 && value.schedules[0].department) ? value.schedules[0].department.floor : ""}</td>
                    {/* <td>{value.class}</td> */}
                    <td>{value.id_card}</td>
                    {/* <td>{value.id_camera}</td> */}
                    <td>
                        {
                            value.declaration ?
                                <a style={{textDecoration: "underline", cursor: "pointer"}} onClick={() => this.handleViewDeclaration(value)} className='text-success'>Có</a>
                                :
                                <a style={{textDecoration: "underline", cursor: "pointer"}} onClick={() => this.handleViewDeclaration(value)} className='text-danger'>Không</a>
                        }
                    </td>
                    <td>
                        {/* <Tooltip title="Chỉnh sửa" placement="top" arrow>   
                            <button
                                className="m-portlet__nav-link btn m-btn m-btn--hover-warning m-btn--icon m-btn--icon-only m-btn--pill"
                                onClick={() => this.handleEdit(value)}
                                >
                                <i className="la la-edit" />
                            </button>
                        </Tooltip> */}
                        <Tooltip title="Bổ sung" placement="top" arrow>   
                            <button
                                className="m-portlet__nav-link btn m-btn m-btn--hover-info m-btn--icon m-btn--icon-only m-btn--pill"
                                onClick={() => this.handleAdditional(value)}
                                >
                                <i className="flaticon-add" />
                            </button>
                        </Tooltip>
                        {["5"].includes(this.state.dataRole) ? "" :
                            <Tooltip title="Xoá" placement="top" arrow>   
                            <button
                                className="m-portlet__nav-link btn m-btn m-btn--hover-danger m-btn--icon m-btn--icon-only m-btn--pill"
                                onClick={() => this.handleDelete(value._id)}
                                >
                                <i className="la la-trash" />
                            </button>
                        </Tooltip> 
                        }
                    </td>
                    {/* <td>
                        <button
                            onClick={() => this.handleViewDeclaration(value)}
                            className="m-portlet__nav-link btn m-btn m-btn--hover-warning m-btn--icon m-btn--icon-only m-btn--pill" data-tip data-for='Print'>
                            <i className="la la-eye" />
                        </button>
                        <ReactToPrint
                            onBeforeGetContent={() => this.handleBeforeGetContent(value)}

                            trigger={() =>
                                <Tooltip title="In khai báo" placement="top" arrow>
                                    <button
                                        className="m-portlet__nav-link btn m-btn m-btn--hover-warning m-btn--icon m-btn--icon-only m-btn--pill" data-tip data-for='Print'>
                                        <i className="la la-print" />
                                    </button>
                                </Tooltip>

                            }
                            content={() => this.componentRef}
                        />
                        <ComponentToPrint ref={el => (this.componentRef = el)} dataPrint={this.state.dataPrint} dataPrint2={""} />
                    </td> */}
                </tr>
            )
        })

        return (
            <div className="m-grid__item m-grid__item--fluid m-wrapper">
                <div className="m-content p-2">
                    <div className="m-portlet m-portlet--tab mb-0">
                        <div className="m-portlet__head p-3">
                            <div className="m-portlet__head-caption pl-3">
                                <div className="m-portlet__head-title">
                                    <h3 className="m-portlet__head-text">
                                        Lịch Sử Ra / Vào
                                    </h3>
                                </div>
                            </div>
                            <div className="m-portlet__head-tools pr-3">
                                <ul className="m-portlet__nav">
                                    <li className="m-portlet__nav-item">

                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="m-portlet__body m-portlet__body--no-padding">
                            <div className="row m-row--no-padding m-row--col-separator-xl">
                                <div className="col-xl-12">
                                    <div className="m-widget1">
                                        <div className='row m-0'>
                                            <div className="col-md-2 pl-md-0">
                                                <Autocomplete
                                                    className='auto_select'
                                                    options={this.state.listBox}
                                                    getOptionLabel={option => option.title}
                                                    value={this.state.crrBox}
                                                    onChange={this.changeHandleBox}
                                                    disableClearable
                                                    renderInput={params => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            placeholder="Chọn thiết bị..."
                                                            fullWidth
                                                        />
                                                    )}
                                                />
                                            </div>
                                            <div className="col-md-2 pl-md-0">
                                                <DatePicker
                                                    className="form-control"
                                                    selected={this.state.startDate}
                                                    dateFormat="dd/MM/yyyy HH:mm"
                                                    timeFormat="HH:mm"
                                                    showTimeSelect
                                                    onChange={this.handleChangeStart}
                                                    placeholderText='Ngày bắt đầu...'
                                                    selectsStart
                                                    startDate={this.state.startDate}
                                                    endDate={this.state.endDate}
                                                />
                                            </div>
                                            <div className="col-md-2 pl-md-0">
                                                <DatePicker
                                                    className="form-control"
                                                    dateFormat="dd/MM/yyyy HH:mm"
                                                    timeFormat="HH:mm"
                                                    showTimeSelect
                                                    selected={this.state.endDate}
                                                    onChange={this.handleChangeEnd}
                                                    placeholderText='Ngày kết thúc...'
                                                    selectsEnd
                                                    startDate={this.state.startDate}
                                                    endDate={this.state.endDate}
                                                    minDate={this.state.startDate}
                                                />
                                            </div>
                                            <div className="col-lg-2 pl-0 m--margin-bottom-10-tablet-and-mobile">
                                                <input type="text" onKeyUp={(event) => this.isChange(event)} id="inputSearch" className="form-control m-input" placeholder="Số định danh / Họ Tên / Số thẻ / SĐT" />
                                            </div>
                                            <div className='col-md-2 pl-md-0 d-flex'>
                                                <button
                                                    onClick={(dataSearch) => {
                                                        this.handleClickSearch()

                                                    }}
                                                    className="btn btn-accent m-btn m-btn--icon mr-3" id="m_search">
                                                    <span>
                                                        <i className="la la-search" />
                                                        <span>Tìm kiếm</span>
                                                    </span>
                                                </button>
                                                <button
                                                    onClick={(dataSearch) => {
                                                        this.reset()

                                                    }}
                                                    className="btn btn-secondary m-btn m-btn--icon" id="m_reset">
                                                    <span>
                                                        <i className="la la-rotate-left" />
                                                        <span>Tải lại</span>
                                                    </span>
                                                </button>
                                            </div>
                                            <div className="text-right col-md-2 p-0">
                                                {/* <button
                                                    onClick={() => {
                                                        this.ExportExcel()
                                                    }}
                                                    className="btn btn-success m-btn m-btn--icon dropdown-toggle" data-toggle="dropdown" id="dropdownMenuButton" aria-expanded="false" aria-haspopup="true">
                                                    <span>
                                                        <i className="la la-download" />
                                                        <span>Xuất Excel</span>
                                                    </span>
                                                </button> */}
                                                    <button className="btn btn-success dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <i className="la la-download pr-2" />
                                                        <span>Xuất Excel </span>
                                                    </button>
                                                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                                        <a onClick={() => {this.ExportExcel(2)}}  className="dropdown-item" href="#">Xuất danh sách ra / vào</a>
                                                        <a   onClick={() => {this.ExportExcel(1)}} className="dropdown-item" href="#">Xuất danh sách định danh giấy tờ</a>
                                                    </div>
                                            </div>
                                        </div>
                                        <div className="table-responsive text-nowrap dataTables_wrapper">
                                            <table className="table table-bordered table-hover dataTable mb-0">
                                                <thead>
                                                    <tr>
                                                        <th  style={{'width': '10px' }}>STT</th>
                                                        <th  style={{'width': '150px' }}>Ảnh Mặt</th>
                                                        <th  style={{'width': '150px' }}>Ảnh CMT Trước</th>
                                                        <th  style={{'width': '150px' }}>Ảnh CMT Sau</th>
                                                        <th style={{'width': '200px' }}>Họ Tên</th>
                                                        <th style={{'width': '100px' }}>Số định danh</th>
                                                        <th style={{'width': '100px' }}>SĐT</th>
                                                        <th style={{'width': '70px' }}>Đơn vị/Công ty</th>
                                                        <th style={{'width': '100px' }}>Thời gian vào</th>
                                                        <th style={{'width': '100px' }}>Thời gian ra</th>
                                                        <th style={{'width': '150px' }}>Nội dung làm việc</th>
                                                        <th style={{'width': '100px' }}>Người bảo lãnh</th>
                                                        <th style={{'width': '100px' }}>Phòng ban</th>
                                                        <th style={{'width': '30px' }}>Tầng</th>
                                                        {/* <th  style={{'width': '50px' }}>Loại giấy tờ</th> */}
                                                        <th style={{'width': '10px' }}>Số thẻ</th>
                                                        {/* <th style={{'width': '150px' }}>Ngày xử lý</th> */}
                                                        {/* <th style={{'width': '80px' }}>ID Thiết bị</th> */}
                                                        <th style={{'width': '20px' }}>TKYT</th>
                                                        <th  style={{'width': '20px' }}>Thao tác</th>
                                                        {/* <th style={{'width': '50px' }}>TKYT</th> */}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {dataRenderHistory}
                                                </tbody>
                                            </table>
                                            <div className="text-center">
                                                <PulseLoader
                                                    css={override}
                                                    sizeUnit={"px"}
                                                    size={12}
                                                    margin={'2px'}
                                                    color={'#36D7B7'}
                                                    loading={this.state.loading}
                                                />
                                            </div>
                                        </div>
                                        <ExcelExport
                                            data={this.state.data_json_export}
                                            fileName={this.state.type_export !== 1 ? "Xuất_Lịch_Sử_Ra_Vào_" + Moment(new Date()).format("DD-MM-YYYY") : "Xuất_Danh_Sách_Định_Danh_Giấy_Tờ_" + Moment(new Date()).format("DD-MM-YYYY") + ".xlsx"}
                                            ref={(exporter) => { this._exporter = exporter; }}>
                                            {this.state.type_export !== 1 ? 
                                            <>
                                                <ExcelExportColumn field="offset" title="STT" width={40} />
                                                <ExcelExportColumn field="name" title="Họ Tên" width={200} />
                                                <ExcelExportColumn field="id" title="Số định danh" width={100} />
                                                <ExcelExportColumn field="phone_number" title="SĐT" />
                                                <ExcelExportColumn field="work_unit" title="Tên đơn vị / Công ty" width={160} />
                                                <ExcelExportColumn field="date_in" title="Thời gian vào" width={130} />
                                                <ExcelExportColumn field="date_out" title="Thời gian ra" width={130} />
                                                <ExcelExportColumn field="id_card" title="Số thẻ" />
                                                <ExcelExportColumn field="description" title="Nội dung làm việc" width={180}/>
                                                <ExcelExportColumn field="guardian" title="Người bảo lãnh" />
                                                <ExcelExportColumn field="department_name" title="Phòng ban" width={120} />
                                                <ExcelExportColumn field="floor" title="Tầng" width={100} />
                                                <ExcelExportColumn field="declaration" title="KBYT" width={100} />
                                                <ExcelExportColumn field="id_camera" title="BiTable ID" />
                                            </> : <> 
                                                <ExcelExportColumn field="id" title="Số định danh" width={100} />
                                                <ExcelExportColumn field="name" title="Họ Tên" width={200} />
                                                <ExcelExportColumn field="phone_number" title="Số điện thoại" />
                                                <ExcelExportColumn field="birthday" title="Ngày sinh" width={120} />
                                                <ExcelExportColumn field="sex" title="Giới tính" width={120} />
                                                <ExcelExportColumn field="national" title="Quốc tịch" width={120} />
                                                <ExcelExportColumn field="country" title="Quốc gia" width={120}/>
                                                <ExcelExportColumn field="address" title="Nơi thường trú" />
                                                <ExcelExportColumn field="expiry" title="Hạn sử dụng thẻ" width={100} />
                                                <ExcelExportColumn field="issue_date" title="Ngày cấp" width={100} />
                                                <ExcelExportColumn field="class" title="Loại bằng" width={100} />
                                                <ExcelExportColumn field="date" title="Ngày xử lý" width={160} />
                                                <ExcelExportColumn field="id_camera" title="BiTable ID" />
                                                </>
                                            } 
                                        </ExcelExport>
                                        {/* <ModalInfo
                                            show={this.state.ModalInfo}
                                            data={this.state.dataInfo}
                                            onHide={this.modalClose}
                                        /> */}
                                        {
                                            this.state.showModalEdit &&
                                            <ModalFormEdit show={this.state.showModalEdit} data={this.state.crrValue} onHide={this.closeModalEdit} onRefresh={this.getHistory}/>
                                        }
                                        {
                                            this.state.ModalDeclare &&
                                            <ModalDeclare
                                                data={this.state.dataDeclare}
                                                show={this.state.ModalDeclare}
                                                onHide={() => this.setState({ ModalDeclare: false, dataDeclare: initialDataDeclare })}
                                                phone={this.state.phone_number}
                                                viewDeclaration={true}
                                            />
                                        }
                                        <Pagination
                                            prevPageText='Trang trước'
                                            nextPageText='Trang sau'
                                            firstPageText='Trang đầu'
                                            lastPageText='Trang cuối'
                                            activePage={this.state.activePage}
                                            itemsCountPerPage={this.itemsPerPage}
                                            totalItemsCount={this.state.total_count}
                                            pageRangeDisplayed={pageRangeDisplayed}
                                            onChange={this.handlePageChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(History);