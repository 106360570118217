import React, { useState, useEffect } from 'react';
import Select from "react-select";
import Moment from 'moment';
import { HOST_EKYC, TYPE_CUSTOMER } from '../../config';
import DatePicker from "react-datepicker";
import swal from 'sweetalert';
import swals from '@sweetalert/with-react';
import { Modal } from 'react-bootstrap';
import { HIDE_REGISTER_CUSTOMERS } from '../../config';
// import ModalPerson from './ModalPerson';
import { KeyboardTimePicker } from '@material-ui/pickers'
import { useSelector } from 'react-redux';
import DOMPurify from 'dompurify';
import ModalPerson from '../HistoryAppointment/ModalAdd';

const ModalRegister = (props) => {

    const { onChangeRadio,onModalAddClose, show, onHide, data, onSave, listCam, appointment, integrate, listPerson, idCompany, objID, idStaff, name_staff } = props;
    const initial = {
        ids_camera: [],
        start_time: Moment(new Date()).format("HH:mm"),
        end_time: Moment(new Date()).add(2, 'hours').format("HH:mm"),
        start_date: Moment(new Date()).format("YYYY-MM-DD"),
        end_date: Moment(new Date()).format("YYYY-MM-DD"),
        description: "",
        guardian: "",
        obj_department: "",
        ids_staff: []
    }
    const [optionsCams, setOptionsCams] = useState([])
    const [crrRegister, setCrrRegister] = useState(initial)
    const [listCalenders, setListCalenders] = useState([])
    const [doorRegister, setDoorRegister] = useState(false)
    const [statusText, setStatusText] = useState(1)
    const [listFloorDepart, setListFloorDepart] = useState([])
    const [floorDepartment, setFloorDepartment] = useState(null)
    const [valueCam, setValueCam] = useState([])
    const [valueTime, setValueTime] = useState({
        start_time: new Date(),
        end_time: new Date().setHours(new Date().getHours() + 2),
    })
    const [disabledSave, setDisabledSave] = useState(true);

    const [valuePerson ,setValuePerson] = useState(null);
    const [modalPerson, setModalPerson] = useState(false);

    const token = useSelector(state => state.isLogin.access_token);






    const handleChangeHourStartTime = startTime => {
        var DoorOpenHand = crrRegister;
        crrRegister['start_time'] = Moment(startTime).format("HH:mm");
        setValueTime({ ...valueTime, start_time: startTime })
        setCrrRegister({ ...crrRegister, DoorOpenHand })

        // if (Moment(startTime).format("HH:mm") < crrRegister['end_time']) {
        //     crrRegister['start_time'] = Moment(startTime).format("HH:mm");
        //     setValueTime({...valueTime, start_time: startTime})
        //     setCrrRegister({ ...crrRegister, DoorOpenHand })
        // } else {
        //     swals("Cảnh báo", "Thời gian ra phải lớn hơn thời gian vào", "warning");
        // }

    }


    const handleChangeHourEndTime = endTime => {
        var DoorOpenHand = crrRegister;
        if (Moment(endTime).format("HH:mm") > DoorOpenHand['start_time']) {
            DoorOpenHand['end_time'] = Moment(endTime).format("HH:mm");
            setValueTime({ ...valueTime, end_time: endTime })
            setCrrRegister({ ...crrRegister, DoorOpenHand })
        } else {
            setValueTime({ ...valueTime, end_time: null })
            setCrrRegister({ ...crrRegister, end_time: null })
            swal("Cảnh báo", "Thời gian vào phải nhỏ hơn thời gian ra", "warning");
        }
    }

    const handleChangeDayStartTime = startDay => {
        const start_date = Moment(startDay).format("YYYY-MM-DD");
        const end_date = Moment(startDay).format("YYYY-MM-DD");

        setCrrRegister({
            ...crrRegister,
            start_date,
            end_date,
        })

    }

    // const handleChangeDayEndTime = endDay => {
    //     const end_date = Moment(endDay).format("YYYY-MM-DD");
    //     setCrrRegister({ ...crrRegister, end_date })
    // }

    const saveRegister = () => {
        console.log(floorDepartment)
        if (valueTime.end_time === null) {
            swal("Cảnh báo", "Thời gian ra không được để trống", "warning");
        } else {
            setStatusText(1)
            let listCalender = [...listCalenders]
            var exists = 0;
            for (var i = 0; i < listCalender.length; i++) {
                if (listCalender.indexOf(listCalender[i]) === crrRegister.index) {
                    listCalender[i].end_date = crrRegister.end_date;
                    listCalender[i].end_time = crrRegister.end_time;
                    listCalender[i].start_date = crrRegister.start_date;
                    listCalender[i].start_time = crrRegister.start_time;
                    listCalender[i].ids_camera = crrRegister.ids_camera;
                    listCalender[i].description = crrRegister.description;
                    listCalender[i].guardian = crrRegister.guardian;
                    listCalender[i].obj_department = floorDepartment.value;
                    listCalender[i].ids_staff = idStaff;
                    exists = 1;
                }
            }

            if (exists === 0) {
                listCalender.push(crrRegister)
            }
            setListCalenders(listCalender)
            setCrrRegister(initial)
            setDisabledSave(false)
            setValueTime({ ...valueTime, start_time: new Date(), end_time: Moment(new Date()).add(2, 'hours').format("HH:mm") })
            setValueCam([])
            setDoorRegister(!doorRegister)
        }
    }

    const handleFinish = async () => {
        if (valueTime.end_time === null || valueTime.start_time === null) {
            swal("Cảnh báo", "Thời gian không được để trống", "warning");
        }
        if (appointment && (!valuePerson || valuePerson.length === 0)) {
            return swal("Cảnh báo", "Vui lòng chọn khách hàng", "warning");
        }
        let ids_staff = [];
        if (valuePerson && valuePerson.length === 1 && valuePerson[0].value === -1) {
            ids_staff = listPerson.slice(1, listPerson.length).map(map => map.value);
        } else if (valuePerson) {
            ids_staff = valuePerson.map(map => map.value)
        }
        let dataPost = [{...crrRegister,
            ids_staff: idStaff, 
            creator: integrate ? objID : '', 
            description: DOMPurify.sanitize(crrRegister.description.replace(/</g, "").replace(/>/g, "")),
            guardian: DOMPurify.sanitize(crrRegister.guardian.replace(/</g, "").replace(/>/g, "")),
        }]
        
        if (appointment) {
            const dataReq = {
                schedules: [{
                    ...crrRegister,
                    ids_staff: ids_staff, 
                    creator: integrate ? objID : '',
                    description: DOMPurify.sanitize(crrRegister.description.replace(/</g, "").replace(/>/g, "")),
                    guardian: DOMPurify.sanitize(crrRegister.guardian.replace(/</g, "").replace(/>/g, "")),
                 }],
                is_register: 0,
                id_company: idCompany,
            }
            await createAppointment(dataReq);

        } else {
            onSave(dataPost);
        }
    }

    const getDeparment = () => {
        fetch(`${HOST_EKYC}/api/department/get_by_company/${idCompany}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token
            },
        })
            .then(response => {
                return response.json()
            }).then(data => {
                if (data.status == 10000) {
                    var dataOption = []
                    for (let i = 0; i < data.data.length; i++) {
                        dataOption.push({value: data.data[i]._id, label: "Tầng " + data.data[i].floor + " - " + "Phòng ban " + data.data[i].name});
                    }
                    setListFloorDepart(dataOption)
                }
            }).catch((error) => {
                console.log(error)
            });
    }

    useEffect(() => {
        getDeparment()
 
    },[])

    const createAppointment = (data) => {

        fetch(`${HOST_EKYC}/api/appointment_schedule/insert_or_update`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                return response.json()
            }).then(data => {
                if (data.status == 10000) {
                    swal({
                        icon: 'success',
                        title: 'Thành công',
                        text: 'Tạo lịch hẹn thành công',
                        timer: 1500,
                        buttons: false,
                    })
                    onHide(true);
                } else {
                    swal("Thất bại", "Tạo lịch hẹn thất bại!", "error");
                    swal({
                        icon: 'error',
                        title: 'Thất bại',
                        text: 'Tạo lịch hẹn thất bại!',
                        timer: 1500,
                        buttons: false,
                    })
                }
            }).catch((error) => {
                swal({
                    icon: 'error',
                    title: 'Thất bại',
                    text: 'Tạo lịch hẹn thất bại!',
                    timer: 1500,
                    buttons: false,
                })
            });
    }


    const handleChangeCams = (value) => {
        let ids_camera = [];
        if (value) {
            const is_all = value.some(some => some.value === -1);
            if (is_all) {
                setValueCam([optionsCams[0]]);
                ids_camera = [-1];
                setCrrRegister({ ...crrRegister, ids_camera })
                return;
            }
            ids_camera = value.map(map => map.value);
            setValueCam(value)
            setCrrRegister({ ...crrRegister, ids_camera })
            return;
        }
        setValueCam(value)
        setCrrRegister({ ...crrRegister, ids_camera })
    }

    const handleAllCams = () => {
        const ids_camera = optionsCams.map(map => map.value);

        setCrrRegister({ ...crrRegister, ids_camera });
        setValueCam(optionsCams);
    }

    const onChangeReson = (e) => {
        crrRegister[e.target.name] = e.target.value.replace(/</g, "").replace(/>/g, "") ;
        setCrrRegister({ ...crrRegister })
    }

    // const editSchedule = (data, i) => {
    //     setStatusText(2)
    //     const valueCam = []
    //     for (let i = 0; i < data.ids_camera.length; i++) {
    //         for (let j = 0; j < optionsCams.length; j++) {
    //             if (data.ids_camera[i] === optionsCams[j].value) {
    //                 valueCam.push({ 'value': data.ids_camera[i], 'label': optionsCams[j].label })
    //             }
    //         }

    //     }

    //     const endTimeSplit = data.end_time.split(':')
    //     const startTimeSplit = data.start_time.split(':')

    //     setValueTime({ ...valueTime, end_time: new Date().setHours(endTimeSplit[0], endTimeSplit[1]), start_time: new Date().setHours(startTimeSplit[0], startTimeSplit[1]) })
    //     setValueCam(valueCam)
    //     setCrrRegister({ ...data, index: i })
    //     setDoorRegister(true)
    // }

    const handleChangePerson = (value) => {
        if (value) {
            const is_all = value.some(some => some.value === -1);
            if (is_all) {
                // setValuePerson([listPerson[0]]);
                setModalPerson(true)
                return;
            } 
            setValuePerson(value);
            return;
        }
        setValuePerson(value);
    }

    const closeModalPerson = () => {
        setModalPerson(false);
    }

    const onSelectPerson = (data) => {
        setValuePerson(data);
    }

    const handleChangeDepartment = (value) => {
        setFloorDepartment(value)
        setCrrRegister({ ...crrRegister, obj_department: value.value })
    }

    const onSubmitAdd = (data, id) => {
        let label = data.work_unit !== "" ? data.name +" - "+ data.id +" - "+ data.work_unit : data.name +" - "+ data.id 
        let dataAdd = [{"value": id, "label": label}]
        if (valuePerson !== null) {
            let dataTotal = valuePerson.concat(dataAdd)
            setValuePerson(dataTotal);
        } else {
            setValuePerson(dataAdd)
        }
        setModalPerson(false);
        onModalAddClose()
    }

    useEffect(() => {
        setOptionsCams(listCam)
        setValueCam(listCam);
        const ids_camera = listCam.map(map => map.value);

        setCrrRegister({ ...initial, ids_camera, guardian: integrate ? name_staff : initial.guardian });

        setListCalenders([])
        setDoorRegister(false)
        // setCrrRegister(initial)
        setDisabledSave(true)
        setValueTime({ ...valueTime, start_time: new Date(), end_time: new Date().setHours(new Date().getHours() + 2), })
    }, [show, listCam])





    return (
        <>
            {/* {modalPerson && <ModalPerson show={modalPerson} onHide={closeModalPerson} persons={valuePerson} idCompany={idCompany} onSelectPerson={onSelectPerson}/>} */}
            {modalPerson && <ModalPerson show={modalPerson} onHide={closeModalPerson} idCompany={idCompany} onSubmitAdd={onSubmitAdd}/>}
            <Modal
                show={show}
                onHide={onHide}
                backdrop="static"
                dialogClassName={`${modalPerson ? "d-none" : ""}`}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Đăng kí lịch hẹn
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-lg-12 mb-3">
                        {
                            appointment ?
                                <div className="pl-2">
                                    <label className="my-2">Khách hàng:</label>
                                    <div className='d-flex align-items-center'>
                                        <Select
                                            className="select my-2 w-100 mr-2"
                                            getOptionValue={(option) => option.label}
                                            onChange={value => handleChangePerson(value)}
                                            isMulti
                                            closeMenuOnSelect={false}
                                            options={listPerson}
                                            value={valuePerson}
                                            // onInputChange={(value) => handleInputChange(value)}
                                            placeholder='Chọn khách...'
                                        />
                                        {/* <button style={{width: '17%' }} className="btn btn-info m-btn--icon mr-2 m-loader--light m-loader--right" onClick={() => setModalPerson(true)}>
                                            <i className='la la-search'></i>
                                        </button> */}
                                    </div>
                                </div>
                                :
                                <>
                                    <div className="row pl-2 pb-4" >
                                        <label className="m-0 pl-3">Khách: <b>{data && data.name}</b></label>
                                    </div>
                                </>
                        }
                            <div className="col-xl-12  p-0">
                                <div className="row pt-0 m-2">
                                    <div className="form-group m-form__group col-md-12 p-0">
                                        <label>Thiết bị BiFace</label>
                                        <div className='d-flex align-items-center'>
                                            <Select
                                                className="select w-100 mr-2"
                                                getOptionValue={(option) => option.label}
                                                onChange={value => handleChangeCams(value)}
                                                isMulti
                                                closeMenuOnSelect={false}
                                                options={optionsCams}
                                                value={valueCam}
                                                placeholder="Chọn BiFace"
                                            />
                                            <button style={{width: '17%' }} className="btn btn-success m-btn--icon m-loader--light m-loader--right" onClick={handleAllCams}>
                                                Tất cả
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mb-3 p-0">
                                        <label>Người bảo lãnh:</label>
                                        <input placeholder="Nhập tên" rows="1" type="text" className="form-control m-input" name="guardian" value={crrRegister.guardian} onChange={(e) => onChangeReson(e)} />
                                    </div>
                                    <div className="form-group m-form__group col-md-12 p-0">
                                        <label>Tầng - Phòng ban</label>
                                        <div className='d-flex align-items-center'>
                                            <Select
                                                className="select w-100"
                                                getOptionValue={(option) => option.label}
                                                onChange={value => handleChangeDepartment(value)}
                                                options={listFloorDepart}
                                                value={floorDepartment}
                                                placeholder="Tầng - Phòng ban"
                                            />
                                        </div>
                                    </div>
                                   
                                    <div className="form-group m-form__group col-md-6 pl-0">
                                        <label>Thời gian vào</label>
                                        <div>
                                        
                                             <KeyboardTimePicker
                                                placeholder='HH:mm'
                                                mask='__:__'
                                                ampm={false}
                                                style={{ width: "100%" }}
                                                value={valueTime.start_time}
                                                onChange={handleChangeHourStartTime}
                                                invalidDateMessage="Định dạng thời gian sai"
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group m-form__group col-md-6 pr-0">
                                        <label>Thời gian ra</label>
                                        <div>
                                              <KeyboardTimePicker
                                                placeholder='HH:mm'
                                                mask='__:__'
                                                ampm={false}
                                                style={{ width: "100%" }}
                                                value={valueTime.end_time}
                                                onChange={handleChangeHourEndTime}
                                                invalidDateMessage="Định dạng thời gian sai"
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group m-form__group col-md-12 p-0">
                                        <label>Ngày hẹn</label>
                                        <div>
                                            <DatePicker
                                                className="form-control m-input m_datetimepicker_6"
                                                selected={new Date(crrRegister.start_date)}
                                                onChange={handleChangeDayStartTime}
                                                dateFormat="dd-MM-yyyy"
                                                minDate={new Date()}
                                                placeholderText="Start Date"
                                            />
                                        </div>
                                    </div>
                                    {/* <div className="form-group m-form__group col-md-6 pr-0">
                                        <label>Ngày kết thúc</label>
                                        <div>
                                            <DatePicker
                                                className="form-control m-input m_datetimepicker_6"
                                                selected={new Date(crrRegister.end_date)}
                                                onChange={handleChangeDayEndTime}
                                                // minDate={subDays(new Date(crrRegister.start_date),0)}
                                                dateFormat="dd-MM-yyyy"
                                                placeholderText="End Date"
                                            />
                                        </div>
                                    </div> */}
                                    <div className="col-md-12 mb-3 p-0">
                                        <label>Nội dung làm việc</label>
                                        <textarea type="text" className="form-control m-input" name="description" value={crrRegister.description} onChange={(e) => onChangeReson(e)} />
                                    </div>
                                    {/* <div className="mx-auto p-3">
                                        <button onClick={() => saveRegister()} className="btn btn-success m-btn--icon mr-2 m-loader--light m-loader--right" >Lưu</button>
                                    </div> */}
                                </div>
                            </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <button className={"btn btn-success m-btn--icon mr-2 m-loader--light m-loader--right "} onClick={() => handleFinish()} >Hoàn tất</button>
                    <button className={"btn btn-danger m-btn--icon mr-2 m-loader--light m-loader--right "} onClick={onHide}>Hủy</button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ModalRegister;
