import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import swal from 'sweetalert';
import { HOST_EKYC } from '../../config/index'

ModalInfo.propTypes = {
    show: PropTypes.bool,
    data: PropTypes.object,
    onHide: PropTypes.func,
    onRefresh: PropTypes.func,
};

ModalInfo.defaultProps = {
    show: false,
    data: null,
    onHide: null,
    onRefresh: null,
}

function ModalInfo(props) {
    const { show, onHide, data, onRefresh } = props;
    const [crrData, setCrrData] = useState(null);

    

    
    useEffect(() => {
        setCrrData(data);
        return () => {
            setCrrData(null);
        }
    }, [data]);
    if(!crrData) return <></>;
    
    return (
        <Modal show={show} onHide={onHide} size='lg'>
            <div className='row-sm row'>
                <div className='col-md-12'>
                    <div className='m-portlet m-portlet--tab'>
                        <Modal.Header closeButton>
                            {/* <Modal.Title>
                          
                            </Modal.Title> */}
                        </Modal.Header>
                        {
                            crrData &&
                            <>
                                <Modal.Body>
                                    <div className='row p-0 m-0'>
                                        <div className='col-12 col-md-6 py-2'>
                                            <h4>Dữ liệu ảnh mặt trước</h4>
                                            <img className="img_front" src={!crrData.history.url_front ? "./img/photo-placeholder.png" : `${HOST_EKYC}/` + crrData.history.url_front} alt=""
                                                style={{
                                                    width: '100%',
                                                    maxHeight: '500px',
                                                    objectFit: 'contain',
                                                    height: "250px",
                                                }} />
                                        </div>
                                        <div className='col-12 col-md-6 py-2'>
                                            <h4>Dữ liệu ảnh mặt sau</h4>
                                            <img className="img_front" src={!crrData.history.url_back ? "./img/photo-placeholder.png" : `${HOST_EKYC}/` + crrData.history.url_back} alt=""
                                                style={{
                                                    width: '100%',
                                                    maxHeight: '500px',
                                                    objectFit: 'contain',
                                                    height: "250px",
                                                }} />
                                        </div>
                                        <div className="col-12 col-md-6 py-2 input_dot">
                                            <label>Số định danh giấy tờ:</label>
                                            <input type="text" className="form-control m-input" name="id" value={crrData.history.id} />
                                        </div>
                                        <div className="col-12 col-md-6 py-2 input_dot">
                                            <label>Họ và tên:</label>
                                            <input type="text" className="form-control m-input" name="name" value={crrData.history.name} />
                                        </div>
                                        <div className="col-12 col-md-6 py-2 input_dot">
                                            <label>Ngày sinh:</label>
                                            <input type="text" className="form-control m-input" name="birthday" value={crrData.history.birthday === "Invalid date" ? "" : crrData.history.birthday} />
                                        </div>
                                        <div className="col-12 col-md-6 py-2 input_dot">
                                            <label>Giới tính:</label>
                                            <input type="text" className="form-control m-input" name="sex" value={crrData.history.sex} />
                                        </div>
                                        <div className="col-12 col-md-6 py-2 input_dot">
                                            <label>Quốc tịch:</label>
                                            <input type="text" className="form-control m-input" name="national" value={crrData.history.national} />
                                        </div>
                                        <div className="col-12 col-md-6 py-2 input_dot">
                                            <label>Hạn sử dụng thẻ:</label>
                                            <input type="text" className="form-control m-input" name="expiry" value={crrData.history.expiry} />
                                        </div>
                                        <div className="col-12 col-md-6 py-2 input_dot">
                                            <label>Số điện thoại:</label>
                                            <input type="text" className="form-control m-input" name="phone_number" value={crrData.history.phone_number} />
                                        </div>
                                        <div className="col-12 col-md-6 py-2 input_dot">
                                            <label>Ngày cấp:</label>
                                            <input type="text" className="form-control m-input" name="issue_date" value={crrData.history.issue_date} />
                                        </div>
                                        <div className="col-12 col-md-6 py-2 input_dot">
                                            <label>Nơi cấp:</label>
                                            <input type="text" className="form-control m-input" name="issue_by" value={crrData.history.issue_by} />
                                        </div>
                                        <div className="col-12 col-md-6 py-2 input_dot">
                                            <label>Loại bằng:</label>
                                            <input type="text" className="form-control m-input" name="class" value={crrData.history.class} />
                                        </div>
                                        <div className="col-12 col-md-6 py-2 input_dot">
                                            <label>Quốc gia:</label>
                                            <input type="text" className="form-control m-input" name="country" value={crrData.history.country} />
                                        </div>
                                        <div className="col-12 col-md-6 py-2 input_dot">
                                            <label>Số thẻ:</label>
                                            <input type="text" className="form-control m-input" name="id_card" value={crrData.history.id_card} />
                                        </div>
                                        {/* <div className="col-12 col-md-6 py-2 input_dot">
                                            <label>Mục đích:</label>
                                            <input type="text" className="form-control m-input" name="people" value={crrData.history.people} />
                                        </div> */}
                                        {/* <div className="col-12 col-md-6 py-2 input_dot">
                                            <label>Ghi chú:</label>
                                            <input type="text" className="form-control m-input" name="note" value={crrData.history.note} />
                                        </div> */}
                                        <div className="col-12 col-md-6 py-2 input_dot">
                                            <label>Nguyên quán:</label>
                                            <textarea type="text" className="form-control m-input" rows="2" name="origin" value={crrData.history.origin} />
                                        </div>
                                        <div className="col-12 col-md-6 py-2 input_dot">
                                            <label>Nơi ĐKHKTT:</label>
                                            <textarea type="text" className="form-control m-input" rows="2" name="address" value={crrData.history.address} />
                                        </div>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button onClick={onHide} variant='secondary'>Đóng</Button>
                                </Modal.Footer>
                            </>
                        }
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default ModalInfo;