import React, { Component } from 'react';

class NotFound extends Component {

    render() {
        return (
            <div className="m-grid__item m-grid__item--fluid m-grid  m-error-3" style={{ backgroundImage: 'url(./assets/app/media/img/error/bg3.jpg)', height: '100vh' }}>
                <div className="m-error_container">
                    <span className="m-error_number">
                        <h1>404</h1>
                    </span>
                    <p className="m-error_title m--font-light">
                        How did you get here
					    </p>
                    <p className="m-error_subtitle">
                        Sorry we can't seem to find the page you're looking for.
					    </p>
                    <p className="m-error_description">
                        There may be amisspelling in the URL entered,
                        or the page you are looking for may no longer exist.
					    </p>
                </div>
            </div>
        );
    }
}

export default NotFound;