import React from 'react';
import Routerlayout from "./router";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import NotFound from './components/NotFound';
import Login from './Login';
import './App.css';
import History from './components/Integrate/History';
import MedicalDeclaration from './components/MedicalDeclaration';
import { Provider } from 'react-redux';
import store from './store';

function App() {
  return (
    <Provider store={store}>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Router>
        <Switch>
          <Route path="/login" exact render={() => <Login />} />
          <Route path="/history-appointment" exact render={() => <History />} />
          <Route path="/tokhaiyte" exact render={() => <MedicalDeclaration />} />
          <Routerlayout />
          <Route render={() => <NotFound />} />
        </Switch>
      </Router>
    </MuiPickersUtilsProvider>
    </Provider>
  );
}

export default App;
