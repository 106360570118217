import { css } from '@emotion/core';
import { Checkbox, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Moment from 'moment';
import React, { Component } from 'react';
import { Form, ProgressBar } from 'react-bootstrap';
import LoadingOverlay from 'react-loading-overlay';
import { PulseLoader } from 'react-spinners';
import ClipLoader from "react-spinners/ClipLoader";
import FadeLoader from 'react-spinners/FadeLoader';
import toastr from 'reactjs-toastr';
import openSocket from 'socket.io-client';
import swal from 'sweetalert';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'toasted-notes/src/styles.css';
import { HIDE_REGISTER_CUSTOMERS, HOST_BITABLE_CONNECT, HOST_EKYC } from '../../config';
import store from '../../store';
import ModalDeclare from "./ModalDeclare";
import ModalRegister from './modalRegister';
import ModalSearch from "./ModalSearch";
import { withRouter } from 'react-router-dom'
import { KeyboardTimePicker } from '@material-ui/pickers'
import Select from "react-select";
import DOMPurify from "dompurify";
import axios from 'axios'

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;
const override_1 = css`
    display: block;
    margin: 0 auto;
    border-color: orange;
`;

const customStyle = {
    // control: styles => ({ ...styles, backgroundColor: 'white' }),
    input: styles => ({ ...styles, padding: '4px' }),
    singleValue: styles => ({ ...styles, fontWeight: 'bold', fontSize: '16px' }),
    // option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    //   return {
    //     ...styles,
    //     fontWeight: "bold",
    //   };
    // },
  };
  

var setTimeoutGetFace;
var setTimeoutCMTFront;
var setTimeoutSocket;
var setTimeoutCMTBack;
var IntervalStream;
var dataFrontDefault = {
    id: '',
    name: '',
    birthday: '',
    sex: '',
    expiry: '',
    address: '',
    origin: '',
    country: '',
    national: '',
    class: '',
    base64: '',
}
var dataDeclare = {
    startPlace: '',
    destination: '',
    workPlace: '',
    covidPlace: '',
    phoneDeclare: '',
    vehicleOther: '',
    flyCheck: false,
    shipCheck: false,
    carCheck: false,
    fever: "0",
    cough: "0",
    breath: "0",
    throat: "0",
    takeCare: "0",
}
var dataBackDefault = {
    issue_by: '',
    issue_date: '',
    base64: '',
}
var dataSaveDefault = {
    address: "",
    country: "",
    date: Moment(new Date()).format('DD/MM/YYYY HH:mm:ss'),
    ethnicity: "",
    id_type: "",
    issue_by: "",
    issue_date: "",
    national: "",
    religion: "",
    time: Moment(new Date()).format('HH:mm:ss'),
    id: '',
    name: '',
    birthday: '',
    sex: '',
    expiry: '',
    // reason: '',
    origin: '',
    class: '',
    id_staff: '',
    image_face: '',
    image_front: '',
    image_back: '',
    id_card: '',
    // note: '',
    // people: '',
    save_percent: '',
    path_back: '',
    path_front: '',
    obj_id: ''
    // declaration:'',
    // startPlace: '',
    // destination: '',
    // workPlace: '',
    // covidPlace:'',
    // phoneDeclare:'',
    // flyCheck: +false,
    // shipCheck: +false,
    // carCheck: +false,
    // fever: 0,
    // cough: 0,
    // breath: 0,
    // throat: 0,
    // takeCare: 0,
    // vehicleOther: '',
    // goDate: '',
    // returnDate: '',
    // startDate: '',
}

var dataCreatePerson = {
    id: '',
    name: '',
    type: 5,
    image: '',
    gender: '',
    phone: '',
    age: ''
}

var dataAppointmentDefault = {
    ids_camera: [],
    start_time: null,
    end_time: null,
    start_date: Moment(new Date()).format("YYYY-MM-DD"),
    end_date: Moment(new Date()).format("YYYY-MM-DD"),
    description: "",
    guardian: "",
    obj_department: "",
    ids_staff: [],
    obj_id: "",
}



class BiTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingImageFace: false,
            loadingImage1: false,
            loadingImage2: false,
            disabled_bitable: false,
            disabled_api_url: false,
            loadingBtn: false,
            loadingProgresbar: false,
            percent: 0,
            imageFace: '',
            dataBitable: localStorage.getItem("infoTable") !== null ? JSON.parse(localStorage.getItem("infoTable")) : { name: '', url: '', stream_socket: '' },
            api_url_send_data: localStorage.getItem("infoApiUrl"),
            dataSave: {
                ...dataSaveDefault
            },
            dataDeclare: {
                ...dataDeclare,
            },
            dataFront: {
                ...dataFrontDefault
            },
            dataBack: {
                ...dataBackDefault
            },
            ModalSearch: false,
            listBox: store.getState().company.crr_company ? store.getState().company.crr_company.dataBox : [],
            listCam: store.getState().company.crr_company ? store.getState().company.crr_company.dataBox2 : [],
            crrCompany: store.getState().company.crr_company ? store.getState().company.crr_company : null,
            crrBox: localStorage.getItem("crrBox") !== null ? JSON.parse(localStorage.getItem('crrBox')) : null,
            list_IP: localStorage.getItem("list_IP") !== null ? JSON.parse(localStorage.getItem('list_IP')) : [],
            crrIP: localStorage.getItem("crrIP") !== null ? JSON.parse(localStorage.getItem('crrIP')) : null,
            flag_socket: -1,
            address: localStorage.getItem("status") !== null ? JSON.parse(localStorage.getItem('status')) : false,
            dataSaveDeclare: null,
            startDate: null,
            goDate: null,
            returnDate: null,
            ModalDeclare: false,
            dataCreatePerson: {
                ...dataCreatePerson
            },
            doorRegister: false,
            modalRegister: false,
            obj_id_history: [],
            option_select: [
                { title: "Tự động", value: 1 },
                { title: "Nhập IP", value: 2 }
            ],
            crrOption: {
                title: "Tự động", value: 1,
            },
            id_box_engine: "",
            name_device: "",
            listCamConfig: [],
            path_front: "",
            path_back: "",
            path_face: "",
            img_path_front: "",
            img_path_back: "",
            img_path_face: "",
            phone_number: '',
            work_unit: '',
            validId: false,
            validName: false,
            isValid: false,
            dataSchedules: [],
            isAdditional: false,
            dataDepartment: [],
            id_staff: [],
            listFloorDepart: [],
            crrFloorDepartment: null,
            checkedBox: true,
            hideInfomation: true,
            dataAppointment: {...dataAppointmentDefault},
            start_time: null,
            end_time: null,
            disableAppointment: false,
        }
        store.subscribe(() => {
            this.setState({
                crrCompany: store.getState().company.crr_company,
            }, () => {
                this.setState({
                    listBox: this.state.crrCompany ? this.state.crrCompany.dataBox : [],
                    listCam: this.state.crrCompany ? this.state.crrCompany.dataBox2 : [],
                    list_IP: localStorage.getItem("list_IP") !== null ? JSON.parse(localStorage.getItem('list_IP')) : [],
                    crrBox: localStorage.getItem("crrBox") !== null ? JSON.parse(localStorage.getItem('crrBox')) : null,
                    crrIP: localStorage.getItem("crrIP") !== null ? JSON.parse(localStorage.getItem('crrIP')) : null,
                    dataBitable: localStorage.getItem("infoTable") !== null ? JSON.parse(localStorage.getItem("infoTable")) : { name: '', url: '', stream_socket: '' },
                    disabled_api_url: false,
                    cameraFace: false,
                    cameraCMT: false,
                    flag_socket: -1,
                    listFloorDepart: [],
                    crrFloorDepartment: null,
                    address: localStorage.getItem("status") !== null ? JSON.parse(localStorage.getItem('status')) : false,
                });
                if (this.state.dataBitable !== null && this.state.dataBitable.stream_socket === "") {
                    this.socketDisconnect();
                    this.setState({
                        disabled_bitable: false,
                    })
                }
                this.getListCamera();
                this.getDeparment()
            })
        })

        this.socket_face = null;
        this.socket_cmt = null;
        // this.Stream();
    }
    modalClose = () => {
        this.setState({
            modalRegister: false,
        }, () => {
            this.onClickClose()
        });
    }

    modalCloseSearch = () => {
        this.setState({
            ModalSearch: false,
        }, () => {
            this.state.disabled_bitable !== false ? this.UpdateBiTable() : this.socketDisconnect()
            // this.UpdateBiTable();
        });
    }

    modalCloseDeclare = () => {
        this.setState({
            ModalDeclare: false,
        });
    }

    onModalRegister = () => {
        this.setState({
            modalRegister: true,
        });
    }

    onSearch = () => {
        this.setState({
            ModalSearch: true,
            ModalDeclare: false
        }, () => {
            this.socketDisconnect();
        });
    }

    onDecare = (e) => {
        e.preventDefault();
        this.setState({
            ModalDeclare: true,
        });
    }

    onChangeRadio = (value) => {
        var { dataCreatePerson } = this.state;
        dataCreatePerson[value.target.name] = value.target.value;
        this.setState({
            dataCreatePerson
        });
    }


    componentWillMount() {
        var { disabled_bitable, disabled_api_url } = this.state
        if (localStorage.getItem("infoTable") !== null) {
            // if (JSON.parse(localStorage.getItem("infoTable")).name !== "") {
            //     disabled_bitable = true
            // } else {
            //     disabled_bitable = false
            // }
            if (localStorage.getItem("infoApiUrl") !== null && localStorage.getItem("infoApiUrl") !== '') {
                disabled_api_url = true
            } else {
                disabled_api_url = false
            }
            this.setState({
                disabled_bitable,
                disabled_api_url,
            });
        }
        if (localStorage.getItem("status") === "true") {
            this.setState({
                address: true,
                crrOption: { title: "Nhập IP", value: 1 }
            });
        } else {
            this.setState({
                address: false,
                crrOption: { title: "Tự động", value: 2 }
            });
        }
    }

    componentWillUnmount() {
        this.socketDisconnect();
    }
    
    getBase64Axios = (url,typeImg) => {
        return axios.get(`${HOST_EKYC}/` + url, {
            responseType: 'arraybuffer',
            headers: {
                'Authorization': store.getState().isLogin.access_token
            }
          }).then(response => {
            const buffer = Buffer.from(response.data, 'base64').toString('base64')
            if (typeImg === 1) {
                this.setState({
                    img_path_front: `data:image/png;base64, ${buffer}`
                });
            } else if (typeImg === 2) {
                this.setState({
                    img_path_back: `data:image/png;base64, ${buffer}`
                });
            } else {
                this.setState({
                    img_path_face: `data:image/png;base64, ${buffer}`
                });
            }

          }).catch(error => {
            console.log(error)
          })
      }

    componentDidMount() {
        var { dataFront, dataBack, phone_number, path_front, path_back, path_face, dataSave, work_unit, dataAppointment, start_time, end_time, crrFloorDepartment } = this.state
        if (this.props.location.state) {
            var dataSchedules = {}
            if (this.props.location.state.schedules.length > 0) {
                dataSchedules = this.props.location.state.schedules[0]
            }

            dataFront.address = this.props.location.state.address
            dataFront.birthday = this.props.location.state.birthday
            dataFront.class = this.props.location.state.class
            dataFront.country = this.props.location.state.country
            dataFront.expiry = this.props.location.state.expiry
            dataFront.id = this.props.location.state.id
            dataFront.name = this.props.location.state.name
            dataFront.national = this.props.location.state.national
            dataFront.origin = this.props.location.state.origin
            dataFront.sex = this.props.location.state.sex
            //data back
            dataBack.issue_by = this.props.location.state.issue_by
            dataBack.issue_date = this.props.location.state.issue_date
            //info other
            phone_number = this.props.location.state.phone_number
            work_unit = this.props.location.state.work_unit
            //image
            path_front = this.props.location.state.url_image_front
            path_back = this.props.location.state.url_image_back
            path_face = this.props.location.state.url_image_face
            //data_save
            dataSave.address = this.props.location.state.address
            dataSave.ethnicity = this.props.location.state.ethnicity
            dataSave.name = this.props.location.state.name
            dataSave.national = this.props.location.state.national
            dataSave.religion = this.props.location.state.religion
            dataSave.id = this.props.location.state.id
            dataSave.birthday = this.props.location.state.birthday
            dataSave.origin = this.props.location.state.origin
            dataSave.sex = this.props.location.state.sex
            dataSave.expiry = this.props.location.state.expiry
            dataSave.class = this.props.location.state.class
            dataSave.obj_id = this.props.location.state._id
            dataSave.id_card = this.props.location.state.id_card
            //schedule

            this.getBase64Axios(this.props.location.state.url_image_front,1)
            this.getBase64Axios(this.props.location.state.url_image_back,2)
            this.getBase64Axios(this.props.location.state.url_image_face,3)

            this.setState({
                dataFront,
                dataBack,
                phone_number,
                work_unit,
                path_front,
                path_back,
                path_face,
                dataSave,
                disableAppointment: Object.keys(dataSchedules).length !== 0 ? true : false,
                dataAppointment: {
                    ...this.state.dataAppointment,
                    ids_camera: Object.keys(dataSchedules).length !== 0 ? dataSchedules.ids_camera : this.state.dataAppointment.ids_camera,
                    start_time: Object.keys(dataSchedules).length !== 0 ? dataSchedules.start_time : this.state.dataAppointment.start_time,
                    end_time: Object.keys(dataSchedules).length !== 0 ? dataSchedules.end_time : this.state.dataAppointment.end_time,
                    start_date: Object.keys(dataSchedules).length !== 0 ? dataSchedules.start_date : this.state.dataAppointment.start_date,
                    end_date: Object.keys(dataSchedules).length !== 0 ? dataSchedules.end_date : this.state.dataAppointment.end_date,
                    description: Object.keys(dataSchedules).length !== 0 ? dataSchedules.description : this.state.dataAppointment.description,
                    guardian: Object.keys(dataSchedules).length !== 0 ? dataSchedules.guardian : this.state.dataAppointment.guardian,
                    obj_department: Object.keys(dataSchedules).length !== 0 && Object.keys(dataSchedules.department).length !== 0 ? dataSchedules.department._id : "",
                    ids_staff: Object.keys(dataSchedules).length !== 0 ? dataSchedules.ids_staff : this.state.dataAppointment.ids_staff,
                    obj_id: Object.keys(dataSchedules).length !== 0 ? dataSchedules._id : this.state.dataAppointment.obj_id,
                },
                crrFloorDepartment: Object.keys(dataSchedules).length !== 0 && Object.keys(dataSchedules.department).length !== 0 ? {value: dataSchedules.department._id, label: "Tầng " + dataSchedules.department.floor + " - "  + dataSchedules.department.name} : null,
                start_time: (Object.keys(dataSchedules).length !== 0 && dataSchedules.start_time !== null)  ? new Date().setHours(dataSchedules.start_time.split(':')[0], dataSchedules.start_time.split(':')[1]) : null,
                end_time: (Object.keys(dataSchedules).length !== 0 && dataSchedules.end_time !== null) ? new Date().setHours(dataSchedules.end_time.split(':')[0], dataSchedules.end_time.split(':')[1]) : null,
            }, () => {
                this.setState({
                    isAdditional: true
                });
                window.history.replaceState({}, '')
            });
        }
        this.getListCamera();
        this.getDeparment()
        if (this.state.dataBitable !== null && this.state.dataBitable.stream_socket !== '') {
            this.setState({
                disabled_bitable: false,
            }, () => {
                this.UpdateBiTable()
            });
        };
    }


    socketDisconnect = () => {
        if (this.socket_face) {
            this.socket_face.disconnect();

        }
        if (this.socket_cmt) {
            this.socket_cmt.disconnect();
        }
        this.setState({
            flag_socket: -1,
        })
    }
    checkSocket = (socket, type) => {
        if (this.state.dataBitable !== null && (this.state.dataBitable.stream_socket == undefined || this.state.dataBitable.stream_socket == '')) return;
        if (socket !== undefined && socket !== null) {
            socket.disconnect();
            if (type == 'face') {
                this.setState({
                    cameraFace: "",
                });
            }
            else {
                this.setState({
                    cameraCMT: ""
                });
            }
        }

        socket = openSocket(`${this.state.dataBitable !== null && this.state.dataBitable.stream_socket}`);
        if (type == 'face') {
            this.socket_face = socket
        }
        else {
            this.socket_cmt = socket
        }
        // socket = openSocket(`http://192.168.3.249:3000`)
        socket.on('connect', () => {
            clearTimeout(setTimeoutSocket)
            socket.emit('joined', type + '_stream')
            this.setState({
                flag_socket: 1,
            })
            console.log('socket connected!')
        });

        socket.on('disconnect', () => {
            clearTimeout(setTimeoutSocket)
            this.setState({
                flag_socket: 0,
            })
            console.log('socket disconnected!');
        });

        socket.on('image', async (msg) => {
            if (type == 'face') {
                this.setState({
                    cameraFace: msg.image,
                    // cameraCMT: msg.cmt
                });
            }
            else {
                this.setState({
                    // cameraFace: msg.image,
                    cameraCMT: msg.image
                });
            }
            this.setState({
                flag_socket: 2,
            })
        });


    };


    toDataURL = (url, callback) => {
        var xhr = new XMLHttpRequest();
        xhr.onload = function () {
            var reader = new FileReader();
            reader.onloadend = function () {
                callback(reader.result);
            }
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    }
    checkSchedules = () => {
        if (this.state.dataFront.id) {
            this.getAppointment(this.state.dataFront.id, true)
        } else {
            swal('Cảnh báo!', "Vui lòng nhập số định danh để kiểm tra", "warning");
        }

    }

  
    getClosetCurrentTime = (list) => {
        var curDate = new Date();
        curDate = curDate.toDateString();
    
        var times = list.map((t) => {
          return new Date(curDate + " " + t.start_time);
        });
        var now = new Date();
        var min = Math.abs(now - times[0]);
    
        var result = '';
        for(let i = 0; i < times.length; i++) {
          if (Math.abs(now - times[i]) <= min) {
              min = Math.abs(now - times[i]);
              result = list[i];
           }
        }
        return result
    }


    getAppointment = (id, buttonCheck) => {
        if (id) {
            const id_company = this.state.crrCompany ? this.state.crrCompany.value : 0;
            fetch(`${HOST_EKYC}/api/appointment_schedule/get_by_staff`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': store.getState().isLogin.access_token
                },
                body: JSON.stringify({
                    id: id,
                    id_company: id_company,
                    start_date: Moment(new Date()).format('YYYY-MM-DD'),
                    end_date: Moment(new Date()).format('YYYY-MM-DD'),
                })
            })
                .then(response => {
                    return response.json()
                }).then(data => {
                    if (data.status == 10000) {
                        if (data.data.schedules.length > 0 || buttonCheck) {
                            if (!buttonCheck) {
                            var listClosetTime 
                            if (data.data.schedules.length === 1) {
                                listClosetTime = data.data.schedules[0]
                            } else {
                                listClosetTime = this.getClosetCurrentTime(data.data.schedules)
                            }
                            this.setState({
                                disableAppointment: true,
                                dataAppointment: {
                                    ...this.state.dataAppointment,
                                    guardian: listClosetTime.guardian,
                                    start_time: listClosetTime.start_time,
                                    end_time: listClosetTime.end_time,
                                    obj_department: listClosetTime && Object.keys(listClosetTime.department).length !== 0 ? listClosetTime.department._id : "",
                                    description: listClosetTime.description,
                                    ids_staff: listClosetTime.ids_staff,
                                    start_date: listClosetTime.start_date,
                                    end_date: listClosetTime.end_date,
                                    ids_camera: listClosetTime.ids_camera,
                                    },
                                    start_time: new Date().setHours(listClosetTime.start_time.split(':')[0], listClosetTime.start_time.split(':')[1]),
                                    end_time: new Date().setHours(listClosetTime.end_time.split(':')[0], listClosetTime.end_time.split(':')[1]),
                                    crrFloorDepartment: Object.keys(listClosetTime.department).length !== 0 ? {value: listClosetTime.department._id, label: "Tầng " + listClosetTime.department.floor + " - "  + listClosetTime.department.name} : null,
                            });
                            } else {
                            this.setState({
                                dataSchedules: data.data.schedules,
                                }, () => {
                                Swal.fire({
                                    title: `Lịch hẹn ngày:&nbsp;<b>${Moment(new Date()).format('DD/MM/YYYY')}</b>`,
                                    html:
                                        `<div>
                                                <div style="font-size:15; line-height: 1.5; text-align: left; padding: 10px 0; border-top: 1px solid #f2f2f2; border-bottom: 1px solid #f2f2f2; margin-bottom: 10px">
                                                Họ tên: <b>${this.state.dataSchedules && this.state.dataSchedules.history ? this.state.dataSchedules[0].history.name.replace(/</g, "").replace(/>/g, "") : this.state.dataFront.name}</b>
                                                </div>
                                                ${this.state.dataSchedules.length > 0 ?
                                            `${this.state.dataSchedules.map(map => `
                                                    <div>
                                                        <div style="font-size:15; line-height: 1.5; display: flex; flexDirection: row">
                                                   <div>
                                                   <strong>${map.start_time.replace(/</g, "").replace(/>/g, "")} - ${map.end_time.replace(/</g, "").replace(/>/g, "")}</strong>
                                                   </div>

                                                    <div style="display: flex; flex-direction: column; text-align: left; padding-left: 3px">
                                                        <div>  ${map.guardian ? "Gặp " + map.guardian.replace(/</g, "").replace(/>/g, "") : ""} ${Object.keys(map.department).length !== 0 ? "(Tầng " + map.department.floor.replace(/</g, "").replace(/>/g, "") + " - " + map.department.name.replace(/</g, "").replace(/>/g, "") + ")" : ""}</div>
                                                        <div> ${map.description.replace(/</g, "").replace(/>/g, "")}</div>
                                                    </div>

                                                   </div>
                                                   </div>
                                                   `).join("")}` :
                                            `<div style="font-size: 16px; margin-top: 20px">Không có lịch hẹn...</div>`}
                                            </div>`,
                                    showCancelButton: true,
                                    showConfirmButton: false,
                                    cancelButtonText: 'Đóng',
                                    customClass: 'swal-wide',
                                })
                            })
                        }}
                    } else if (data.status === 10003 && buttonCheck) {
                        swal({
                            title: "Không có dữ liệu khách hàng!",
                            icon: "error",
                            buttons: {
                                confirm: {
                                    text: 'Đóng',
                                    value: null,
                                    visible: true,
                                    closeModal: true,
                                },
                            },
                            dangerMode: false,
                        })
                    } else {
                        if (buttonCheck) {
                            swal({
                                title: "Lấy lịch hẹn thất bại!",
                                icon: "error",
                                buttons: {
                                    confirm: {
                                        text: 'Đóng',
                                        value: null,
                                        visible: true,
                                        closeModal: true,
                                    },
                                },
                                dangerMode: false,
                            })
                        }
                    }
                }).catch((error) => {
                    console.error("error", error);
                    if (buttonCheck) {
                    swal({
                        title: "Lấy lịch hẹn thất bại, vui lòng kiểm tra kết nối rồi thử lại!",
                        icon: "error",
                        buttons: {
                            confirm: {
                                text: 'Đóng',
                                value: null,
                                visible: true,
                                closeModal: true,
                            },
                        },
                        dangerMode: false,
                    })
                }
                });

        }
    }

    onChangeFace = (files) => {
        //face
        const id_company = this.state.crrCompany ? this.state.crrCompany.value : '';
        this.setState({
            loadingImageFace: true,
            imageFace: '',
            path_face: '',
            percent: 0,
            phone_number: "",
            work_unit: "",
            crrFloorDepartment: null,
            disableAppointment: false,
            dataAppointment: {...dataAppointmentDefault, 
                ids_camera: this.state.dataAppointment.ids_camera,
                start_time: Moment().format("HH:mm"),
                end_time: Moment().set({hour: 18,minute: 0,second: 0}).format("HH:mm")
            },
            start_time: new Date(),
            end_time: new Date().setHours(18, 0, 0),
        }, async () => {
            var { dataBitable } = this.state;
            var checktimeout = false
            if (dataBitable.name !== "") {
                if (dataBitable.url != "") {
                    const timeout = 10000;
                    const controller = new AbortController();
                    const signal = controller.signal;

                    setTimeoutGetFace = setTimeout(() => {
                        controller.abort();
                        this.setState({
                            imageFace: '',
                            loadingImageFace: false
                        }, () => {
                            swal({
                                title: "Đọc dữ liệu thất bại, kiểm tra lại kết nối rồi thử lại!",
                                icon: "error",
                                buttons: {
                                    confirm: {
                                        text: 'Đóng',
                                        value: null,
                                        visible: true,
                                        closeModal: true,
                                    },
                                },
                                dangerMode: false,
                            })
                        });
                        checktimeout = true;
                    }, timeout);

                    await fetch(`${window.location.protocol}//${dataBitable.url}:8096/bitable?option=2&timeout=4`, {
                        method: 'GET',
                        signal: signal,
                    }).then((response) => {
                        return response.json()
                    }).then((data) => {
                        clearTimeout(setTimeoutGetFace)
                        if (data.code === '1') {
                            var { imageFace, dataSave } = this.state;
                            imageFace = data.data[0].images[0].base64.split(',')[0]
                            dataSave.image_face = imageFace
                            this.setState({
                                imageFace,
                                dataSave,
                                loadingImageFace: false
                            }, () => {
                                // if (this.state.dataFront.base64 === "" && this.state.dataBack.base64 === "") {
                                fetch(`${HOST_EKYC}/api/reva/recognize_customer`, {
                                    method: 'POST',
                                    headers: {
                                        "Content-Type": "application/json",
                                        'Authorization': store.getState().isLogin.access_token
                                    },
                                    body: JSON.stringify({
                                        id_number: "",
                                        image_base64: imageFace,
                                        id_camera: this.state.crrBox ? this.state.crrBox.id_box_engine : this.state.id_box_engine,
                                        id_company: id_company
                                    })
                                }).then((response) => {
                                    return response.json()
                                }).then((data) => {
                                    var { dataFront, dataBack, dataSave, phone_number, work_unit } = this.state
                                    if (data.status === 10000) {
                                        if (data.data !== null) {
                                            //dataFront
                                            dataFront.id = data.data.id
                                            dataFront.name = data.data.name
                                            dataFront.birthday = data.data.birthday
                                            dataFront.sex = data.data.sex
                                            dataFront.expiry = data.data.expiry
                                            dataFront.address = data.data.address
                                            dataFront.national = data.data.national
                                            //dataBack
                                            dataBack.issue_by = data.data.issue_by
                                            dataBack.issue_date = data.data.issue_date
                                            //dataSave
                                            dataSave.path_front =  dataFront.base64 === "" ? data.data.url_front : ""
                                            dataSave.path_back = data.data.url_back
                                            dataSave.address = data.data.address
                                            dataSave.ethnicity = data.data.ethnicity
                                            dataSave.name = data.data.name
                                            dataSave.national = data.data.national
                                            dataSave.religion = data.data.religion
                                            dataSave.id = data.data.id
                                            dataSave.birthday = data.data.birthday
                                            dataSave.origin = data.data.origin
                                            dataSave.sex = data.data.sex
                                            dataSave.expiry = data.data.expiry
                                            dataSave.class = data.data.class
                                            dataSave.date = Moment(new Date()).format('DD/MM/YYYY HH:mm:ss')
                                            dataSave.time = Moment(new Date()).format('HH:mm:ss')
                                            dataSave.save_percent = data.data.save_percent
                                            //info other
                                            phone_number = data.data.phone_number
                                            work_unit = data.data.work_unit
                                            this.setState({
                                                // path_front: dataFront.base64 === "" ? data.data.url_front : "",
                                                // path_back: data.data.url_back,
                                                // path_face: data.data.url_face,
                                                dataFront,
                                                dataBack,
                                                dataSave,
                                                dataSchedules: data.data.schedules ? data.data.schedules : [],
                                                phone_number,
                                                work_unit
                                            }, () => {
                                                this.VerificationFace();
                                                this.getAppointment(this.state.dataFront.id)
                                            });
                                        }
                                    } else if (data.status === 10004) {
                                        this.setState({
                                            path_face: "",
                                            imageFace: '',
                                            loadingImageFace: false
                                        }, () => {
                                            swal({
                                                title: "Khuôn mặt không hợp lệ, yêu cầu nhìn thẳng và chụp lại khuôn mặt.",
                                                icon: "error",
                                                buttons: {
                                                    confirm: {
                                                        text: 'Đóng',
                                                        value: null,
                                                        visible: true,
                                                        closeModal: true,
                                                    },
                                                },
                                                dangerMode: false,
                                            })
                                        });
                                    }
                                })
                                // }
                                this.VerificationFace();
                            });
                            return data;
                        } else {
                            if (checktimeout === false) {
                                this.setState({
                                    imageFace: '',
                                    loadingImageFace: false
                                }, () => {
                                    swal({
                                        title: "Lấy ảnh thất bại, vui lòng lấy lại ảnh!",
                                        icon: "error",
                                        buttons: {
                                            confirm: {
                                                text: 'Đóng',
                                                value: null,
                                                visible: true,
                                                closeModal: true,
                                            },
                                        },
                                        dangerMode: false,
                                    })
                                });
                            }
                            return data;
                        }

                    }).catch((error) => {
                        if (error) {
                            clearTimeout(setTimeoutGetFace)
                            if (checktimeout === false) {
                                this.setState({
                                    imageFace: '',
                                    loadingImageFace: false
                                }, () => {
                                    swal({
                                        title: "Đọc dữ liệu thất bại, kiểm tra lại kết nối rồi thử lại!",
                                        icon: "error",
                                        buttons: {
                                            confirm: {
                                                text: 'Đóng',
                                                value: null,
                                                visible: true,
                                                closeModal: true,
                                            },
                                        },
                                        dangerMode: false,
                                    })
                                });
                            }
                        }
                    });
                } else {
                    this.setState({
                        imageFace: '',
                        loadingImageFace: false
                    }, () => {
                        swal({
                            title: "Đọc dữ liệu thất bại, kiểm tra lại kết nối rồi thử lại!",
                            icon: "error",
                            buttons: {
                                confirm: {
                                    text: 'Đóng',
                                    value: null,
                                    visible: true,
                                    closeModal: true,
                                },
                            },
                            dangerMode: false,
                        })
                    });
                }
            } else {
                this.setState({
                    imageFace: '',
                    loadingImageFace: false
                }, () => {
                    swal({
                        title: "Kiểm tra lại kết nối Bitable rồi thử lại!",
                        icon: "warning",
                        buttons: {
                            confirm: {
                                text: 'Đóng',
                                value: null,
                                visible: true,
                                closeModal: true,
                            },
                        },
                        dangerMode: false,
                    })
                });
            }
        });
    }

    onFilesChange1 = (files) => {
        //front
        const id_company = this.state.crrCompany ? this.state.crrCompany.value : '';
        this.resetForm()
        this.setState({
            loadingImage1: true,
            path_front: "",
            dataFront: {
                ...dataFrontDefault
            },
            phone_number: "",
            work_unit: "",
            dataAppointment: {...dataAppointmentDefault, 
                ids_camera: this.state.dataAppointment.ids_camera,
                start_time: Moment().format("HH:mm"),
                end_time: Moment().set({hour: 18,minute: 0,second: 0}).format("HH:mm")
            },
            start_time: new Date(),
            end_time: new Date().setHours(18, 0, 0),
            crrFloorDepartment: null,
            disableAppointment: false,
        }, async () => {
            var { dataBitable } = this.state;
            var checktimeout = false
            if (dataBitable.name !== "") {
                if (dataBitable.url != "") {
                    const timeout = 10000;
                    const controller = new AbortController();
                    const signal = controller.signal;

                    setTimeoutCMTFront = setTimeout(() => {
                        controller.abort();
                        this.setState({
                            dataFront: {
                                ...dataFrontDefault
                            },
                            loadingImage1: false
                        }, () => {
                            swal({
                                title: "Đọc dữ liệu thất bại, kiểm tra lại kết nối rồi thử lại!",
                                icon: "error",
                                buttons: {
                                    confirm: {
                                        text: 'Đóng',
                                        value: null,
                                        visible: true,
                                        closeModal: true,
                                    },
                                },
                                dangerMode: false,
                            })
                        });
                        checktimeout = true;
                    }, timeout);
                    console.log('logg', `${window.location.protocol}//${dataBitable.url}:8096/bitable?option=3&timeout=4`);
                    var image = await fetch(`${window.location.protocol}//${dataBitable.url}:8096/bitable?option=3&timeout=4`, {
                        method: 'GET',
                        signal: signal,
                    }).then((response) => {
                        return response.json()
                    }).then((data) => {
                        clearTimeout(setTimeoutCMTFront)
                        var { dataFront } = this.state;
                        dataFront.base64 = data.data[0].images[0].base64
                        this.setState({
                            dataFront,
                            percent: 0,
                        });
                        return data;
                    }).catch((error) => {
                        if (error) {
                            clearTimeout(setTimeoutCMTFront)
                            if (checktimeout === false) {
                                this.setState({
                                    dataFront: {
                                        ...dataFrontDefault
                                    },
                                    loadingImage1: false
                                }, () => {
                                    swal({
                                        title: "Kiểm tra lại kết nối Bitable rồi thử lại!",
                                        icon: "warning",
                                        buttons: {
                                            confirm: {
                                                text: 'Đóng',
                                                value: null,
                                                visible: true,
                                                closeModal: true,
                                            },
                                        },
                                        dangerMode: false,
                                    })
                                });
                            }
                        }
                    });
                    if (checktimeout === false) {
                        if (image !== undefined) {
                            if (image.code == "401") {
                                this.setState({
                                    dataFront: {
                                        ...dataFrontDefault
                                    },
                                    percent: 0,
                                    loadingImage1: false
                                }, () => {
                                    swal({
                                        title: image.error,
                                        icon: "error",
                                        buttons: {
                                            confirm: {
                                                text: 'Đóng',
                                                value: null,
                                                visible: true,
                                                closeModal: true,
                                            },
                                        },
                                        dangerMode: false,
                                    })
                                });
                            } else {
                                fetch(`${HOST_EKYC}/api/ekyc/recog`, {
                                    // fetch(`${HOST_VERIFI}/v3.1/ocr/recognition`, {
                                    method: 'POST',
                                    headers: {
                                        'key': 'YW5kZXB0cmFpaGloaQ==',
                                        'Content-Type': 'application/json',
                                        'Authorization': store.getState().isLogin.access_token
                                    },
                                    body: JSON.stringify({
                                        'image': image.data[0].images[0].base64,
                                        "customer_id": this.state.crrBox ? this.state.crrBox.id_box_engine : this.state.id_box_engine,
                                    })
                                }).then((response) => {
                                    return response.json()
                                }).then((data) => {
                                    if (data.result_code === 200) {
                                        var { dataFront, dataSave, dataBack } = this.state
                                        if (data.id_type === "0" || data.document === "PASSPORT" || data.document === "DRIVER LICENSE" || data.id_type === "") {
                                            //dataFront
                                            dataFront.id = (data.id != 'N/A') ? data.id : ""
                                            dataFront.name = (data.name != 'N/A') ? data.name : ""
                                            dataFront.birthday = (data.birthday != 'N/A') ? data.birthday : ""
                                            dataFront.sex = (data.sex != 'N/A') ? data.sex : ""
                                            dataFront.expiry = (data.expiry != 'N/A') ? data.expiry : ""
                                            dataFront.origin = (data.origin != 'N/A') ? data.origin : ""
                                            dataFront.address = (data.address != 'N/A') ? data.address : ""
                                            dataFront.national = (data.national != 'N/A') ? data.national : ""
                                            dataFront.class = (data.class != 'N/A') ? data.class : ""
                                            if (data.document === "PASSPORT" || data.document === "DRIVER LICENSE") {
                                                dataFront.country = (data.country != 'N/A') ? data.country : ""
                                                dataFront.national = (data.national != 'N/A') ? data.national : ""
                                                dataFront.class = (data.class != 'N/A') ? data.class : ""
                                                dataBack.issue_by = (data.issue_by != 'N/A') ? data.issue_by : ""
                                                dataBack.issue_date = (data.issue_date != 'N/A') ? data.issue_date : ""
                                                dataSave.issue_by = (data.issue_by != 'N/A') ? data.issue_by : ""
                                                dataSave.issue_date = (data.issue_date != 'N/A') ? data.issue_date : ""
                                            }
                                            //dataSave
                                            dataSave.image_front = image.data[0].images[0].base64
                                            dataSave.address = (data.address != 'N/A') ? data.address : ""
                                            dataSave.country = (data.country != 'N/A') ? data.country : ""
                                            dataSave.ethnicity = (data.ethnicity != 'N/A') ? data.ethnicity : ""
                                            dataSave.name = (data.name != 'N/A') ? data.name : ""
                                            dataSave.national = (data.national != 'N/A') ? data.national : ""
                                            dataSave.religion = (data.religion != 'N/A') ? data.religion : ""
                                            dataSave.id = (data.id != 'N/A') ? data.id : ""
                                            dataSave.birthday = (data.birthday != 'N/A') ? data.birthday : ""
                                            dataSave.origin = (data.origin != 'N/A') ? data.origin : ""
                                            dataSave.sex = (data.sex != 'N/A') ? data.sex : ""
                                            dataSave.expiry = (data.expiry != 'N/A') ? data.expiry : ""
                                            dataSave.class = (data.class != 'N/A') ? data.class : ""
                                            dataSave.date = Moment(new Date()).format('DD/MM/YYYY HH:mm:ss')
                                            dataSave.time = Moment(new Date()).format('HH:mm:ss')
                                            this.setState({
                                                dataSave,
                                                dataFront,
                                                loadingImage1: false
                                            }, () => {
                                                fetch(`${HOST_EKYC}/api/reva/recognize_customer`, {
                                                    method: 'POST',
                                                    headers: {
                                                        "Content-Type": "application/json",
                                                        'Authorization': store.getState().isLogin.access_token
                                                    },
                                                    body: JSON.stringify({
                                                        id_camera: this.state.crrBox ? this.state.crrBox.id_box_engine : this.state.id_box_engine,
                                                        id_number: data.id != 'N/A' ? data.id : "",
                                                        image_base64: "",
                                                        id_company: id_company
                                                    })
                                                }).then((response) => {
                                                    return response.json()
                                                }).then((data) => {
                                                    var { dataFront, dataBack, dataSave, phone_number, work_unit } = this.state
                                                    if (data.status === 10000) {
                                                        if (data.data !== null) {
                                                            //dataFront
                                                            dataFront.id = data.data.id
                                                            dataFront.name = data.data.name
                                                            dataFront.birthday = data.data.birthday
                                                            dataFront.sex = data.data.sex
                                                            dataFront.expiry = data.data.expiry
                                                            dataFront.address = data.data.address
                                                            dataFront.national = data.data.national
                                                            //dataBack
                                                            dataBack.issue_by = data.data.issue_by
                                                            dataBack.issue_date = data.data.issue_date
                                                            //dataSave
                                                            // dataSave.path_front = data.data.url_front
                                                            dataSave.path_back = data.data.url_back
                                                            dataSave.address = data.data.address
                                                            dataSave.ethnicity = data.data.ethnicity
                                                            dataSave.name = data.data.name
                                                            dataSave.national = data.data.national
                                                            dataSave.religion = data.data.religion
                                                            dataSave.id = data.data.id
                                                            dataSave.birthday = data.data.birthday
                                                            dataSave.origin = data.data.origin
                                                            dataSave.sex = data.data.sex
                                                            dataSave.expiry = data.data.expiry
                                                            dataSave.class = data.data.class
                                                            dataSave.date = Moment(new Date()).format('DD/MM/YYYY HH:mm:ss')
                                                            dataSave.time = Moment(new Date()).format('HH:mm:ss')
                                                            dataSave.save_percent = data.data.save_percent
                                                            //info other
                                                            phone_number = data.data.phone_number
                                                            work_unit = data.data.work_unit
                                                            this.setState({
                                                                // path_front: data.data.url_front,
                                                                // path_back: data.data.url_back,
                                                                // path_face: data.data.url_face,
                                                                dataFront,
                                                                dataBack,
                                                                dataSave,
                                                                dataSchedules: data.data.schedules ? data.data.schedules : [],
                                                                phone_number,
                                                                work_unit
                                                            });
                                                        }
                                                    } 
                                                })
                                                this.getAppointment(this.state.dataFront.id)
                                                this.VerificationFace()
                                            });
                                        } else {
                                            dataSave.image_front = image.data[0].base64
                                            this.setState({
                                                dataFront,
                                                loadingImage1: false
                                            }, () => {
                                                this.VerificationFace()
                                            });
                                        }
                                    } else {
                                        this.setState({
                                            loadingImage1: false
                                        }, () => {
                                            swal({
                                                title: "Đọc dữ liệu thất bại, vui lòng kiểm tra lại!",
                                                icon: "error",
                                                buttons: {
                                                    confirm: {
                                                        text: 'Đóng',
                                                        value: null,
                                                        visible: true,
                                                        closeModal: true,
                                                    },
                                                },
                                                dangerMode: false,
                                            })
                                        });
                                    }
                                }).catch((error) => {
                                    if (error) {
                                        this.setState({
                                            dataFront: {
                                                ...dataFrontDefault
                                            },
                                            loadingImage1: false
                                        }, () => {
                                            swal({
                                                title: "Đọc dữ liệu thất bại, kiểm tra lại kết nối rồi thử lại!",
                                                icon: "error",
                                                buttons: {
                                                    confirm: {
                                                        text: 'Đóng',
                                                        value: null,
                                                        visible: true,
                                                        closeModal: true,
                                                    },
                                                },
                                                dangerMode: false,
                                            })
                                        });
                                    }
                                })
                            }
                        } else {
                            this.setState({
                                dataFront: {
                                    ...dataFrontDefault
                                },
                                loadingImage1: false
                            }, () => {
                                swal({
                                    title: "Đọc dữ liệu thất bại, kiểm tra lại kết nối rồi thử lại!",
                                    icon: "error",
                                    buttons: {
                                        confirm: {
                                            text: 'Đóng',
                                            value: null,
                                            visible: true,
                                            closeModal: true,
                                        },
                                    },
                                    dangerMode: false,
                                })
                            });
                        }
                    }
                } else {
                    this.setState({
                        dataFront: {
                            ...dataFrontDefault
                        },
                        loadingImage1: false
                    }, () => {
                        swal({
                            title: "Đọc dữ liệu thất bại, kiểm tra lại kết nối rồi thử lại!",
                            icon: "error",
                            buttons: {
                                confirm: {
                                    text: 'Đóng',
                                    value: null,
                                    visible: true,
                                    closeModal: true,
                                },
                            },
                            dangerMode: false,
                        })
                    });
                }
            } else {
                this.setState({
                    dataFront: {
                        ...dataFrontDefault
                    },
                    loadingImage1: false
                }, () => {
                    swal({
                        title: "Kiểm tra lại kết nối Bitable rồi thử lại!",
                        icon: "warning",
                        buttons: {
                            confirm: {
                                text: 'Đóng',
                                value: null,
                                visible: true,
                                closeModal: true,
                            },
                        },
                        dangerMode: false,
                    })
                });
            }
        });
    }

    onFilesChange2 = (files) => {
        //back
        this.setState({
            dataBack: {
                ...dataBackDefault
            },
            path_back: "",
            loadingImage2: true
        }, async () => {
            var { dataBitable } = this.state;
            var checktimeout = false
            if (dataBitable.name !== "") {
                if (dataBitable.url != "") {
                    const timeout = 10000;
                    const controller = new AbortController();
                    const signal = controller.signal;

                    setTimeoutCMTBack = setTimeout(() => {
                        controller.abort();
                        this.setState({
                            dataBack: {
                                ...dataBackDefault
                            },
                            loadingImage2: false
                        }, () => {
                            swal({
                                title: "Đọc dữ liệu thất bại, kiểm tra lại kết nối rồi thử lại!",
                                icon: "error",
                                buttons: {
                                    confirm: {
                                        text: 'Đóng',
                                        value: null,
                                        visible: true,
                                        closeModal: true,
                                    },
                                },
                                dangerMode: false,
                            })
                        });
                        checktimeout = true;
                    }, timeout);
                    var image = await fetch(`${window.location.protocol}//${dataBitable.url}:8096/bitable?option=4&timeout=4`, {
                        method: 'GET',
                        signal: signal,
                    }).then((response) => {
                        return response.json()
                    }).then((data) => {
                        clearTimeout(setTimeoutCMTBack)
                        var { dataBack } = this.state;
                        dataBack.base64 = data.data[0].images[0].base64;
                        this.setState({
                            dataBack,
                        });
                        return data;
                    }).catch((error) => {
                        if (error) {
                            clearTimeout(setTimeoutCMTBack)
                            if (checktimeout === false) {
                                this.setState({
                                    dataBack: {
                                        ...dataBackDefault
                                    },
                                    loadingImage2: false
                                }, () => {
                                    swal({
                                        title: "Đọc dữ liệu thất bại, kiểm tra lại kết nối rồi thử lại!",
                                        icon: "error",
                                        buttons: {
                                            confirm: {
                                                text: 'Đóng',
                                                value: null,
                                                visible: true,
                                                closeModal: true,
                                            },
                                        },
                                        dangerMode: false,
                                    })
                                });
                            }
                        }
                    });
                    if (checktimeout === false) {
                        if (image !== undefined) {
                            if (image.code == "401") {
                                this.setState({
                                    dataBack: {
                                        ...dataBackDefault
                                    },
                                    loadingImage2: false
                                }, () => {
                                    swal({
                                        title: image.error,
                                        icon: "error",
                                        buttons: {
                                            confirm: {
                                                text: 'Đóng',
                                                value: null,
                                                visible: true,
                                                closeModal: true,
                                            },
                                        },
                                        dangerMode: false,
                                    })
                                });
                            } else {
                                fetch(`${HOST_EKYC}/api/ekyc/recog`, {
                                    // fetch(`${HOST_VERIFI}/v3.1/ocr/recognition`, {
                                    method: 'POST',
                                    headers: {
                                        'key': 'YW5kZXB0cmFpaGloaQ==',
                                        'Content-Type': 'application/json',
                                        'Authorization': store.getState().isLogin.access_token
                                    },
                                    body: JSON.stringify({
                                        'image': image.data[0].images[0].base64,
                                        "customer_id": this.state.crrBox ? this.state.crrBox.id_box_engine : this.state.id_box_engine,
                                    })
                                }).then((response) => {
                                    return response.json()
                                }).then((data) => {
                                    if (data.result_code === 200) {
                                        var { dataBack, dataSave, dataFront } = this.state
                                        if (data.id_type === "1" || data.id_type === "") {
                                            //dataBack
                                            dataBack.issue_by = (data.issue_by != 'N/A') ? data.issue_by : ""
                                            dataBack.issue_date = (data.issue_date != 'N/A') ? data.issue_date : ""
                                            //dataSave
                                            dataSave.image_back = image.data[0].images[0].base64
                                            dataSave.issue_by = (data.issue_by != 'N/A') ? data.issue_by : ""
                                            dataSave.issue_date = (data.issue_date != 'N/A') ? data.issue_date : ""
                                            this.setState({
                                                dataSave,
                                                dataBack,
                                                loadingImage2: false
                                            });
                                        }
                                        else if (data.document === "PASSPORT" || data.document === "DRIVER LICENSE") {
                                            //dataFront
                                            dataFront.id = (data.id != 'N/A') ? data.id : ""
                                            dataFront.name = (data.name != 'N/A') ? data.name : ""
                                            dataFront.birthday = (data.birthday != 'N/A') ? data.birthday : ""
                                            dataFront.sex = (data.sex != 'N/A') ? data.sex : ""
                                            dataFront.expiry = (data.expiry != 'N/A') ? data.expiry : ""
                                            dataFront.origin = (data.origin != 'N/A') ? data.origin : ""
                                            dataFront.address = (data.address != 'N/A') ? data.address : ""
                                            if (data.document === "PASSPORT" || data.document === "DRIVER LICENSE") {
                                                dataFront.country = (data.country != 'N/A') ? data.country : ""
                                                dataFront.national = (data.national != 'N/A') ? data.national : ""
                                                dataFront.class = (data.class != 'N/A') ? data.class : ""
                                                dataBack.issue_by = (data.issue_by != 'N/A') ? data.issue_by : ""
                                                dataBack.issue_date = (data.issue_date != 'N/A') ? data.issue_date : ""
                                                dataSave.issue_by = (data.issue_by != 'N/A') ? data.issue_by : ""
                                                dataSave.issue_date = (data.issue_date != 'N/A') ? data.issue_date : ""
                                            }
                                            //dataSave
                                            dataSave.image_front = image.data[0].images[0].base64
                                            dataSave.address = (data.address != 'N/A') ? data.address : ""
                                            dataSave.country = (data.country != 'N/A') ? data.country : ""
                                            dataSave.ethnicity = (data.ethnicity != 'N/A') ? data.ethnicity : ""
                                            dataSave.name = (data.name != 'N/A') ? data.name : ""
                                            dataSave.national = (data.national != 'N/A') ? data.national : ""
                                            dataSave.religion = (data.religion != 'N/A') ? data.religion : ""
                                            dataSave.id = (data.id != 'N/A') ? data.id : ""
                                            dataSave.birthday = (data.birthday != 'N/A') ? data.birthday : ""
                                            dataSave.origin = (data.origin != 'N/A') ? data.origin : ""
                                            dataSave.sex = (data.sex != 'N/A') ? data.sex : ""
                                            dataSave.expiry = (data.expiry != 'N/A') ? data.expiry : ""
                                            dataSave.class = (data.class != 'N/A') ? data.class : ""
                                            this.setState({
                                                dataFront,
                                                dataSave,
                                                dataBack,
                                                loadingImage2: false
                                            });
                                        }
                                        else {
                                            this.setState({
                                                loadingImage2: false
                                            }, () => {
                                                swal({
                                                    title: "Đọc dữ liệu thất bại, vui lòng kiểm tra lại!",
                                                    icon: "error",
                                                    buttons: {
                                                        confirm: {
                                                            text: 'Đóng',
                                                            value: null,
                                                            visible: true,
                                                            closeModal: true,
                                                        },
                                                    },
                                                    dangerMode: false,
                                                })
                                            });
                                        }

                                    } else {
                                        this.setState({
                                            loadingImage2: false
                                        }, () => {
                                            swal({
                                                title: "Đọc dữ liệu thất bại, vui lòng kiểm tra lại!",
                                                icon: "error",
                                                buttons: {
                                                    confirm: {
                                                        text: 'Đóng',
                                                        value: null,
                                                        visible: true,
                                                        closeModal: true,
                                                    },
                                                },
                                                dangerMode: false,
                                            })
                                        });
                                    }
                                }).catch((error) => {
                                    if (error) {
                                        this.setState({
                                            dataBack: {
                                                ...dataBackDefault
                                            },
                                            loadingImage2: false
                                        }, () => {
                                            swal({
                                                title: "Đọc dữ liệu thất bại, kiểm tra lại kết nối rồi thử lại!",
                                                icon: "error",
                                                buttons: {
                                                    confirm: {
                                                        text: 'Đóng',
                                                        value: null,
                                                        visible: true,
                                                        closeModal: true,
                                                    },
                                                },
                                                dangerMode: false,
                                            })
                                        });
                                    }
                                })
                            }
                        } else {
                            this.setState({
                                dataBack: {
                                    ...dataBackDefault
                                },
                                loadingImage2: false
                            }, () => {
                                swal({
                                    title: "Đọc dữ liệu thất bại, vui lòng kiểm tra lại!",
                                    icon: "error",
                                    buttons: {
                                        confirm: {
                                            text: 'Đóng',
                                            value: null,
                                            visible: true,
                                            closeModal: true,
                                        },
                                    },
                                    dangerMode: false,
                                })
                            });
                        }
                    }

                } else {
                    this.setState({
                        dataBack: {
                            ...dataBackDefault
                        },
                        loadingImage2: false
                    }, () => {
                        swal({
                            title: "Kiểm tra lại kết nối Bitable rồi thử lại!",
                            icon: "warning",
                            buttons: {
                                confirm: {
                                    text: 'Đóng',
                                    value: null,
                                    visible: true,
                                    closeModal: true,
                                },
                            },
                            dangerMode: false,
                        })
                    });
                }
            } else {
                this.setState({
                    dataBack: {
                        ...dataBackDefault
                    },
                    loadingImage2: false
                }, () => {
                    swal({
                        title: "Kiểm tra lại kết nối Bitable rồi thử lại!",
                        icon: "warning",
                        buttons: {
                            confirm: {
                                text: 'Đóng',
                                value: null,
                                visible: true,
                                closeModal: true,
                            },
                        },
                        dangerMode: false,
                    })
                });
            }
        });
    }

    Stream = () => {
        this.setState({
            flag_socket: 1,
        })

        if (!this.state.address) {
            setTimeoutSocket = setTimeout(() => {
                swal({
                    title: "Cập nhật thất bại, Thiết bị Bitable chưa được kết nối!",
                    icon: "warning",
                    buttons: {
                        confirm: {
                            text: 'Đóng',
                            value: null,
                            visible: true,
                            closeModal: true,
                        },
                    },
                    dangerMode: false,
                })
                this.setState({
                    flag_socket: -1,
                    disabled_bitable: false,
                }, () => {
                    this.socketDisconnect();
                });
            }, 5000);
        }
        var { dataBitable } = this.state;
        this.checkSocket(this.socket_face, 'face');
        this.checkSocket(this.socket_cmt, 'cmt');

        // if(dataBitable.type_connect == 'socket')
        // {
        //     this.checkSocket();
        // }
        // else
        // {
        //     clearInterval(IntervalStream);
        //     // IntervalStream = setInterval(this.DDos, 100);
        //     this.DDos();
        // }
    }
    changeHandleBox = (e, value) => {
        this.setState({
            crrBox: value,
            dataBitable: {
                ...this.state.dataBitable,
                name: `BITABLE_${value.id_box_engine}`
            }
        }, () => {
            fetch(`${HOST_BITABLE_CONNECT}/get-ip-box?id=${this.state.dataBitable.name}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            }).then((response) => {
                return response.json()
            }).then((data) => {
                try {
                    if (data.error) {
                        return swal({
                            title: "Không có dữ liệu Bitable!",
                            icon: "warning",
                            buttons: {
                                confirm: {
                                    text: 'Đóng',
                                    value: null,
                                    visible: true,
                                    closeModal: true,
                                },
                            },
                            dangerMode: false,
                        })
                    }
                    let list_IP = [];
                    if (data.lan) {
                        list_IP.push({ title: `lan: ${data.lan}`, value: data.lan_url, url: data.lan })
                    }
                    if (data.wifi) {
                        list_IP.push({ title: `wifi: ${data.wifi}`, value: data.wifi_url, url: data.wifi })
                    }
                    this.setState({
                        list_IP: list_IP,
                        crrIP: list_IP.length > 0 ? list_IP[0] : null,
                    }, () => {
                        this.setState({
                            dataBitable: {
                                ...this.state.dataBitable,
                                url: this.state.crrIP ? this.state.crrIP.url : '',
                                stream_socket: this.state.crrIP ? `${this.state.crrIP.value}${window.location.protocol === "https:" ? ":8096" : ":3000"}` : '',
                            }
                        })
                    })
                } catch (error) {
                    console.log(error)

                }
            })
        })
    }

    changeHandleIP = (e, value) => {
        this.setState({
            crrIP: value,
            dataBitable: {
                ...this.state.dataBitable,
                url: value.url,
                stream_socket: `${value.value}${window.location.protocol === "https:" ? ":8096" : ":3000"}`
            }
        })
    }

    changeHandleSelect = (e, value) => {
        this.setState({
            crrOption: value,
            address: value.value === 1 ? false : true,
            name_device: "",
            crrBox: null,
            crrIP: null,
            dataBitable: { ...this.state.dataBitable, url: "" },
        })
        localStorage.setItem("status", !this.state.address);
    }
    getListCamera = () => {
        const id_company = this.state.crrCompany ? this.state.crrCompany.value : '';
        if (!id_company) return;
        fetch(`${HOST_EKYC}/api/config/${id_company}/get_by_company`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': store.getState().isLogin.access_token,
            },
        })
            .then(res => res.json())
            .then(data => {
                if (data.data) {
                    let cams = data.data.cameras.map(map => ({ label: map.alias_camera + ' (' + map.id_camera + ')', value: map.id_camera }))
                    const ids_camera = cams.map(map => map.value)
                    this.setState({
                        listCamConfig: cams,
                        dataAppointment: {...this.state.dataAppointment, ids_camera: ids_camera}
                    })
                }
            }).catch(err => {
                console.log('error', err);
            })

    }

    getDeparment = () => {
        const id_company = this.state.crrCompany ? this.state.crrCompany.value : '';
        if (!id_company) return;
        fetch(`${HOST_EKYC}/api/department/get_by_company/${id_company}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': store.getState().isLogin.access_token,
            },
        })
            .then(response => {
                return response.json()
            }).then(data => {
                if (data.status == 10000) {
                    var dataOption = []
                    for (let i = 0; i < data.data.length; i++) {
                        dataOption.push({ value: data.data[i]._id, label: "Tầng " + data.data[i].floor + " - "  + data.data[i].name });
                    }
                    this.setState({
                        listFloorDepart: dataOption
                    });
                }
            }).catch((error) => {
                console.log(error)
            });
    }

    handleChangeDepartment = (value) => {
        this.setState({
            crrFloorDepartment: value,
            dataAppointment: {...this.state.dataAppointment, obj_department: value.value}
        });
    }

    UpdateBiTable = () => {
        let dataBitable = this.state.dataBitable;
        console.log(this.state.crrBox, this.state.crrIP)
        if ((this.state.crrBox === null || this.state.crrIP === null) && dataBitable.url === "") {
            swal({
                title: "Bạn chưa chọn thiết bị hoặc URL!",
                icon: "warning",
                buttons: {
                    confirm: {
                        text: 'Đóng',
                        value: null,
                        visible: true,
                        closeModal: true,
                    },
                },
                dangerMode: false,
            })
            return
        }
        this.setState({
            disabled_bitable: true
        }, () => {
            if (this.state.address) {
                this.UpdateBiTableFromURL();
                this.setState({
                    flag_socket: 1,
                });
            } else {
                this.Stream();
                localStorage.setItem("infoTable", JSON.stringify(this.state.dataBitable));
                localStorage.setItem("crrBox", JSON.stringify(this.state.crrBox));
                localStorage.setItem("crrIP", JSON.stringify(this.state.crrIP));
                localStorage.setItem("list_IP", JSON.stringify(this.state.list_IP));
            }
        });
    }

    UpdateBiTableFromURL = () => {
        var checktimeout = false
        let dataBitable = this.state.dataBitable;
        let result = async () => {
            setTimeoutCMTFront = setTimeout(() => {
                this.setState({
                    flag_socket: -1,
                    disabled_bitable: false
                }, () => {
                    swal({
                        title: "Kiểm tra lại kết nối Bitable rồi thử lại!",
                        icon: "warning",
                        buttons: {
                            confirm: {
                                text: 'Đóng',
                                value: null,
                                visible: true,
                                closeModal: true,
                            },
                        },
                        dangerMode: false,
                    })
                });
                checktimeout = true
            }, 5000);
            let img = await fetch(`${window.location.protocol}//${dataBitable.url}:8096/connect`, {
                method: 'GET',
                timeout: 4000

            }).then((response) => {
                return response.json()
            }).then((data) => {
                clearTimeout(setTimeoutCMTFront)
                if (data.response !== undefined) {
                    dataBitable.name = data.response
                    var id_box = data.response.slice(8)
                    this.setState({ dataBitable }, () => {
                        this.Stream();
                        localStorage.setItem("infoTable", JSON.stringify(this.state.dataBitable));
                        this.setState({
                            id_box_engine: +id_box,
                            name_device: data.response
                        });
                        toastr.success("Cập nhật thành công!", 'Thành công!', { displayDuration: 3000 })
                    })
                }
                else {
                    this.setState({
                        name_device: ""
                    }, () => {
                        toastr.error("Cập nhật thất bại, Thiết bị Bitable chưa được kết nối!", 'Thất bại!', { displayDuration: 3000 })
                        this.setState({
                            flag_socket: -1,
                            disabled_bitable: false,
                        })
                    });
                }

            }).catch((error) => {
                clearTimeout(setTimeoutCMTFront)
                if (checktimeout === false) {
                    toastr.error("Cập nhật thất bại, Thiết bị Bitable chưa được kết nối!", 'Thất bại!', { displayDuration: 3000 })
                    this.setState({
                        flag_socket: -1,
                        disabled_bitable: false,
                        name_device: ""
                    })
                }
            })
        }
        result();
    }
    onChangeBiTableUrl = (e) => {
        var dataBitable = this.state.dataBitable;
        dataBitable.name = "";
        dataBitable[e.target.name] = e.target.value;
        dataBitable.stream_socket = e.target.value + `${window.location.protocol === "https:" ? ":8096" : ":3000"}`;
        this.setState({

            dataBitable: dataBitable
        });
    }
    onClickClose = () => {
        this.setState({
            percent: 0,
            imageFace: '',
            dataSave: {
                ...dataSaveDefault
            },
            dataDeclare: {
                ...dataDeclare,
                startPlace: '',
                destination: '',
                workPlace: '',
                covidPlace: '',
                phoneDeclare: '',
                vehicleOther: '',
                // flyCheck: false,
                // shipCheck: false,
                // carCheck: false,
                fever: "0",
                cough: "0",
                breath: "0",
                throat: "0",
                takeCare: "0",
            },
            goDate: null,
            returnDate: null,
            startDate: null,
            dataFront: {
                ...dataFrontDefault
            },
            dataBack: {
                ...dataBackDefault
            },
            path_back: "",
            path_front: "",
            path_face: "",
            dataSchedules: [],
            phone_number: "",
            work_unit: "",
            hideInfomation: true,
            dataAppointment: {...dataAppointmentDefault, ids_camera: this.state.dataAppointment.ids_camera},
            start_time: null,
            end_time: null,
            crrFloorDepartment: null,
            disableAppointment: false
        });
    }

    validForm = () => {
        if (!this.state.dataFront.id) {
            this.setState({ validId: true });
        }
        if (!this.state.dataFront.name) {
            this.setState({ validName: true });
        }
    }

    resetForm = () => {
        this.setState({
            validId: false,
            validName: false
        });
    }


    save_history = (event) => {
        this.validForm()
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            this.setState({ isValid: true });
            event.stopPropagation();
        } else {
            var dataPost = this.state.dataSave;
            let schedules = []
           
            if (this.state.dataAppointment.guardian !== "") {
                var StartTime = Moment(this.state.start_time).format("HH:mm");
                var EndTime = Moment(this.state.end_time).format("HH:mm");
                if (this.state.start_time === null && this.state.end_time === null) {
                    this.setState({
                        dataAppointment: {...this.state.dataAppointment, 
                            start_time: Moment().format("HH:mm"),
                            end_time: Moment().set({hour: 18,minute: 0,second: 0}).format("HH:mm")
                        },
                    }, () => {
                        schedules.push({
                            ...this.state.dataAppointment,
                            description: DOMPurify.sanitize(this.state.dataAppointment.description),
                            guardian: DOMPurify.sanitize(this.state.dataAppointment.guardian),
                        })
                    });
                } else if (this.state.start_time !== null && this.state.end_time === null) {
                    this.setState({
                        dataAppointment: {...this.state.dataAppointment, 
                            start_time: Moment(this.state.start_time).format("HH:mm"),
                            end_time: Moment().set({hour: 18,minute: 0,second: 0}).format("HH:mm")
                        },
                    }, () => {
                        schedules.push({
                            ...this.state.dataAppointment,
                            description: DOMPurify.sanitize(this.state.dataAppointment.description),
                            guardian: DOMPurify.sanitize(this.state.dataAppointment.guardian),
                        })
                    });
                } else {
                    if (Moment(StartTime,"HH:mm a").isAfter(Moment(EndTime,"HH:mm a"))) {
                        swal('Cảnh báo!', "Thời gian vào lớn hơn thời gian ra", "warning");
                        this.setState({
                            loadingBtn: false
                        });
                        return
                    } else {
                        schedules.push({
                            ...this.state.dataAppointment,
                            description: DOMPurify.sanitize(this.state.dataAppointment.description),
                            guardian: DOMPurify.sanitize(this.state.dataAppointment.guardian),
                        })
                    }
                }
            }
            this.setState({ isValid: false });
            if (!this.state.isAdditional && ((this.state.dataSave.image_front === "" && this.state.dataSave.image_back === "" && this.state.dataSave.image_face === "") || this.state.dataSave.image_face === "")) {
                swal('Cảnh báo!', "Bạn chưa có dữ liệu ảnh", "warning");
                this.setState({
                    loadingBtn: false
                });
                return
            }
            swal({
                title: "Bạn có chắc chắn muốn lưu dữ liệu",
                icon: "warning",
                buttons: ['Huỷ', 'Hoàn tất'],
                dangerMode: false,
            })
                .then(name => {
                    if (!name) throw null;
                    this.setState({
                        loadingBtn: true
                    });
                    return fetch(`${HOST_EKYC}/api/ekyc/save`, {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': store.getState().isLogin.access_token
                        },
                        body: JSON.stringify({
                            ...dataPost,
                            id_camera: this.state.crrBox ? this.state.crrBox.id_box_engine : this.state.id_box_engine,
                            id_company: this.state.crrCompany ? this.state.crrCompany.value : '',
                            path_front: this.state.path_front !== "" ? this.state.path_front : "",
                            path_back: this.state.path_back !== "" ? this.state.path_back : "",
                            phone_number: DOMPurify.sanitize(this.state.phone_number),
                            work_unit: DOMPurify.sanitize(this.state.work_unit),
                            type_person: +(this.state.dataCreatePerson.type),
                            schedules: schedules
                        })
                    })
                })
                .then(response => {
                    return response.json()
                }).then(data => {
                    let id_staff = []
                    if (data.status === 10000) {
                        id_staff.push(data._id)
                        this.setState({
                            obj_id_history: data.obj_id_history,
                            id_staff: id_staff,
                            loadingBtn: false,
                            hideInfomation: true
                        }, () => {
                            swal({
                                title: "Thành công",
                                text: "Lưu thành công!",
                                icon: "success",
                                button: false,
                                timer: 2000,
                            })
                            // this.onModalRegister()
                            this.onClickClose()
                        });
                    } else if (data.status === 10004) {
                        this.setState({
                            loadingBtn: false,
                            path_face: "",
                        }, () => {
                            swal({
                                title: "Khuôn mặt không hợp lệ, yêu cầu nhìn thẳng và chụp lại khuôn mặt.",
                                icon: "error",
                                buttons: {
                                    confirm: {
                                        text: 'Đóng',
                                        value: null,
                                        visible: true,
                                        closeModal: true,
                                    },
                                },
                                dangerMode: false,
                            })
                        });
                    } else {
                        this.setState({
                            loadingBtn: false
                        }, () => {
                            swal({
                                title: "Lưu dữ liệu thất bại, vui lòng kiểm tra lại!",
                                icon: "error",
                                buttons: {
                                    confirm: {
                                        text: 'Đóng',
                                        value: null,
                                        visible: true,
                                        closeModal: true,
                                    },
                                },
                                dangerMode: false,
                            })
                        });
                    }
                }).catch((error) => {
                    if (error) {
                        this.setState({
                            loadingBtn: false
                        }, () => {
                            swal({
                                title: "Lưu dữ liệu thất bại, kiểm tra lại kết nối rồi thử lại!",
                                icon: "error",
                                buttons: {
                                    confirm: {
                                        text: 'Đóng',
                                        value: null,
                                        visible: true,
                                        closeModal: true,
                                    },
                                },
                                dangerMode: false,
                            })
                        });
                    } else {
                        swal.stopLoading();
                        swal.close();
                        this.setState({
                            loadingBtn: false
                        });
                    }
                });
        }

    }


    createPerson = (dataRegister) => {
        fetch(`${HOST_EKYC}/api/appointment_schedule/insert_or_update`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': store.getState().isLogin.access_token
            },
            body: JSON.stringify({
                ...this.state.dataCreatePerson,
                type: +(this.state.dataCreatePerson.type),
                id: this.state.dataFront.id,
                name: this.state.dataFront.name,
                image: this.state.dataSave.image_face,
                idCamera: this.state.crrBox && this.state.crrBox.id_box_engine ? this.state.crrBox.id_box_engine.toString() : this.state.id_box_engine,
                schedules: dataRegister,
                id_company: this.state.crrCompany ? this.state.crrCompany.value : '',
                obj_id_history: this.state.obj_id_history ? this.state.obj_id_history : '',
            })
        })

            .then(response => {
                return response.json()
            }).then(data => {
                if (data.status == 10000) {
                    this.setState({
                        loadingBtnSave: false
                    });
                    this.onClickClose()
                    this.modalClose()
                    swal({
                        title: "Thành công",
                        text: "Đăng ký thành công!",
                        icon: "success",
                        button: false,
                        timer: 3000,
                    })
                } else {
                    this.setState({
                        loadingBtnSave: false
                    }, () => {
                        swal({
                            title: "Đăng ký thất bại!",
                            icon: "error",
                            buttons: {
                                confirm: {
                                    text: 'Đóng',
                                    value: null,
                                    visible: true,
                                    closeModal: true,
                                },
                            },
                            dangerMode: false,
                        })
                    });
                }
            }).catch((error) => {
                if (error) {
                    this.setState({
                        loadingBtnSave: false
                    });
                    this.onClickClose();
                    swal({
                        title: "Đăng ký thất bại!",
                        icon: "error",
                        buttons: {
                            confirm: {
                                text: 'Đóng',
                                value: null,
                                visible: true,
                                closeModal: true,
                            },
                        },
                        dangerMode: false,
                    })
                } else {
                    swal.stopLoading();
                    swal.close();
                    this.setState({
                        loadingBtnSave: false
                    });
                }
            });
    }



    sendAPI = () => {
        // var dataPost = this.state.dataSave;
        // {dataPost.goDate ? dataPost.goDate = Moment(this.state.goDate).format('DD/MM/YYYY') : dataPost.goDate = ""}
        // {dataPost.returnDate ? dataPost.returnDate = Moment(this.state.returnDate).format('DD/MM/YYYY') : dataPost.returnDate = ""}
        // {dataPost.startDate ? dataPost.startDate = Moment(this.state.startDate).format('DD/MM/YYYY') : dataPost.startDate = "" }
        this.setState({
            loadingSendAPI: true
        }, () => {
            if (this.state.dataSave.image_front === "") {
                swal('Cảnh báo!', "Bạn chưa lấy ảnh giấy tờ mặt trước", "warning");
                this.setState({
                    loadingSendAPI: false
                });
                return
            }
            fetch(`${this.state.api_url_send_data}`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(this.state.dataSave)
            }).then((response) => {
                return response.json()
            }).then((data) => {
                if (data.status == 10000) {
                    this.setState({
                        loadingSendAPI: false
                    });
                    swal('Thành Công!', "Gửi Dữ Liệu Thành Công", "success");
                } else {
                    swal('Lỗi!', "Gửi Dữ Liệu Lỗi", "error");
                    this.setState({
                        loadingSendAPI: false
                    });
                }
            }).catch((error) => {
                if (error) {
                    this.setState({
                        loadingSendAPI: false
                    });
                    swal('Lỗi!', "Gửi Dữ Liệu Lỗi", "error");
                }
            })
        });
    }

    getBase64FromUrl = async (url) => {
        const data = await fetch(url);
        const blob = await data.blob();
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
                const base64data = reader.result;
                resolve(base64data);
            }
        });
    }


    VerificationFace = async () => {
        const img_base64 = await this.getBase64FromUrl(`${HOST_EKYC}/` + this.state.path_front)
        if (this.state.path_front === "") {
            if (this.state.dataSave.image_front === "") return;
        }
        if (this.state.dataSave.image_face === "") return;
        var { dataSave } = this.state;

        this.setState({
            loadingProgresbar: true
        }, () => {
            fetch(`${HOST_EKYC}/api/ekyc/verification`, {
                method: 'POST',
                headers: {
                    'key': 'qV3weotvEwlJ1G5cErvsUgxco6m1LMYX',
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': store.getState().isLogin.access_token
                },
                body: JSON.stringify({
                    'image_cmt': this.state.path_front !== "" ? img_base64.slice(22) : this.state.dataSave.image_front,
                    'image_live': this.state.dataSave.image_face,
                    'face_return': 0
                })
            }).then((response) => {
                return response.json()
            }).then((rs) => {
                if (rs.message === "OK") {
                    dataSave.save_percent = Math.ceil(rs.sim);
                    this.setState({
                        percent: rs.sim,
                        loadingProgresbar: false,
                        dataSave: dataSave

                    });
                } else {
                    dataSave.save_percent = 0;
                    this.setState({
                        percent: 0,
                        loadingProgresbar: false,
                        dataSave: dataSave
                    });
                }
            }).catch((error) => {
                if (error) {
                    dataSave.save_percent = 0;
                    this.setState({
                        percent: 0,
                        loadingProgresbar: false,
                        dataSave: dataSave
                    });
                }
            })
        });
    }


    onChangeBiTable = (value) => {
        var dataBitable = this.state.dataBitable;
        dataBitable[value.target.name] = value.target.value;
        this.setState({
            dataBitable: dataBitable
        });
    }
    // onChangeBiTableUrl = (value) => {
    //     console.log(window.location.protocol)
    //     var dataBitable = this.state.dataBitable;
    //     dataBitable.name = "";
    //     dataBitable[value.target.name] = value.target.value;
    //     dataBitable.stream_socket = value.target.value + `${window.location.protocol === "https:" ? "8096" : "3000"}`;
    //     this.setState({

    //         dataBitable: dataBitable
    //     });
    // }

    onChangeApiURL = (value) => {
        this.setState({
            api_url_send_data: value.target.value
        });
    }

    onChangeInputFront = (value) => {
        var { dataSave, dataFront } = this.state;
        dataFront[value.target.name] = DOMPurify.sanitize(value.target.value.replace(/</g, "").replace(/>/g, ""));
        dataSave[value.target.name] = DOMPurify.sanitize(value.target.value.replace(/</g, "").replace(/>/g, ""));
        this.setState({
            dataFront,
            dataSave
        });
        this.resetForm()
    }

    onChangeInputBack = (value) => {
        var { dataSave, dataBack } = this.state;
        dataBack[value.target.name] = DOMPurify.sanitize(value.target.value.replace(/</g, "").replace(/>/g, ""));
        dataSave[value.target.name] = DOMPurify.sanitize(value.target.value.replace(/</g, "").replace(/>/g, ""));
        this.setState({
            dataBack,
            dataSave
        });
    }
    onChangeIDcard = (value) => {
        var { dataSave } = this.state;
        dataSave[value.target.name] = value.target.value;
        this.setState({
            dataSave
        });
    }



    CloseBiTable = () => {
        this.setState({
            disabled_bitable: false,
        });
    }







    handleChangeHourStartTime = (time) => {
        this.setState({
            dataAppointment: {...this.state.dataAppointment, 
                start_time: Moment(time).format("HH:mm"),
                end_time: Moment(new Date(time)).add(2, 'hours').format("HH:mm")
            },
            start_time: time,
            end_time: new Date(time).setHours(new Date(time).getHours() + 2)
        });
    }

    handleChangeHourEndTime = (time) => {
        this.setState({
            dataAppointment: {...this.state.dataAppointment, end_time: Moment(time).format("HH:mm")},
            end_time: time,
        });
    }

    clearAppointment = () => {
        this.setState({
            dataAppointment: {
                ...dataAppointmentDefault,
                // obj_id: this.state.dataAppointment.obj_id,
                ids_staff: this.state.dataAppointment.ids_staff,
                ids_camera: this.state.dataAppointment.ids_camera,
            },
            start_time: null,
            end_time: null,
            crrFloorDepartment: null,
            disableAppointment: false
        });
    }

    clickToggle = () => {
        this.setState({
            hideInfomation: !this.state.hideInfomation
        });
    }

    onChangeInputAppointment = (e) => {
        var { dataAppointment } = this.state;
        dataAppointment[e.target.name] = DOMPurify.sanitize(e.target.value.replace(/</g, "").replace(/>/g, ""));
        this.setState({
            dataAppointment
        });
    }




    render() {
        var sizeScreen;
        if (window.innerWidth <= 1024) {
            sizeScreen = "220"
        } else if (1024 < window.innerWidth && window.innerWidth <= 1280) {
            sizeScreen = "280"
        } else if (1280 < window.innerWidth && window.innerWidth <= 1366) {
            sizeScreen = "300"
        } else if (1366 < window.innerWidth && window.innerWidth <= 1600) {
            sizeScreen = "360"
        } else if (1366 < window.innerWidth && window.innerWidth <= 1920) {
            sizeScreen = "440"
        }
        const style = this.state.flag_socket === 0 ? { backgroundColor: '#fff', width: 15, height: 15, borderRadius: '50%' } :
            this.state.flag_socket === 1 ? { backgroundColor: 'orange', width: 15, height: 15, borderRadius: '50%' } :
                this.state.flag_socket === 2 ? { backgroundColor: '#34bfa3', width: 15, height: 15, borderRadius: '50%' } : {}
        return (
            <div className="col-lg-12 pt-3">
                <div className="m-portlet" id="form_reva">
                    {/*begin::Form*/}
                    <div className="m-portlet__head row">
                        <div className="m-portlet__head-caption col-md-10">
                            <div className="col-md-12 row text-center m-0 pl-4">
                                <div className='d-flex align-items-center mr-3'
                                >
                                    {
                                        this.state.flag_socket === 1 ?
                                            <ClipLoader color={'#000'} loading={true} css={override_1} size={15} />
                                            :
                                            <span className='d-block' style={style}></span>
                                    }
                                </div>
                                <div className='d-flex align-items-center mr-3'>
                                    <Autocomplete
                                        className='bitable_select'
                                        options={this.state.option_select}
                                        getOptionLabel={option => option.title}
                                        getOptionSelected={(option, value) => option.title === value.title}
                                        value={this.state.crrOption}
                                        onChange={this.changeHandleSelect}
                                        disableClearable
                                        style={{ width: 130 }}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                fullWidth
                                                style={{ borderColor: '#ebedf2' }}
                                            />
                                        )}
                                        disabled={this.state.disabled_bitable}
                                    />
                                </div>
                                {
                                    !this.state.address ?
                                        <>
                                            <Autocomplete
                                                className='bitable_select'
                                                options={this.state.listBox}
                                                getOptionLabel={option => option.title}
                                                getOptionSelected={(option, value) => option.iso === value.iso}
                                                value={this.state.crrBox}
                                                onChange={this.changeHandleBox}
                                                disableClearable
                                                style={{ width: 160 }}
                                                renderInput={params => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        placeholder="Chọn thiết bị..."
                                                        fullWidth
                                                        style={{ borderColor: '#ebedf2' }}
                                                    />
                                                )}
                                                disabled={this.state.disabled_bitable}
                                            />
                                            <Autocomplete
                                                className='bitable_select mx-3'
                                                options={this.state.list_IP ? this.state.list_IP : []}
                                                getOptionLabel={option => option.title}
                                                getOptionSelected={(option, value) => option.iso === value.iso}
                                                value={this.state.crrIP}
                                                onChange={this.changeHandleIP}
                                                disableClearable
                                                style={{ width: 220 }}
                                                renderInput={params => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        placeholder="Chọn địa chỉ IP..."
                                                        fullWidth
                                                        style={{ borderColor: '#ebedf2' }}
                                                    />
                                                )}
                                                disabled={this.state.disabled_bitable}
                                            />
                                        </>
                                        :
                                        <>
                                            <div className="d-flex align-items-center mr-3" style={{ display: this.state.name_device === "" ? "none" : "block" }}>{this.state.name_device}</div>

                                            <input className='mr-3 form-control m-input' style={{ width: 220 }} type="text" name="url" placeholder='Nhập địa chỉ IP...'
                                                value={this.state.dataBitable?.url} onChange={(e) => this.onChangeBiTableUrl(e)} disabled={this.state.disabled_bitable}
                                            />
                                        </>

                                }

                                {
                                    this.state.disabled_bitable !== false
                                        ?
                                        <button className="btn btn-warning" onClick={() => {
                                            this.CloseBiTable();
                                            this.socketDisconnect();
                                        }}>Sửa</button>
                                        :
                                        <button className="btn btn-success" onClick={this.UpdateBiTable}>Cập nhật</button>
                                }
                            </div>
                        </div>
                        <div className="m-portlet__head-caption col-md-2">
                            <button style={{ position: "absolute", right: 300 }} className={"btn btn-success " + (this.state.loadingBtn === true && 'm-loader')} disabled={this.state.loadingBtn} form="formBitable" type="submit">Lưu</button>
                            <button style={{ position: "absolute", right: 145 }} className="btn btn-success" onClick={this.checkSchedules}><i className='la la-calendar'></i> Kiểm tra lịch hẹn</button>
                            <button style={{ position: "absolute", right: "0", width: 135 }} className="btn btn-success" onClick={this.onSearch}><i className='la la-search'></i> Tra cứu/Trả thẻ</button>
                        </div>
                    </div>
                    <div className="m-form m-form--fit m-form--label-align-right m-form--group-seperator-dashed">
                        <div className="m-portlet__body p-2">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className='row'>
                                        <div className='col-lg-6 col-md-6 py-3'>
                                            <img
                                                src={
                                                    this.state.cameraFace
                                                        ? 'data:image/jpeg;base64,' +
                                                        this.state.cameraFace
                                                        : './img/camera-placeholder.png'
                                                }
                                                alt=''
                                                style={{
                                                    width: '100%',
                                                    maxHeight: '300px',
                                                    objectFit: 'cover',
                                                }}
                                            />
                                        </div>
                                        <div className='col-lg-6 col-md-6 py-3'>
                                            <img
                                                src={
                                                    this.state.cameraCMT
                                                        ? 'data:image/jpeg;base64,' + this.state.cameraCMT
                                                        : './img/camera-placeholder.png'
                                                }
                                                alt=''
                                                style={{
                                                    width: '100%',
                                                    maxHeight: '300px',
                                                    objectFit: 'cover',
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-7">
                                            <LoadingOverlay
                                                active={this.state.loadingImageFace}
                                                spinner={<FadeLoader
                                                    css={override}
                                                    sizeUnit={"px"}
                                                    size={150}
                                                    color={'rgb(54, 215, 183)'}
                                                    loading={true}
                                                />}
                                                styles={{
                                                    overlay: (base) => ({
                                                        ...base,
                                                        background: 'rgba(17, 17, 17, 0.24)'
                                                    })
                                                }}
                                            >
                                                <a href='#' onClick={this.onChangeFace}>
                                                    <img alt="img" src={this.state.imageFace !== "" ? 'data:image/jpeg;base64,' + this.state.imageFace : this.state.path_face !== "" ? this.state.img_path_face : "./img/scan-face.jpg"} style={{ "width": "100%", "height": `${sizeScreen}px`, "objectFit": "cover" }} />
                                                </a>
                                            </LoadingOverlay>
                                            <div className="col-md-8 m-auto pb-5 progressBarStyle text-center align-items-center">
                                                <div className="pb-2 pt-2" style={{ fontSize: "15px" }}>
                                                    Tỷ lệ trùng khớp <b className={Math.ceil(this.state.percent) <= 50 ? 'text-warning' : 'text-success'} >{Math.ceil(this.state.percent) + '%'}</b>
                                                </div>
                                                {/* {
                                                    this.state.loadingProgresbar === true
                                                        ?
                                                        <PulseLoader
                                                            css={override}
                                                            sizeUnit={"px"}
                                                            size={12}
                                                            margin={'2px'}
                                                            color={'#36D7B7'}
                                                            loading={this.state.loadingProgresbar}
                                                        />
                                                        :
                                                        <ProgressBar className="animation" striped variant={Math.ceil(this.state.percent) <= 50 ? 'warning' : 'success'} label={Math.ceil(this.state.percent) + '%'} min={0} max={100} now={Math.ceil(this.state.percent)} />
                                                } */}

                                            </div>
                                        </div>


                                        <div className="col-md-5">
                                            <div className="form-group m-form__group row pt-0 pl-0 pr-0">
                                                <div className="col-lg-12">
                                                    <LoadingOverlay
                                                        active={this.state.loadingImage1}
                                                        spinner={<FadeLoader
                                                            css={override}
                                                            sizeUnit={"px"}
                                                            size={150}
                                                            color={'rgb(54, 215, 183)'}
                                                            loading={true}
                                                        />}
                                                        styles={{
                                                            overlay: (base) => ({
                                                                ...base,
                                                                background: 'rgba(17, 17, 17, 0.24)',
                                                                width: '100%',
                                                                // height: 'auto'
                                                            })
                                                        }}
                                                    >
                                                        <a href='#' onClick={this.onFilesChange1}>
                                                            <img alt="img" id="previewImage1" src={this.state.path_front !== "" ? this.state.img_path_front : this.state.dataFront.base64 == "" ? "./img/anhmattruoc.png" : 'data:image/jpeg;base64,' + this.state.dataFront.base64} style={{ "width": "100%", "height": "auto" }} />
                                                        </a>
                                                    </LoadingOverlay>
                                                </div>
                                                <div className="col-lg-12" style={{ 'paddingTop': '10px' }}>
                                                    <LoadingOverlay
                                                        active={this.state.loadingImage2}
                                                        spinner={<FadeLoader
                                                            css={override}
                                                            sizeUnit={"px"}
                                                            size={150}
                                                            color={'rgb(54, 215, 183)'}
                                                            loading={true}
                                                        />}
                                                        styles={{
                                                            overlay: (base) => ({
                                                                ...base,
                                                                background: 'rgba(17, 17, 17, 0.24)',
                                                                width: '100%',
                                                                // height: 'auto'
                                                            })
                                                        }}
                                                    >
                                                        <a href='#' onClick={this.onFilesChange2}>
                                                            <img alt="img" id="previewImage2" src={this.state.path_back !== "" ? this.state.img_path_back : this.state.dataBack.base64 == "" ? "./img/anhmatsau.png" : 'data:image/jpeg;base64,' + this.state.dataBack.base64} style={{ "width": "100%", "height": "auto" }} />
                                                        </a>
                                                    </LoadingOverlay>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <Form id="formBitable" noValidate validated={this.state.isValid} onSubmit={(e) => this.save_history(e)} className="col-md-6">
                             
                                <fieldset className="fieldset_A mt-3">
                                        <legend className="legend_A">Thông tin khách</legend>
                                        <div className='row'>
                                            <div className="col-md-5">
                                                <div className="form-group m-form__group p-0">
                                                    <label className="text-lable">
                                                        Số định danh (<span className='text-danger'>*</span>):
                                                    </label>
                                                    <div className=" row">
                                                        <div className="col-lg-12">
                                                            <input style={{ fontWeight: "bold", fontSize: "18px" }} type="text" className=" input_md form-control m-input" name="id" value={this.state.dataFront.id} onChange={(value) => this.onChangeInputFront(value)} required />
                                                            {this.state.validId && <div className='text-danger pt-2'>Vui lòng nhập thông tin</div>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-7">
                                                <div className="form-group m-form__group p-0">
                                                    <label className="text-lable">Họ và tên (<span className='text-danger'>*</span>):</label>
                                                    <div className=" row">
                                                        <div className="col-lg-12">
                                                            <input  style={{ fontWeight: "bold", fontSize: "18px" }} type="text" className="input_md form-control m-input" name="name" value={this.state.dataFront.name} onChange={(value) => this.onChangeInputFront(value)} required />
                                                            {this.state.validName && <div className='text-danger pt-2'>Vui lòng nhập thông tin</div>}
                                                        </div>
                                                    </div>
                                                    
                                                </div>

                                               

                                            </div>

                                        </div>

                                        <div className='row'>
                                            <div className="col-md-5 mt-2">
                                                <div className="form-group m-form__group p-0">
                                                    {/* <h3>Dữ liệu ảnh mặt trước</h3> */}
                                                    <label className="text-lable">
                                                        Số điện thoại:
                                                    </label>
                                                    <div className=" row">
                                                        <div className="col-lg-12">
                                                            <input style={{ fontWeight: "bold" }} type="tel" className="input_md form-control m-input" rows="5" name="phone_number" value={this.state.phone_number} onChange={e => this.setState({ phone_number: e.target.value.replace(/</g, "").replace(/>/g, "") })} />
                                                        </div>
                                                    </div>
                                                   
                                                </div>
                                            </div>
                                            

                                            <div className="col-md-7 mt-2">
                                                <div className="form-group m-form__group p-0">
                                                    <label className="text-lable">Đơn vị công tác:</label>
                                                    <div className=" row">
                                                        <div className="col-lg-12">
                                                            <input style={{ fontWeight: "bold" }} type="text" className="input_md form-control m-input" rows="5" name="work_unit" value={this.state.work_unit} onChange={e => this.setState({ work_unit: e.target.value.replace(/</g, "").replace(/>/g, "") })} />
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="row">
                                            {/* <div className="col-md-6 mt-3">
                                            <div style={{cursor: "pointer"}} onClick={() => this.clickToggle()} className="m-demo-icon__class d-flex align-items-center"><b>Thông tin khác</b>&nbsp; <i className={`${this.state.hideInfomation ? "fas fa-chevron-down" : "fas fa-chevron-up"} `}></i> </div>
                                            </div> */}
                                            </div>

                                            {/* ẩn tôggle */}
                                            {/* <div className={`row mt-2 ${this.state.hideInfomation ? "d-none" : ""}`}> */}
                                            <div className={`row mt-2`}>

                                            <div className="col-md-12">
                                                <div className="form-group m-form__group p-0">
                                                    <div className=" row">
                                                        <div className="col-lg-3 mb-2">
                                                            <label className="text-lable-s">Ngày sinh: </label>
                                                            <input type="text" className="form-control m-input input_sm" name="birthday" value={this.state.dataFront.birthday} onChange={(value) => this.onChangeInputFront(value)} />
                                                        </div>
                                                        <div className="col-lg-2 mb-2">
                                                                <label  className="text-lable-s">Giới tính: </label>
                                                                <input  type="text" className="form-control m-input input_sm" name="sex" value={this.state.dataFront.sex} onChange={(value) => this.onChangeInputFront(value)} />
                                                            </div>
                                                        <div className="col-lg-7 m-0 p-0">
                                                            <div className="col-lg-12 row" >
                                                            <div className="col-lg-4 mb-2">
                                                                <label className="text-lable-s">Quốc gia: </label>
                                                                <input type="text" className="form-control m-input input_sm" name="country" value={this.state.dataFront.country} onChange={(value) => this.onChangeInputFront(value)} />
                                                            </div>
                                                            <div className="col-lg-4 mb-2">
                                                                <label className="text-lable-s">Quốc tịch: </label>
                                                                <input type="text" className="form-control m-input input_sm" name="national" value={this.state.dataFront.national} onChange={(value) => this.onChangeInputFront(value)} />
                                                            </div>
                                                            <div className="col-lg-4 mb-2">
                                                                <label className="text-lable-s">Loại bằng: </label>
                                                                <input type="text" className="form-control m-input input_sm" name="class" value={this.state.dataFront.class} onChange={(value) => this.onChangeInputFront(value)} />
                                                            </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className=" row">
                                                        <div className="col-lg-6 mb-2">
                                                            <label className="text-lable-s">Nguyên quán: </label>
                                                            <input type="text" className="form-control m-input input_sm" rows="1" name="origin" value={this.state.dataFront.origin} onChange={(value) => this.onChangeInputFront(value)} />
                                                        </div>
                                                        <div className="col-lg-6 mb-2">
                                                            <label className="text-lable-s">Nơi ĐKHKTT: </label>
                                                            <input type="text" className="form-control m-input input_sm" rows="1" name="address" value={this.state.dataFront.address} onChange={(value) => this.onChangeInputFront(value)} />
                                                        </div>
                                                     
                                                    </div>

                                                    <div className=" row">
                                                         <div className="col-lg-6 mb-2">
                                                            <label className="text-lable-s">Cấp bởi: </label>
                                                            <input type="text" className="form-control m-input input_sm" rows="1" name="issue_by" value={this.state.dataBack.issue_by} onChange={(value) => this.onChangeInputBack(value)} />
                                                        </div>
                                                        <div className="col-lg-3 mb-2">
                                                                <label className="text-lable-s">Ngày hết hạn: </label>
                                                                <input type="text" className="form-control m-input input_sm" name="expiry" value={this.state.dataFront.expiry} onChange={(value) => this.onChangeInputFront(value)} />
                                                        </div>
                                                        <div className="col-lg-3 mb-2">
                                                            <label className="text-lable-s">Ngày cấp: </label>
                                                            <input type="text" className="form-control m-input input_sm" name="issue_date" value={this.state.dataBack.issue_date} onChange={(value) => this.onChangeInputBack(value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        </fieldset>

                                        <fieldset className="fieldset_A mt-3 mb-2">
                                            
                                        <legend className="legend_A">Thông tin lịch hẹn</legend>
                                        <button className={"legend_B btn btn-warning "} type='button' onClick={this.clearAppointment}>Tạo mới</button>
                                        <div className="row">
                                            <div className="col-md-4 mt-2">
                                                <div className="form-group m-form__group p-0">
                                                    <label className="text-lable">Người bảo lãnh:</label>
                                                    <input style={{ fontWeight: "bold", fontSize: "16px", padding: "8px" }}  required={this.state.start_time !== null ? true : false} disabled={this.state.disableAppointment ? true : false} type="text" className=" form-control m-input" name="guardian" value={this.state.dataAppointment.guardian} onChange={(value) => this.onChangeInputAppointment(value)} />
                                                </div>
                                            </div>
                                            <div className="col-md-8 mt-2">
                                                <div className="form-group m-form__group p-0">
                                                    <label className="text-lable">Tầng - Phòng ban:</label>

                                                    <div className='d-flex align-items-center'>
                                                        <Select
                                                            isDisabled={this.state.disableAppointment ? true : false}
                                                            className="select w-100"
                                                            getOptionValue={(option) => option.label}
                                                            onChange={value => this.handleChangeDepartment(value)}
                                                            options={this.state.listFloorDepart}
                                                            value={this.state.crrFloorDepartment}
                                                            styles={customStyle}
                                                            placeholder=""
                                                            style={{fontWeight: "bold"}}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-1">
                                            <div className="col-md-4">
                                                <div className="form-group m-form__group p-0">
                                                    <label className="mb-0 text-lable">Thời gian vào:</label>
                                                    <KeyboardTimePicker
                                                        placeholder='HH:mm'
                                                        mask='__:__'
                                                        ampm={false}
                                                        style={{ width: "100%" }}
                                                        value={this.state.start_time}
                                                        onChange={(time) => this.handleChangeHourStartTime(time)}
                                                        invalidDateMessage="Định dạng thời gian sai"
                                                        disabled={this.state.disableAppointment ? true : false}
                                                        todayLabel="Hiện tại"
                                                        showTodayButton={true}
                                                        okLabel="Chọn"
                                                        cancelLabel="Hủy"
                                                        KeyboardButtonProps={{
                                                            onFocus: e => {
                                                               if (this.state.start_time === null) {
                                                                   this.setState({
                                                                    start_time: new Date()
                                                                   })
                                                                   
                                                               } 
                                                            }
                                                          }}
                                                    />
                                                </div>
                                                <div className="form-group m-form__group p-0">
                                                    <label className="mt-2 mb-0 text-lable">Thời gian ra:</label>
                                                    <KeyboardTimePicker
                                                        placeholder='HH:mm'
                                                        mask='__:__'
                                                        ampm={false}
                                                        style={{ width: "100%" }}
                                                        value={this.state.end_time}
                                                        onChange={(time) => this.handleChangeHourEndTime(time)}
                                                        invalidDateMessage="Định dạng thời gian sai"
                                                        disabled={this.state.disableAppointment ? true : false}
                                                        todayLabel="Hiện tại"
                                                        showTodayButton={true}
                                                        okLabel="Chọn"
                                                        cancelLabel="Hủy"
                                                    />

                                                </div>
                                            </div>
                                            <div className="col-md-8">
                                                <div className="form-group m-form__group p-0">
                                                    <label className="text-lable">Nội dung làm việc:</label>
                                                    <textarea disabled={this.state.disableAppointment ? true : false} type="text" className="form-control m-input mt-2" rows="3" name="description" value={this.state.dataAppointment.description} onChange={(value) => this.onChangeInputAppointment(value)} />
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </fieldset>
                                 
                                    <div className="row mt-3">
                                        {/* <div className="col-md-12 text-center p-3"> */}
                                            <div className="col-md-8">
                                                <div className="d-flex align-items-center form-group m-form__group p-0 row">
                                                    <label style={{ fontSize: "18px", paddingTop: "5px" }} className="mr-3 ml-3">Số thẻ</label>
                                                    <input style={{ fontWeight: "bold", fontSize: "20px" }} type="text" className="col-md-9 form-control-cus form-control m-input" name="id_card" value={this.state.dataSave.id_card} onChange={(value) => this.onChangeInputFront(value)} />
                                                </div>
                                            </div>
                                            
                                            <div className="col-md-4 d-flex justify-content-end">
                                                <button className={"btn btn-success m-btn--icon mr-3 m-loader--light m-loader--right p-3 btn-text " + (this.state.loadingBtn === true && 'm-loader')} disabled={this.state.loadingBtn} form="formBitable" type="submit">Lưu dữ liệu</button>
                                                <button className={"btn btn-danger m-btn--icon mr-2 m-loader--light m-loader--right ml-3 p-3 btn-text "} type='button' onClick={this.onClickClose}>Hủy</button>
                                            </div>
                               
                                        {/* </div> */}
                                    </div>
                                </Form>

                            </div>

                        </div>
                        <ModalSearch
                            show={this.state.ModalSearch}
                            onHide={this.modalCloseSearch}
                            crrCompany={this.state.crrCompany}
                            crrBox={this.state.crrBox}
                        />
                        {this.state.ModalDeclare &&
                            <ModalDeclare
                                data={this.state.dataFront}
                                show={this.state.ModalDeclare}
                                onHide={this.modalCloseDeclare}
                                phone={this.state.phone_number}
                            />
                        }
                        {this.state.modalRegister &&
                            <ModalRegister
                                show={this.state.modalRegister}
                                onHide={this.modalClose}
                                onChangeRadio={this.onChangeRadio}
                                data={this.state.dataFront}
                                onSave={this.createPerson}
                                listCam={this.state.listCamConfig}
                                idCompany={this.state.crrCompany.value}
                                idStaff={this.state.id_staff}
                            />
                        }
                        {/*end::Form*/}
                    </div>
                </div>
            </div>
        );
    }

}

export default withRouter(BiTable);
