import { css } from '@emotion/core';
import Tooltip from '@material-ui/core/Tooltip';
import $ from 'jquery';
import React, { Component } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import Pagination from "react-js-pagination";
import { withRouter } from 'react-router';
import { PulseLoader } from 'react-spinners';
import swal from 'sweetalert';
import { HOST_EKYC } from '../../config';
import store from '../../store';
import ModalFormEdit from './ModalFormEdit';

const override = css`
    display: block;
    
    margin: 0 auto;
    border-color: red;
`;


class Customer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data_json_export: [],
            dataHistory: [],
            total_count: 0,
            offset: 0,
            activePage: 1,
            keyWord:'',
            loading: true,
            ModalInfo: false,
            crrCompany: store.getState().company.crr_company ? store.getState().company.crr_company : null,
            showModalEdit: false,
            crrValue: null,
            ModalDeclare: false,
            dataRole: store.getState().role.role,
        }
        store.subscribe(() => {
            this.setState({
                crrCompany: store.getState().company.crr_company,
                dataRole: store.getState().role.role,
            }, () => {
                    this.setState({
                    }, () => { this.getCustomer() });
                    
            })
        })
        this.itemsPerPage = 10;
    }
    
    componentDidMount = () => {
        this.getCustomer()
    };

 

    getCustomer = () => {
        if(!this.state.crrCompany) return;
        fetch(`${HOST_EKYC}/api/customer/search`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': store.getState().isLogin.access_token
            },
            body: JSON.stringify({
                "search_data":this.state.keyWord,
                "index": this.state.activePage,
                "item_per_page": this.itemsPerPage,
                "id_company": this.state.crrCompany ? this.state.crrCompany.value : '',
            })
        }).then((response) => {
            return response.json()
        }).then((dataRes) => {
            if (dataRes.status === 10000) {
                this.setState({
                    dataHistory: dataRes.data.customers,
                    total_count: dataRes.data.count,
                    loading: false
                })
                this.FilterStaff(this.state.activePage)
            } else {
                this.setState({
                    dataHistory: [],
                    total_count: 0,
                    loading: false
                })
            }
        }).catch((error) => {
            this.setState({
                dataHistory: [],
                total_count: 0,
                loading: false
            })
            console.log(error)
        })
    }



    FilterStaff = (activePage) => {
        const offset = (activePage - 1) * this.itemsPerPage;
        this.setState({
            offset: offset
        })
    }

    handlePageChange = (pageNumber) => {
        this.setState({
            ...this.state,
            activePage: pageNumber
        }, () => {
            this.getCustomer()
        })
    }


    handleClickSearch = () => {
        this.setState({
            dataHistory: [],
            total_count: 0,
            activePage: 1,
            loading: true
        }, () => {
            this.getCustomer()
        })
    }

    reset = (e) => {
        this.setState({
            activePage: 1,
            dataHistory: [],
            data_json_export: [],
            loading: true,
            keyWord:'',
        }, () => {
            this.getCustomer();
        });
    }

    isChange = (event) => {
        if (event.key === 'Enter') {
            this.handleClickSearch()
        }
        this.setState({
            keyWord: event.target.value.trim()
        });
    }


    handleDelete = (id) => {
        swal({
            title: 'Bạn có chắc',
            text: 'Bạn có chắc muốn xóa lịch sử',
            icon: "warning",
            buttons: {
                oke: 'Xóa',
                cancel: 'Đóng',
            },
        }).then(async (value) => {
            if (value === 'oke') {
                fetch(`${HOST_EKYC}/api/customer/${id}`, {
                    method: 'DELETE',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': store.getState().isLogin.access_token
                    },
                }).then((response) => {
                    return response.json()
                }).then((dataRes) => {
                    if (dataRes.status === 10000) {
                        swal({
                            title: 'Thành công',
                            text: 'Xóa thành công',
                            buttons: false,
                            timer: 1500,
                            icon: 'success',
                        });
                        this.getCustomer();
                    } else {
                        swal({
                            title: 'Lỗi',
                            text: 'Xóa không thành công',
                            buttons: false,
                            timer: 1500,
                            icon: 'error',
                        })
                    }
                }).catch((error) => {
                    console.log(error);
                    swal({
                        title: 'Lỗi',
                        text: 'Xóa không thành công',
                        buttons: false,
                        timer: 1500,
                        icon: 'error',
                    })
                })
            }
        })
        
    }

    handleEdit = (value) => {
        this.setState({
            showModalEdit: true,
            crrValue: value,
        })
    }
    closeModalEdit = () => {
        this.setState({
            showModalEdit: false,
            crrValue: null,
        })
    }




 

    render() {
        var pageRangeDisplayed = 1;
        if ($(window).width() < 768) {
            pageRangeDisplayed = 3
        } else {
            pageRangeDisplayed = 5
        }

        const dataRenderHistory = this.state.dataHistory.map((value, index) => {
            return (
                <tr key={index}>
                    <td>{(index + this.state.offset + 1)}</td>
                    <td >{value.name}</td>
                    <td>{value.id}</td>
                    <td>{value.phone_number}</td>
                    <td style={{whiteSpace: "initial"}}>{value.work_unit}</td>
                    <td>
                        <Tooltip title="Chỉnh sửa" placement="top" arrow>   
                            <button
                                className="m-portlet__nav-link btn m-btn m-btn--hover-warning m-btn--icon m-btn--icon-only m-btn--pill"
                                onClick={() => this.handleEdit(value)}
                                >
                                <i className="la la-edit" />
                            </button>
                        </Tooltip>
                        <Tooltip title="Xoá" placement="top" arrow>   
                            <button
                                className="m-portlet__nav-link btn m-btn m-btn--hover-danger m-btn--icon m-btn--icon-only m-btn--pill"
                                onClick={() => this.handleDelete(value._id)}
                                >
                                <i className="la la-trash" />
                            </button>
                        </Tooltip> 
                    </td>
                </tr>
            )
        })

        return (
            <div className="m-grid__item m-grid__item--fluid m-wrapper">
                <div className="m-content p-2">
                    <div className="m-portlet m-portlet--tab mb-0">
                        <div className="m-portlet__head p-3">
                            <div className="m-portlet__head-caption pl-3">
                                <div className="m-portlet__head-title">
                                    <h3 className="m-portlet__head-text">
                                        Danh sách khách hàng
                                    </h3>
                                </div>
                            </div>
                            <div className="m-portlet__head-tools pr-3">
                                    <button
                                    onClick={() => { 
                                        var crrDataAdd = {
                                            id: "",
                                            name: "",
                                            phone_number: "",
                                            work_unit: "",
                                            _id: "",
                                        }
                                        this.setState({ 
                                            showModalEdit: true,
                                            crrValue: crrDataAdd,
                                         })
                                     }}
                                        className='btn btn-accent m-btn m-btn--custom m-btn--icon m-btn--pill m-btn--air'
                                    >

                                        <span>
                                            <i className='la la-plus' />
                                            <span>
                                                Thêm mới
                                            </span>
                                        </span>
                                    </button>
                            </div>
                        </div>
                        <div className="m-portlet__body m-portlet__body--no-padding">
                            <div className="row m-row--no-padding m-row--col-separator-xl">
                                <div className="col-xl-12">
                                    <div className="m-widget1">
                                        <div className='row m-0'>
                                            <div className="col-lg-2 pl-0 m--margin-bottom-10-tablet-and-mobile">
                                                <input type="text" onKeyUp={(event) => this.isChange(event)} id="inputSearch" className="form-control m-input" placeholder="Số định danh / Họ Tên / SĐT" />
                                            </div>
                                            <div className='col-md-2 pl-md-0 d-flex'>
                                                <button
                                                    onClick={(dataSearch) => {
                                                        this.handleClickSearch()

                                                    }}
                                                    className="btn btn-accent m-btn m-btn--icon mr-3" id="m_search">
                                                    <span>
                                                        <i className="la la-search" />
                                                        <span>Tìm kiếm</span>
                                                    </span>
                                                </button>
                                                <button
                                                    onClick={(dataSearch) => {
                                                        this.reset()

                                                    }}
                                                    className="btn btn-secondary m-btn m-btn--icon" id="m_reset">
                                                    <span>
                                                        <i className="la la-rotate-left" />
                                                        <span>Tải lại</span>
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="table-responsive text-nowrap dataTables_wrapper">
                                            <table className="table table-bordered table-hover dataTable mb-0">
                                                <thead>
                                                    <tr>
                                                        <th  style={{'width': '10px' }}>STT</th>
                                                        <th style={{'width': '200px' }}>Họ Tên</th>
                                                        <th style={{'width': '100px' }}>Số định danh</th>
                                                        <th style={{'width': '100px' }}>SĐT</th>
                                                        <th style={{'width': '70px' }}>Đơn vị/Công ty</th>
                                                        <th  style={{'width': '20px' }}>Thao tác</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {dataRenderHistory}
                                                </tbody>
                                            </table>
                                            <div className="text-center">
                                                <PulseLoader
                                                    css={override}
                                                    sizeUnit={"px"}
                                                    size={12}
                                                    margin={'2px'}
                                                    color={'#36D7B7'}
                                                    loading={this.state.loading}
                                                />
                                            </div>
                                        </div>
                                        {
                                            this.state.showModalEdit &&
                                            <ModalFormEdit 
                                             show={this.state.showModalEdit} 
                                             data={this.state.crrValue} 
                                             onHide={this.closeModalEdit} 
                                             onRefresh={this.getCustomer}
                                             idCompany={this.props.integrate ? this.props.id_company : this.state.crrCompany ? this.state.crrCompany.value : ''}
                                            />
                                        }
                                        <Pagination
                                            prevPageText='Trang trước'
                                            nextPageText='Trang sau'
                                            firstPageText='Trang đầu'
                                            lastPageText='Trang cuối'
                                            activePage={this.state.activePage}
                                            itemsCountPerPage={this.itemsPerPage}
                                            totalItemsCount={this.state.total_count}
                                            pageRangeDisplayed={pageRangeDisplayed}
                                            onChange={this.handlePageChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Customer);